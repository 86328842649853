import '../CSS/global.css';
import '../CSS/pages.css';
import { useState, useEffect, useRef } from 'react';
import { useScrollbar } from '../Hooks/use-scrollbar-y';
import Pdf2 from '../assets/pdfs/PrivacyPolicyDejaVu.pdf';
import { Link } from "react-router-dom";
import { W1, W2 } from "../functions";


function Privacy() {
  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /**************** Scroll bar  things*********************************/
  const privacyWrapper = useRef(null);
  const hasScroll = true;

  useScrollbar(privacyWrapper, hasScroll, windowWidth);
  let mult = 1;
  if (windowWidth > 600) {
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (

      <div className="maincontainer2">
        <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="pg-mainbox" style={{ width: 1320 / mult, height: 690 / mult }}>
            <div className="pg-mainbox-top" style={{ width: 1240 / mult, height: 85 / mult }}>
              <span className="pg-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px', marginBottom: 8 / mult }}>Privacy Policy</span>
            </div>


            <div className="pg-mainbox-media" style={{ width: 1230 / mult, height: 597 / mult }}>
              <div style={{ width: 1100 / mult, paddingRight: '12px', height: 450 / mult, minHeight: 450 / mult }} ref={privacyWrapper}>
                <div className="" style={{ width: 1000 / mult, height: 450 / mult, marginRight: 50 / mult, fontSize: 16 / mult }}>
                  <PrivacyText />
                </div>
              </div>
            </div>
            <div className="pg-terms-mainbox-bottom" style={{ width: 1270 / mult, height: 90 / mult }}>
              <a style={{ color: '#698ff9', fontWeight: 600 / mult, fontSize: 20 / mult, marginRight: 20 / mult, marginTop: 15 / mult }}
                href={Pdf2} target="_blank" rel="noopener noreferrer">Privacy Policy in PDF format</a>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // Mobile *************************************************************
    mult = 600 / windowWidth;
    return (
      <>
        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>Privacy Policy</span>
          </div>
          <div className="main_div_mob" style={{ width: 550 / mult }}>
            <div style={{ width: 500 / mult }}>
              <PrivacyText />
              <a style={{ color: 'blue', fontWeight: 600 / mult, fontSize: 20 / mult }}
                href={Pdf2} target="_blank" rel="noopener noreferrer">Privacy Policy in PDF format</a>
              <div style={{ height: 40 }}></div>
            </div>
          </div>
        </div >
      </>
    );
  }
}

function PrivacyText() {
  return (
    <>
      <p>Final March 1, 2023</p>

      <p>PRIVACY POLICY</p>
      <p>Last Updated: March 1, 2023</p>
      <p>This Privacy Policy (this “Policy”) describes the types of information DejaVu.art, a subsidiary of
        DejaVuAI Inc. a Washington corporation and its affiliates (“Company” “we,” “our,” or “us”)
        may collect from you (“you”), that you may provide to us when you use the website
        <a data-fr-linked="true" href="//www.dejavu.art">www.dejavu.art</a> (the “Website”), or that you may provide to us when you use any software
        services provided by the Company that link to or reference this Policy (collectively,
        the “Services”). This Policy also describes our practices for collecting, using, maintaining,
        protecting, and disclosing such information. This Policy does not apply to information collected
        by us offline or by any third-party that may be accessible or linked to or from the Services.</p>

      <p>By accessing or using the Services, you agree to this Policy. If you do not agree with the terms of
        this Policy, do not use the Services or provide any information to us. This Policy may change
        from time-to-time and we will post such changes on the Website. Your continued use of the
        Services after any revisions we make to this Policy that are posted on the Website constitutes your
        acceptance and agreement to those changes, so please check the Website periodically for updates.</p>

      <p>1. INFORMATION WE COLLECT.</p>
      <p>We may collect the following types of information when you use the Services:</p>
      <p>• “Personal Information”: information that identifies you or another person as an
        individual, such as your or someone else’s name, mailing address, e-mail address,
        telephone number, IP address, or any other identifier by which you or any other individual
        may be contacted online or offline.</p>

      <p>• “Non-Personal Information”: information that does not identify you as an individual,
        such as your browser type, your internet connection, the URL of the previous web site you
        visited, the equipment you use to access the Services, and details about how you use the
        Services.</p>

      <p>2. HOW WE COLLECT YOUR INFORMATION.</p>
      <p>We may collect information (i) when you provide it to us, or (ii) automatically through the
        Services.</p>

      <p>Information You Provide to Us. When you use the Services, you may provide us with both
        Personal and Non-Personal Information, including when you:</p>

      <p>• Complete and submit forms on the Services, including registering an account with
        Company</p>
      <p>• Use the Services</p>
      <p>• Contact us</p>
      <p>• Report a problem with the Services</p>
      <p>• Talk to us on the phone</p>
      <p>• Answer our customer surveys</p>
      <p>• Images that you upload for use in the Services</p>
      <p>• Payment and transaction data</p>

      <p>Information We Collect Automatically. We do not collect Personal Information automatically,
        with the exception of IP addresses which may be collected automatically and are only used as
        described below. Other than IP addresses, we do not collect real-time information about you
        or your devices via the Services. When you access and use the Services, we may use automatic
        data collection technologies to automatically collect:</p>

      <p>• Usage details (e.g., details regarding your access to and use of the Services)</p>
      <p>• Device information (e.g., information about your computer or mobile device and/or
        internet connection, including the device's unique device identifier, operating system,
        browser type, and/or mobile network information)</p>
      <p>The technologies we use for automatic information collection may include “cookies.” A
        cookie is a small file placed on your browsing device. It may be possible to refuse to accept
        cookies by activating the appropriate setting on your computer, tablet, or smartphone.
        However, if you select this setting you may be unable to access certain parts of our Services.</p>

      <p>3. THIRD PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES;</p>
      <p>DO NOT TRACK</p>
      <p>• Cookies and Other Tracking Technologies. Some content on the Services may be served
        by third-parties. These third parties may use cookies or other tracking technologies to
        collect information about you when you use the Services. We do not control these third
        parties' tracking technologies or how they may be used. If you have any questions about
        an advertisement or other targeted content, you should contact the responsible provider
        directly.</p>
      <p>• Do Not Track. “Do Not Track” is a privacy preference that you can set in your web
        browser. When you turn on the Do Not Track signal, your browser sends a message to
        Services requesting them not to track you. For information about Do Not Track, visit
        <a data-fr-linked="true" href="//www.allaboutdnt.org">www.allaboutdnt.org</a>. At this time, we do not respond to Do Not Track browser settings or
        signals. In addition, we may use other technology that is standard on the internet, such as
        pixel tags, web beacons, and other similar technologies, to track visitors to the Services.
        Those tools may be used by us and by third parties to collect information about you and
        your internet activity, even if you have turned on the Do Not Track signal.</p>
      <p>4. HOW WE USE YOUR INFORMATION</p>
      <p>We may use the information provided by or collected from you, including Personal
        Information, in order to:</p>
      <p>• Make the Services available to you</p>
      <p>• Provide you with information, products, or Services that you request from us</p>
      <p>• Comply with applicable law</p>
      <p>• Fulfill any other purpose for which you provide or we receive your information</p>
      <p>• Improve the Services and our business practices</p>
      <p>5. DISCLOSURE OF YOUR INFORMATION</p>
      <p              >We do not share your Personal Information with third-parties in any manner not described in
        this Policy.</p>
      <p>We may disclose aggregated Non-Personal Information about our users, and information that
        does not identify any individual, to third-parties to help us improve the Services or for any
        other commercially valid purposes.</p>
      <p>We may disclose your Personal Information:</p>
      <p>• To contractors, service providers, and other third-parties we use to support our business
        (“Service Providers”). In such circumstances, Service Providers may be bound by
        contractual obligations to keep your Personal Information confidential and use it only for
        the purposes for which we disclose it to them; however, not all such Service Providers will
        be bound by such contractual obligations. In such an event your Personal Information will
        be governed by the Service Providers’ privacy policies.</p>
      <p>• To a buyer or successor in the event of a merger, reorganization, dissolution, or other sale
        or transfer of all or substantially all of Company’s assets or equity interests.</p>
      <p>• To fulfill the purpose for which you provide it or we receive it with authorization. For
        example, if you give us an email address in order for us to provide you more information
        about the Services, we will use that email address or that social media credential to provide
        the requested information to you.</p>
      <p>• For any other purpose disclosed by us when you provide Personal Information.</p>
      <p>• With your consent.</p>
      <p>We may also disclose your Personal Information:</p>
      <p>• To comply with any court order, law, or legal process, including to respond to any
        government or regulatory request.</p>
      <p>• If we believe disclosure is necessary or appropriate to protect the rights, property, or safety
        of Company or its affiliates, customers, or others.</p>
      <p>6. YOUR CHOICES AND OPTING OUT</p>

      <p>We may periodically send you email messages describing news, information, updates, or
        promotional offers related to the Services.</p>
      <p>You can “opt-out” of receiving emails from us at any time by following the instructions
        included in your email and we will make the requested changes. Whether you opt out of
        receiving promotional emails or not, Company reserves the right to email or otherwise contact
        you with respect to important information, important notices, or important changes relating to
        our products and Services.</p>
      <p>7. ACCESSING AND CORRECTING PERSONAL INFORMATION</p>
      <p>• You can review and change your Personal Information by logging into your account with
        us; or, if such Personal Information is not available by logging into your account with us,
        please send us an email at <a data-fr-linked="true" href="mailto:artsupport@dejavuai.com">artsupport@dejavuai.com</a> to request access to, or to correct or
        delete any of your Personal Information in our possession. We may not change or delete
        information if we believe the change or deletion would violate any law or legal requirement
        or cause the information to be incorrect.</p>
      <p>8. DATA SECURITY</p>
      <p>The transmission of information via the internet and the storage of such information is not
        completely secure. Although we have commercially reasonable security measures in place to
        protect your Personal Information, we cannot guarantee the security of your Personal
        Information. You acknowledge and agree that you are providing, or allow us to gather, your
        Personal Information at your own risk. We are not responsible for failure or circumvention of
        any privacy settings or security measures we may have in place to protect your Personal
        Information.</p>
      <p>9. CHILDREN UNDER THE AGE OF 13</p>
      <p>The Services are not intended for use by children. We do not knowingly collect information
        from children under the age of 13. If we learn that Personal Information of a child under the
        age of 13 is collected, we will delete that information from our systems. If you believe we
        might have any information from or about a child under 13, please contact us at
        <a data-fr-linked="true" href="mailto:artsupport@dejavuai.com">artsupport@dejavuai.com</a> .</p>
      <p>10. CHANGES TO OUR PRIVACY POLICY</p>
      <p>We may modify or amend this Policy from time-to-time. The date this Policy was last revised
        is identified at the top of the page and the terms of this Policy are effective as of that date.</p>
      <p>11. CONTACT INFORMATION</p>
      <p>To ask questions or comment about this Policy and our privacy practices, contact us at:</p>
      <p><a data-fr-linked="true" href="mailto:artsupport@dejavuai.com">artsupport@dejavuai.com</a> .</p>

    </>
  );
}

export default Privacy;