import { createSlice } from "@reduxjs/toolkit";
import { ccModel } from "../../../Interfaces";
export const initialPaymentState: ccModel = {
    cc1: "",
    cc2: "",
    cc3: "",
    cc4: "",
    ccFull: "",    
    ccFirstName: "",
    ccLastName: "",
    ccMonth: "",
    ccYear: "",    
    ccAddress: "",
    ccCity: "",
    ccState: "",
    ccCountry: "",
    ccZip: "",
  };
export const paymentSlice = createSlice({
    name: "paymentSlice",
    initialState:initialPaymentState,
    reducers:{
        setCc: (state, action) => {
            state.cc1 = action.payload.cc1;
            state.cc2 = action.payload.cc2;
            state.cc3 = action.payload.cc3;
            state.cc4 = action.payload.cc4;
            state.ccFull = action.payload.cc1+action.payload.cc2+action.payload.cc3+action.payload.cc4;
        },        
        setCcFirstName: (state, action) => {
            state.ccFirstName = action.payload;
        },
        setCcLastName: (state, action) => {
            state.ccLastName = action.payload;
        },        
        setCcYear: (state, action) => {
            state.ccYear = action.payload;
        },        
        setCcMonth: (state, action) => {
            state.ccMonth = action.payload;
        },
        setCcAddress: (state, action) => {
            state.ccAddress = action.payload;
        },
        setCcCity: (state, action) => {
            state.ccCity = action.payload;
        },        
        setCcState: (state, action) => {
            state.ccState = action.payload;
        },
        setCcCountry: (state, action) => {
            state.ccCountry = action.payload;
        },                
        setCcZip: (state, action) => {
            state.ccZip = action.payload;
        },                        
    },
});

export const {setCc,setCcFirstName,setCcLastName,setCcYear,setCcMonth,setCcAddress,setCcCity,setCcState,setCcCountry,setCcZip} =  paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;