import { createSlice } from "@reduxjs/toolkit";
import { userModel } from "../../../Interfaces";

export const emptyUserState: any = {
  test: ""
};

export const accountOpsSlice = createSlice({
  name: "accountOps",
  initialState: emptyUserState,
  reducers: {
    setAccountDetails: (state, action) => {

    },
  },
});

export const { setAccountDetails } = accountOpsSlice.actions;
export const accountOpsReducer = accountOpsSlice.reducer;