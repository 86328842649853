import { createSlice } from "@reduxjs/toolkit";
import { userModel } from "../../../Interfaces";

export const emptyUserState: userModel = {
  fullName: "",
  id: "",
  email: "",
  role: "",
  actas_id: "",
  actas_email: "",
  a:false
};

export const userAuthSlice = createSlice({
  name: "userAuth",
  initialState: emptyUserState,
  reducers: {
    setLoggedInUser: (state, action) => {
      state.fullName = action.payload.fullName;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.actas_id = action.payload.id;
      state.actas_email = action.payload.email;
    },
    setActAs: (state, action) => {
      state.actas_email = action.payload.actas_email;
      state.actas_id = action.payload.actas_id;
    },    
    setShowAll: (state, action) => {
      state.a = action.payload
    },
  },
});

export const { setLoggedInUser,setShowAll, setActAs } = userAuthSlice.actions;
export const userAuthReducer = userAuthSlice.reducer;