import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { accountModel, ccModel, userModel } from "../../Interfaces";
//import { accountModel, userBigModel, subscriptionModel, userSubscriptionModel, apiResponse,userModel } from "../../Interfaces";
import EmptyButton from '../Misc/EmptyButton';
import { RootState } from "../../storage/redux/store";
import { useNavigate } from 'react-router-dom';
import { setSbscClientSecret } from "../../storage/redux/slice/subscriptionSlice";
import { useCreateSubscriptionMutation } from "../../Apis/paymentsApi";
import { CreateSubscriptionRequest } from "../../functions";
import { withAuth } from '../../HOC';
import MiniLoader from "../Misc/MiniLoader";
import { W0, W1, W2 } from "../../functions";

function CheckoutB() {
    const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
    );
    const subscriptionData: accountModel = useSelector(
        (state: RootState) => state.subscriptionStore
    );
    const paymentData: ccModel = useSelector(
        (state: RootState) => state.paymentStore
    );
    const [error, setError] = useState("");
    const [createSubscription] = useCreateSubscriptionMutation();
    const [loading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const proceedhandler = async () => {

        // create subscription

        const req = CreateSubscriptionRequest(subscriptionData, f_byd, f_3t, f_d, f_m, f_w, n_e, n_sms, ondemandaddon);
        // req to API , if error -  show error
        setIsLoading(true);
        const response: any = await createSubscription({
            req: req,
            userid: userData.id,
        });
        setIsLoading(false);
        if (response.data) {
            dispatch(setSbscClientSecret(response.data.clientSecret));
            navigate("/EnterPaymentInfo");
        } else if (response.error) {
            setError("Error: " + response.error.data);
        }
    }
    let f_byd = 0;
    let f_3t = 0;
    let f_d = 0;
    let f_m = 0;
    let f_w = 0;
    let n_e = 0;
    let n_sms = 0;
    let cost = 0;
    let ondemandaddon = 0;
    if (subscriptionData.cost !== undefined) { cost = subscriptionData.cost / 100; }
    if (subscriptionData.users !== undefined) {
        let i;

        for (i = 0; i < subscriptionData.users.length; i++) {
            if (subscriptionData.users[i].frequencyMonthly) { f_m++; }
            if (subscriptionData.users[i].frequencyWeekly) { f_w++; }
            if (subscriptionData.users[i].frequencyDaily) { f_d++; }
            if (subscriptionData.users[i].frequency3Times) { f_3t++; }
            if (subscriptionData.users[i].frequencyOnDemand) { f_byd++; }
            if (subscriptionData.users[i].notificationEmail) { n_e++; }
            if (subscriptionData.users[i].notificationSMS) { n_sms++; }
            if (subscriptionData.users[i].notificationAddOn) { ondemandaddon++; }
        }
    }
    /*************Calculate data for responsive behaviour************************/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    let mult = 1;
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    /******************************************************************************/
    if (windowWidth > W0) {
        return (
            <>
                <div className="maincontainer2 colore6e6e6">
                    <div style={{ width: 1320 / mult, height: 910 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 100, marginTop: -50 }}>
                        <div className="ca-mainbox" style={{ width: 1300 / mult, height: 800 / mult, marginTop: 100 }}>
                            <div className="ca-top" style={{ width: 1100 / mult, height: 130 / mult, fontWeight: 500, fontSize: 35 / mult + 'px', letterSpacing: '1.5px' }}>
                                Checkout
                            </div>
                            <div className="ca-top" style={{ width: 1100 / mult, height: 50 / mult, fontWeight: 400, fontSize: 30 / mult + 'px' }}>
                                You are about to subscribe to a Commercial plan with the following options:
                            </div>
                            <span style={{ color: 'red' }}>{error}</span>
                            <div className="container-column" style={{ margin: 0, width: 1100 / mult, height: 620 / mult, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>
                                <div className="container-row-up" style={{ margin: 0, width: 1000 / mult, justifyContent: 'center' }}>
                                    <div className="container-l-l-column" style={{ marginRight: 40 / mult, width: '50%' }}>
                                        <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Number of users:</span>
                                            <span>{subscriptionData.users?.length}</span>
                                        </div>
                                        <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Number of media:</span>
                                            <span>{subscriptionData.mediaQuota}</span>
                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Image Swap:</span>
                                            <span>Included for all users</span>

                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Online notification: </span>
                                            <span>Included for all users</span>

                                        </div>

                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Email notification: </span>
                                            <span>{n_e} units</span>

                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>SMS notification:</span>
                                            <span>{n_sms} units</span>

                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;</span>
                                            <span>&nbsp;</span>
                                        </div>

                                    </div>
                                    <div className="container-l-l-column" style={{ marginRight: 40 / mult, width: '50%' }}>
                                        <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Search Frequency</span>
                                            <span>&nbsp;</span>


                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On Demand:</span>
                                            <span>{f_byd} units</span>

                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3 Times / Day:</span>
                                            <span>{f_3t} units</span>

                                        </div>

                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Daily:</span>
                                            <span>{f_d} units</span>
                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Weekly:</span>
                                            <span>{f_w} units</span>
                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monthly:</span>
                                            <span>{f_m} units</span>
                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OnDemand add-on:</span>
                                            <span>{ondemandaddon} units</span>
                                        </div>


                                    </div>
                                </div>

                                <div className="container-row-center" style={{ width: '100%', marginTop: 20 / mult }}>
                                    Subscription monthly cost :&nbsp;<span style={{ color: 'white' }}>${cost}</span>
                                </div>

                                {loading ? (<MiniLoader />) :
                                    (
                                        <div className="container-row-center" style={{ width: '100%', marginTop: 60 / mult }}>
                                            <EmptyButton nav={() => { navigate(-1); }} title={"Back"} id={""} classname={"emptybuttongray"} />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <EmptyButton nav={proceedhandler} title={"Proceed with payment"} id={""} />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    } else {
        // Mobile *************************************************************
        mult = W0 / windowWidth;
        return (
            <>
                <div className="bg_div_mob">
                    <div className="title_div_mob" style={{ width: 540 / mult }}>
                        <span className="p-span1 colord9d9d9" style={{ fontSize: 30 / mult + 'px' }}>
                            Checkout
                        </span>
                    </div>
                    <div className="main_div_mob" style={{ width: 550 / mult, backgroundColor: '#494b53', border: '2px solid #a6abb9' }}>
                        <div style={{ width: 500 / mult, marginBottom: 30 }}>


                            <div style={{ margin: 0, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>

                                <div style={{ fontSize: 25 / mult + 'px', margin: 10, marginTop: 0, marginBottom: 10 }}>&nbsp;<br />
                                    You are about to subscribe to a Commercial plan with the following options:
                                </div>
                                <div className="container-column" style={{ margin: 0, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>
                                    <div className="container-column" style={{ margin: 0, justifyContent: 'center' }}>

                                        <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Number of users:</span>
                                            <span>{subscriptionData.users?.length}</span>
                                        </div>
                                        <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Number of media:</span>
                                            <span>{subscriptionData.mediaQuota}</span>
                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Image Swap:</span>
                                            <span>Included for all users</span>

                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Online notification: </span>
                                            <span>Included for all users</span>

                                        </div>

                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Email notification: </span>
                                            <span>{n_e} units</span>

                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>SMS notification:</span>
                                            <span>{n_sms} units</span>

                                        </div>


                                        <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Search Frequency</span>
                                            <span>&nbsp;</span>


                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On Demand:</span>
                                            <span>{f_byd} units</span>

                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3 Times / Day:</span>
                                            <span>{f_3t} units</span>

                                        </div>

                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Daily:</span>
                                            <span>{f_d} units</span>
                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Weekly:</span>
                                            <span>{f_w} units</span>
                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Monthly:</span>
                                            <span>{f_m} units</span>
                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OnDemand add-on:</span>
                                            <span>{ondemandaddon} units</span>
                                        </div>

                                    </div>

                                    <div className="container-row-center" style={{ width: '100%', marginTop: 20 / mult }}>
                                        Subscription monthly cost : ${cost}
                                    </div>

                                    {loading ? (<MiniLoader />) :
                                        (
                                            <div className="container-column" style={{ width: '100%', marginTop: 60 / mult }}>
                                                <EmptyButton nav={() => { navigate(-1); }} title={"Back"} id={""} classname={"emptybuttongray"} />
                                                &nbsp;
                                                <EmptyButton nav={proceedhandler} title={"Proceed with payment"} id={""} />
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default withAuth(CheckoutB);

