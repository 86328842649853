import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import { useState, useEffect, useRef} from 'react';
import RadioButtonBlock from "../Misc/RadioButtonBlock";
import CheckBoxBlock from "../Misc/CheckBoxBlock";



function Test1() {
    const CickIAmInd = () => {
        
    } 
    const CickIAmBus = () => {

    }

    const [checkrbutton, setCheckRButton] = useState(false);
    const checkrbuttonhandler = () => {
      setCheckRButton(!checkrbutton)
    };


      /************* Switch Ind/Bus Subscription************************/
  const [isbuz, setisbuz] = useState(false);
  const switchSmallLargeIcons = () => {
    let temp1 = document.getElementById('iconisbuz1')!;
    let temp2 = document.getElementById('iconisbuz2')!;
    if(isbuz){
      temp1.style.display = 'block'; 
      temp2.style.display = 'none';       
    }else{
      temp2.style.display = 'block'; 
      temp1.style.display = 'none';       
    }
    console.log(temp1.style.background)
    setisbuz(!isbuz);               
  }
    /*************Calculate data for responsive behaviour************************/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleWindowResize);
      return () => {
        window.removeEventListener('resize', handleWindowResize);
      };
    });  
    let mult = 1;
    let m = 1;
    if(windowWidth<1700) mult = 1.4;
    if(windowWidth<1000) mult = 1.8;
    mult = 1920/windowWidth;
    /******************************************************************************/

    const buttonsarray = [
      {"id": "rb1", "title": "On demand", "buttonstate":true,"buttonhandler":()=>true},
      {"id": "rb2", "title": "3 times/day", "buttonstate":true,"buttonhandler":()=>true},
      {"id": "rb3", "title": "Daily", "buttonstate":true,"buttonhandler":()=>true},
      {"id": "rb4", "title": "Weekly", "buttonstate": true,"buttonhandler":()=>true},                            
      {"id": "rb4", "title": "Monthly", "buttonstate":true,"buttonhandler":()=>true} 
    ]
    const buttonsarray1 = [
      {"id": "cb1", "title": "Web", "buttonstate":true,"buttonhandler":()=>true},
      {"id": "cb2", "title": "Email", "buttonstate":true,"buttonhandler":()=>true},
      {"id": "cb3", "title": "SMS/Text", "buttonstate":true,"buttonhandler":()=>true}
    ]
  return (
    <>
    <div className="maincontainer2 colore6e6e6">

        <div className="ca-mainbox" style={{width: 1300/m/mult,height: 850/m/mult, marginTop: 100}}>

            <div className="ca-top" style={{width: 1300/m/mult,height: 150/m/mult,fontWeight: 500, fontSize: 30/mult + 'px'}}>

                <div className="ca-top-left"  style={{width: 400/m/mult,height: 90/m/mult}}>
                    Test 1  Component
                </div>
                <div className="ca-top-right1" id="iconisbuz1" onClick={switchSmallLargeIcons} style={{width: 620/m/mult,height: 80/m/mult}}>
                </div>
                <div className="ca-top-right2" id="iconisbuz2" onClick={switchSmallLargeIcons} style={{width: 620/m/mult,height: 80/m/mult}}>
                </div>
            </div> 
            <div className="ca-top" style={{width: 1300/m/mult,height: 300/m/mult,fontWeight: 300, fontSize: 30/mult + 'px'}}>
            


            <RadioButtonBlock buttonsarray={buttonsarray} layout={"column"} />
            <CheckBoxBlock buttonsarray={buttonsarray1} layout={"column"} />

            </div> 
                                       
        </div>   

     </div>   
    </>
   );
}

export default Test1;
