import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "../../Apis";
import { imageApi } from "../../Apis";
import { paymentsApi } from "../../Apis";
import { userAuthReducer } from "./slice/userAuthSlice";
import { imageArrayReducer } from "./slice/imageArraySlice";
import { accountOpsReducer } from "./slice/accountOpsSlice";
import { accountReducer } from "./slice/accountSlice";
import { paymentReducer } from "./slice/paymentSlice";
import { varReducer } from "./slice/varSlice";
import { subscriptionReducer } from "./slice/subscriptionSlice";

const store = configureStore({
  reducer: {
    //loginStore:loginReducer,
    imageArrayStore: imageArrayReducer,
    accountOpsStore: accountOpsReducer,
    userAuthStore: userAuthReducer,
    accountStore: accountReducer,
    paymentStore: paymentReducer,
    varStore: varReducer,
    subscriptionStore: subscriptionReducer,
    [authApi.reducerPath]: authApi.reducer,
    [imageApi.reducerPath]: imageApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(imageApi.middleware)
      .concat(paymentsApi.middleware),

});


export type RootState = ReturnType<typeof store.getState>;

export default store;

