import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import { useState, useEffect, useRef } from 'react';
import UpgradeAccountI from "./UpgradeAccountI";
import UpgradeAccountB from "./UpgradeAccountB";
import { useSelector, useDispatch } from "react-redux";
import { accountModel, userBigModel } from "../../Interfaces";
import { userModel } from "../../Interfaces";
import { RootState } from "../../storage/redux/store";
import {
    setSbscAccType,
    setSbscUsers,
    setSbscUserNumber,
    setSbscSwap,
    setSbscMediaQuota,
    setSbscCost,
    setSbscCompanyName,
    setSbscCompanyAddress,
    setSbscUserStatus,
    setSbscObject
} from "../../storage/redux/slice/subscriptionSlice";
import { useGetSubscriptionDetailsQuery } from "../../Apis/authApi";
import { v4 as uuid } from 'uuid';
import { withAuth } from '../../HOC';
import { W0, W1, W2 } from "../../functions";

function UpgradeAccount() {
    const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
    );

    const dispatch = useDispatch();

    const subscriptionData: accountModel = useSelector(
        (state: RootState) => state.subscriptionStore
    );
    /*
    let temp;

    if (subscriptionData.accountType === 1) {
        temp = true;
    }
    if (subscriptionData.accountType === 2) {
        temp = false;
    }
    */
    //const [isbuz, setIsbuz] = useState(0);

    /*****************Load subscr redux*********************************/
    const usersubscription: any = useGetSubscriptionDetailsQuery(userData.id);

    useEffect(() => {
        if (usersubscription.data !== undefined) {

            let users: Array<userBigModel> = [];
            let i;
            //setIsbuz(usersubscription.data.AppSubscriptionType);
            for (i = 0; i < usersubscription.data.AppSubscriptionUsers.length; i++) {
                let bigUser: userBigModel
                /*notifications*/
                let notificationWeb = true;
                let notificationEmail = false;
                let notificationSMS = false;
                switch (usersubscription.data.AppSubscriptionUsers[i].NotificationType) {
                    case 2:
                        notificationSMS = true;
                        break;
                    case 3:
                        notificationEmail = true;
                        break;
                    case 4:
                        notificationEmail = true;
                        notificationSMS = true;
                        break;
                }

                /*frequency add-on*/
                let notificationAddOn = usersubscription.data.AppSubscriptionUsers[i].FrequencyAddOn;

                /*frequency*/
                let frequencyOnDemand = false;
                let frequency3Times = false;
                let frequencyDaily = false;
                let frequencyWeekly = false;
                let frequencyMonthly = false;

                switch (usersubscription.data.AppSubscriptionUsers[i].FrequencyType) {
                    case 1:
                        frequencyMonthly = true;
                        break;
                    case 2:
                        frequencyWeekly = true;
                        break;
                    case 3:
                        frequencyDaily = true;
                        break;
                    case 4:
                        frequency3Times = true;
                        break;
                    case 5:
                        frequencyOnDemand = true;
                        break;
                }
                //const userTempId = CreateSmallID();   
                bigUser = {
                    email: usersubscription.data.AppSubscriptionUsers[i].ApplicationUser.Email,
                    /*lastName:element.lastName,
                    firstName:element.firstName,
                    phoneNumber:element.phoneNumber,
                    twoFactorEnabled:element.two_factor_enabled,
                    emailConfirmed:element.email_confirmed,
                    phoneNumberConfirmed:element.phone_number_confirmed,*/
                    password: "",
                    confpassword: "",
                    notificationAddOn: notificationAddOn,
                    notificationWeb: notificationWeb,
                    notificationEmail: notificationEmail,
                    notificationSMS: notificationSMS,
                    frequencyOnDemand: frequencyOnDemand,
                    frequency3Times: frequency3Times,
                    frequencyDaily: frequencyDaily,
                    frequencyWeekly: frequencyWeekly,
                    frequencyMonthly: frequencyMonthly,

                    primaryUser: usersubscription.data.AppSubscriptionUsers[i].ApplicationUser.PrimaryUser,
                    role: usersubscription.data.AppSubscriptionUsers[i].ApplicationUser.Comments,
                    userStatus: usersubscription.data.AppSubscriptionUsers[i].ApplicationUser.UserStatus,
                    userTempId: usersubscription.data.AppSubscriptionUsers[i].ApplicationUser.Id
                };
                users.push(bigUser);
            }
            dispatch(setSbscAccType(usersubscription.data.AppSubscriptionType));
            dispatch(setSbscUsers(users));
            dispatch(setSbscUserNumber(users.length));
            dispatch(setSbscSwap(usersubscription.data.Swap));
            dispatch(setSbscCost(usersubscription.data.Cost));
            dispatch(setSbscAccType(usersubscription.data.AppSubscriptionType));
            dispatch(setSbscCompanyName(""));
            dispatch(setSbscCompanyAddress(""));
            dispatch(setSbscMediaQuota(usersubscription.data.SearchMediaQuota));


        }
    }, [usersubscription.data]);

    /*****************END OF Load subscr redux*********************************/

    /*************Calculate data for responsive behaviour************************/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    let mult = 1;
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    /******************************************************************************/


    //else {
    //dispatch(setSbscAccType(2));
    //  temp1 = "Individual";
    // }
    if (usersubscription.data === undefined) { return (<></>); }
    let temp1;
    if (usersubscription.data.AppSubscriptionType === 1) {
        temp1 = "Commercial";
    }
    if (usersubscription.data.AppSubscriptionType === 2) {
        temp1 = "Individual";
    }
    if (windowWidth > W0) {
        return (
            <>
                <div className="maincontainer2 colore6e6e6">
                    <div style={{ marginTop: 100, width: 1320 / mult, height: 860 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
                        <div className="ca-mainbox" style={{ width: 1300 / mult, height: 800 / mult }}>

                            <div className="ca-top" style={{ width: 1100 / mult, height: 150 / mult, fontWeight: 600, fontSize: 35 / mult + 'px', letterSpacing: '1.5px' }}>
                                <div className="ca-top-left" style={{ width: 600 / mult, height: 90 / mult }}>
                                    {temp1} Subscription Upgrade
                                </div>
                            </div>
                            <div style={{ margin: 0, width: 1100 / mult, height: 620 / mult, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>

                                {usersubscription.data.AppSubscriptionType === 2 &&
                                    (<UpgradeAccountI subscriptionData={subscriptionData} />)
                                }
                                {usersubscription.data.AppSubscriptionType === 1 &&
                                    (<UpgradeAccountB subscriptionData={subscriptionData} />)
                                }

                            </div>

                        </div>
                    </div>

                </div>
            </>
        );
    } else {
        // Mobile *************************************************************
        mult = W0 / windowWidth;
        return (
            <>
                <div className="bg_div_mob">
                    <div className="title_div_mob" style={{ width: 540 / mult }}>
                        <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>

                            {usersubscription.data.AppSubscriptionType === 2 &&
                                'Individual Subscription Upgrade'
                            }
                            {usersubscription.data.AppSubscriptionType === 1 &&
                                'Commercial Subscription Upgrade'
                            }

                        </span>
                    </div>
                    <div className="main_div_mob" style={{ width: 550 / mult, backgroundColor: '#494b53', border: '2px solid #a6abb9' }}>
                        <div style={{ width: 500 / mult, marginBottom: 30 }}>


                            <div style={{ margin: 0, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>

                                {usersubscription.data.AppSubscriptionType === 2 &&
                                    (<UpgradeAccountI subscriptionData={subscriptionData} />)
                                }
                                {usersubscription.data.AppSubscriptionType === 1 &&
                                    (<UpgradeAccountB subscriptionData={subscriptionData} />)
                                }

                            </div>



                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default withAuth(UpgradeAccount);
