import { useNavigate, useSearchParams } from "react-router-dom";
import '../../../src/CSS/global.css';
import '../../../src/CSS/loginandreg.css';
import BigMsg from "../Misc/BigMsg";


function ConfirmEmail() {

    const navigate = useNavigate();
    const ClickOk = () => {
        navigate("/");
    }

    const error = "";
    const title = "Sign Up";
    const subtitle = "";
    //If you don't receive it in a couple of minuts, please click Resend button.
    const message = "We have sent you a confirmation email. Please check your inbox and spam folders. ";
    const inputs = [
        { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
        { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
        { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
    ]
    const buttons = [
        { "handler": ClickOk, "title": "OK" },
        { "handler": () => true, "title": "" },
        { "handler": () => true, "title": "" },
    ]

    return (
        <BigMsg title={title} subtitle={subtitle} message={message} error={error} inputs={inputs} buttons={buttons} />
    );

}

export default ConfirmEmail;