import SmallButton from '../Misc/SmallButton';

function PromoCode({ mult, applyhandler, promocodeinputhandler, text = "", promocodevalue, wrongcode = "", mobile = false }: any) {
    let top1 = -532;
    let left1 = 920;
    if (mobile) {
        top1 = 0;
        left1 = 0;
    }
    if (text === "") {
        return (
            <div style={{
                width: 180 / mult, height: 97 / mult, borderRadius: 15 / mult,
                position: 'relative', top: top1 / mult, left: left1 / mult
            }}>
                <input
                    type="text"
                    id="imagemunber_i"
                    value={promocodevalue}
                    placeholder="Enter coupon"
                    onChange={promocodeinputhandler}
                    style={{ width: 178 / mult, height: 30 / mult, fontWeight: 200, fontSize: 18 / mult, textAlign: 'center', outline: 'none', border: '3px solid #a6abb9', borderRadius: 15 / mult }}
                />
                <SmallButton nav={applyhandler} mult={mult} title={"Apply"} type={"promo"} />
                <div style={{ color: 'red' }}>{wrongcode}</div>
            </div>

        );
    } else {
        return (
            <div style={{
                width: 180 / mult, height: 97 / mult, borderRadius: 15 / mult,
                position: 'relative', top: top1 / mult, left: left1 / mult
            }}>
                <div style={{ background: 'white', width: 180 / mult, height: 30 / mult, fontWeight: 200, fontSize: 18 / mult, textAlign: 'center', outline: 'none', border: '3px solid #a6abb9', borderRadius: 15 / mult }}>
                    <div style={{ paddingTop: 2 / mult }}>{text}</div>
                </div>
                <SmallButton nav={applyhandler} mult={mult} title={"Remove coupon"} type={"promo"} />
            </div>
        );
    }
}
export default PromoCode;