import React, { useState, useEffect } from "react";
import { useLoginUserMutation, useSendFPEmailMutation } from "../../Apis/authApi";
import { inputHelper } from "../helper";
import { apiResponse, userModel } from "../../Interfaces";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoggedInUser } from "../../storage/redux/slice/userAuthSlice";
import imageApi from "../../Apis/imageApi";
import '../../../src/CSS/global.css';
import '../../../src/CSS/loginandreg.css';
import { Link } from "react-router-dom";
import EmptyButton from "../Misc/EmptyButton";
import BigMsg from "../Misc/BigMsg";

function ForgotPassword() {
  const navigate = useNavigate();

  const [sendFPEmail] = useSendFPEmailMutation();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const RequestPasswordReset = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email))) {
      setError("Error: You have entered an invalid email address!");
    } else {
      const response: apiResponse = await sendFPEmail(email);
      console.log(response);
      if (response.data) {
        navigate("/ResetPasswordEmailSent");
      } else if (response.error) {
        setError("Error: " + response.error.data.errorMessages[0]);
      }
    }
  };

  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setError("");
  };


  const title = "Password Reset";
  const subtitle = "Please enter email address associated with your account";
  const message = "";

  const inputs = [
    { "toptext": "Email", "type": "email", "id": "emailfp", "value": "", "handler": handleUserInput },
    { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
    { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
  ]
  const buttons = [
    { "handler": RequestPasswordReset, "title": "Submit" },
    { "handler": () => true, "title": "" },
    { "handler": () => true, "title": "" },
  ]

  return (
    <BigMsg title={title} subtitle={subtitle} message={message} error={error} inputs={inputs} buttons={buttons} />
  );

}

export default ForgotPassword;