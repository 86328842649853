import React, { useState, useEffect } from "react";
import '../CSS/global.css';
import '../CSS/loginandreg.css';
import EmptyButton from '../components/Misc/EmptyButton';
import { useNavigate } from 'react-router-dom';

function NotYet() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  /*************Calculate data for responsive behaviour****************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < 1240) { mult = 1240 / windowWidth; }

  return (
    <>
      <div className="maincontainer2 ">
        <div className="mainbox" style={{ width: 1240 / mult, height: 560 / mult }}>
          <span className="colord9d9d9 midfont" style={{ marginBottom: 80 / mult, fontSize: 30 / mult }}> We are sorry. This feature is not available yet.</span>
          <EmptyButton nav={goBack} title={"Back"} />
        </div>
      </div>

    </>
  );

}

export default NotYet; 