import { PaymentElement, CardElement } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import MiniLoader from "../Misc/MiniLoader";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCreateSubscriptionOnServerMutation } from "../../Apis/authApi";
import { accountModel, userModel, userSubscriptionModel, subscriptionModel, userBigModel } from "../../Interfaces";
import { RootState } from "../../storage/redux/store";
import { withAuth } from '../../HOC';
import '../../../src/CSS/loginandreg.css';

function CheckoutForm({ clientSecret }: any) {

    const appearance: any = {
        theme: 'night',

        variables: {
            colorPrimary: '#fff',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
            // See all possible variables below
        }
    };

    const stripe: any = useStripe();
    let elements = useElements();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //const elements = stripe.elements({clientSecret, appearance});

    const [message, setMessage] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const [createSubscriptionOnServer] = useCreateSubscriptionOnServerMutation();


    const subscriptionData: accountModel = useSelector(
        (state: RootState) => state.subscriptionStore
    );
    const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
    );

    const [error, setError] = useState("");

    const handleSubmit = async (e: any) => {
        //        e.preventDefault();
        setMessage("");
        //  Create object  for server subscription
        const sb: subscriptionModel = {
            "swap": subscriptionData.swap,
            "app_subscription_type": subscriptionData.accountType,
            "search_media_quota": subscriptionData.mediaQuota,
            "primary_user_id": userData.id,
            "cost": subscriptionData.cost,
            "users": []
        }

        if (subscriptionData.users !== undefined) {
            subscriptionData.users.forEach((element: userBigModel) => {

                let tempFrequencyType = 1;
                if (element.frequencyMonthly) { tempFrequencyType = 1; }
                if (element.frequencyWeekly) { tempFrequencyType = 2; }
                if (element.frequencyDaily) { tempFrequencyType = 3; }
                if (element.frequency3Times) { tempFrequencyType = 4; }
                if (element.frequencyOnDemand) { tempFrequencyType = 5; }

                let tempNotificationType = 1;
                if (element.notificationSMS) { tempNotificationType = 2; }
                if (element.notificationEmail) { tempNotificationType = 3; }
                if (element.notificationEmail && element.notificationSMS) { tempNotificationType = 4; }

                let tempuser: userSubscriptionModel = {
                    "frequency_type": tempFrequencyType,
                    "notification_type": tempNotificationType,
                    "email": element.email,
                    "role": element.role,
                    "primary_user": element.primaryUser,
                    "password": element.password,
                    "user_status": element.userStatus,
                    "frequency_add_on": element.notificationAddOn,
                    "application_user_id": element.userTempId
                }
                sb.users?.push(tempuser);
            })

            //https://test1.dejavu.art/Completion?payment_intent=pi_3NBQp6EcRFIRevWf1Cm90zh1&payment_intent_client_secret=pi_3NBQp6EcRFIRevWf1Cm90zh1_secret_qLYbQ36154WSxVS7L23XFM0qv&redirect_status=succeeded

            setIsProcessing(true);
            const response: any = await createSubscriptionOnServer({
                "sb": sb,
                "userid": userData.id,
            });
            setIsProcessing(false);
            console.log(sb);
            if (response.data) {
                if (!stripe || !elements) {
                    // Stripe.js has not yet loaded.
                    console.log("!stripe || !elements");
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return;
                }
                setIsProcessing(true);

                const { error, paymentIntent }: any = await stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url: `${window.location.origin}/`,
                    },
                    //redirect: "if_required"
                });
                setIsProcessing(false);
                console.log("er in sbmit");
                console.log(error);
                if (error) {
                    setMessage(error.message);

                } else if (paymentIntent && paymentIntent.status === "succeeded") {
                    setMessage("Payment status: " + paymentIntent.status);
                    navigate("/Completion");
                } else {
                    setMessage("An unexpected error occured.");
                }



            } else if (response.error) {
                setError("Error: " + response.error.data);
            }
            //navigate("/Completion");
        };
    }

    return (
        <>
            <div>
                <div style={{ margin: '10px', fontSize: 15, fontWeight: 400, color: 'red', textAlign: 'center' }}>
                    {message}
                </div>
                <PaymentElement className="paymentelement" id="payment-element" />

                <div className="paymentinfobuttonsdiv">
                    {(isProcessing || !stripe || !elements) ? (<MiniLoader />) :
                        (
                            <>
                                <button id="backtocheckoutform" className="paymentinfobuttons paymentinfobuttons_gray" onClick={() => { navigate(-1); }}>
                                    <span id="button-text-back">
                                        {"Back"}
                                    </span>
                                </button>
                                <button id="submit" className="paymentinfobuttons paymentinfobuttons_blue" onClick={handleSubmit}>
                                    <span id="button-text" >
                                        {isProcessing ? "Processing ... " : "Pay now"}
                                    </span>
                                </button>
                            </>
                        )
                    }
                </div>

            </div>
        </>
    );
}

export default withAuth(CheckoutForm);