import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../functions";

const imageApi = createApi({
  reducerPath: "imageApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["AddImageData", "AllImages", "GetUsedQuota", "NewTimeByDemand", "IgnoredUrl"],
  endpoints: (builder) => ({
    getAllImagesInfo: builder.query({
      query: ({ userid, userasid, sort }) => ({
        url: `initload/getsearchimagesinfo/${userid}/${userasid}/${sort}`,
      }),
      transformResponse: (response: any) => {
        const notintrash = response.filter((searchimage: any) => !searchimage.in_trash);
        const intrash = response.filter((searchimage: any) => searchimage.in_trash);
        const withresults = notintrash.filter((searchimage: any) => searchimage.result_images.length > 0);
        const numberintrash = intrash.length;
        let resultnumber = 0;
        let notaknnumber = 0;
        console.log("notintrash");
        console.log(notintrash);
        notintrash.forEach((element: any) => {
          resultnumber += element.result_images.length;
          for (let i = 0; i < element.result_images.length; i++) {
            if (element.result_images[i].result_status === 1) {
              notaknnumber++;
            }
          }
        });
        return ({ notintrash, intrash, resultnumber, notaknnumber, numberintrash, withresults });
      },
      providesTags: ["AllImages"],
    }),

    setAkn: builder.mutation({
      query: ({ userid, ritemid, rstatus }) => ({
        url: `accounts/setakn/${userid}/${ritemid}/${rstatus}`,
        method: "POST",
        body: "",
      }),
      invalidatesTags: ["AllImages",],
    }),

    getImageData: builder.query({
      query: ({ userid, sorr, uuid4name }) => ({
        url: `initload/getsearchimagesdata/${userid}/${sorr}/${uuid4name}`,
      }),
      providesTags: ["AddImageData"],
    }),

    getUsedQuota: builder.query({
      query: (userid) => ({
        url: `accounts/getusedquota/${userid}`,
      }),
      providesTags: ["GetUsedQuota"],
    }),

    //*******NewDateByDemand processing***************
    getByDemandNextTime: builder.query({
      query: (userid) => ({
        url: `searchimage/getbydemandnexttime/${userid}`,
      }),
      providesTags: ["NewTimeByDemand"],
    }),
    setByDemandNextTime: builder.mutation({
      query: (userid) => ({
        url: `searchimage/setbydemandnexttime/${userid}`,
        method: "POST",
        body: "",
      }),
      invalidatesTags: ["NewTimeByDemand"],
    }),
    //**********Run "ByDemand" on background************  
    runByDemand: builder.mutation({
      query: (userid) => ({
        url: `bgsearchimages/${userid}`,
        method: "POST",
        body: "",
      }),
      invalidatesTags: ["AddImageData", "AllImages"],
    }),
    // *********** Ignored Urls ****************
    setIgnoredUrl: builder.mutation({
      query: (ignoredurlsruct) => ({
        url: "searchimage/setignoredurl",
        method: "POST",
        body: ignoredurlsruct,
      }),
      invalidatesTags: ["IgnoredUrl"],
    }),

    deleteIgnoredUrl: builder.mutation({
      query: (ignoredurlsruct) => ({
        url: "searchimage/deleteignoredurl",
        method: "POST",
        body: ignoredurlsruct,
      }),
      invalidatesTags: ["IgnoredUrl"],
    }),

    getIgnoredUrl: builder.query({
      query: ({ userid, urltype }) => ({
        url: `searchimage/getignoredurl/${userid}/${urltype}`,
      }),
      providesTags: ["IgnoredUrl"],
    }),
    //************************** */

    getSearchOnDubleClick: builder.mutation({
      query: ({ SearchImageId }) => ({
        url: `searchimage?SearchImageId=${SearchImageId}`,
        method: "POST",
        body: "",
      }),
      invalidatesTags: ["AddImageData", "AllImages"],
    }),

    sendToTrash: builder.mutation({
      query: (searchItemId) => ({
        url: "searchimage/sendtotrash",
        method: "POST",
        body: searchItemId,
      }),
      invalidatesTags: ["AddImageData", "AllImages", "GetUsedQuota"],
    }),

    restoreFromTrash: builder.mutation({
      query: (searchimage) => ({
        url: "searchimage/restorefromtrash",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: searchimage,

      }),
      invalidatesTags: ["AddImageData", "AllImages", "GetUsedQuota"],
    }),

    deleteFromTrash: builder.mutation({
      query: (searchimage) => ({
        url: "searchimage/deletefromtrash",
        method: "POST",

        body: searchimage,
      }),
      invalidatesTags: ["AddImageData", "AllImages"],
    }),

    deleteAllFromTrash: builder.mutation({
      query: (searchimage) => ({
        url: "searchimage/deleteallfromtrash",
        method: "POST",
        body: searchimage,
      }),
      invalidatesTags: ["AddImageData", "AllImages"],
    }),

    addSearchFile: builder.mutation({
      query: (searchimage) => ({
        url: "searchimage/add",
        method: "POST",
        body: searchimage,
      }),
      invalidatesTags: ["AddImageData", "AllImages", "GetUsedQuota"],
    }),

  }),
});

export const {
  useGetAllImagesInfoQuery,
  useGetImageDataQuery,
  useGetSearchOnDubleClickMutation,
  useAddSearchFileMutation,
  useSendToTrashMutation,
  useRestoreFromTrashMutation,
  useDeleteFromTrashMutation,
  useDeleteAllFromTrashMutation,
  useGetUsedQuotaQuery,
  useSetAknMutation,
  useGetByDemandNextTimeQuery,
  useSetByDemandNextTimeMutation,
  useDeleteIgnoredUrlMutation,
  useSetIgnoredUrlMutation,
  useGetIgnoredUrlQuery,
  useRunByDemandMutation
} = imageApi;
export default imageApi;