import '../CSS/global.css';
import '../CSS/pages.css';
import '../CSS/trash.css';
import { useState, useEffect, useRef } from 'react';
import { useScrollbar } from '../Hooks/use-scrollbar-y';
import { Link } from "react-router-dom";
import { W0, W1, W2 } from "../functions";
import LogoDejavuAI from "../assets/svgtojsx/LogoDejavuAI";


function Help() {

  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /**************** Scroll bar  things*********************************/
  const termsWrapper = useRef(null);
  const hasScroll = true;

  useScrollbar(termsWrapper, hasScroll, windowWidth);
  let mult = 1;
  if (windowWidth > 600) {
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (

      <div className="maincontainer2">
        <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="pg-mainbox" style={{ width: 1320 / mult, height: 690 / mult }}>
            <div className="pg-mainbox-top" style={{ width: 1250 / mult, height: 80 / mult }}>
              <span className="pg-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px', marginBottom: 20 / mult }}>Help and Support</span>
            </div>
            <div className="pg-mainbox-media" style={{ width: 1230 / mult, height: 597 / mult }}>
              <div style={{ width: 1100 / mult, paddingRight: '12px', height: 450 / mult, minHeight: 450 / mult, marginBottom: 40 / mult }} ref={termsWrapper}>
                <div className="" style={{ height: 1000 / mult, fontSize: 16, marginRight: 50 / mult }}>
                  <HelpText />
                </div>
              </div >
              <div style={{ width: '100%', display: 'flex', justifyContent: 'right', marginTop: 40 / mult, marginRight: 40 / mult }}>
                <LogoDejavuAI mult={mult} />
              </div>
            </div >
          </div >
        </div >
      </div >
    );
  } else {
    // Mobile *************************************************************

    mult = 600 / windowWidth;
    const mult1 = 3;
    return (
      <>
        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>Help and Support</span>
          </div>
          <div className="main_div_mob" style={{ width: 550 / mult }}>
            <div style={{ width: 500 / mult }}>
              <HelpText />
            </div>
          </div>
        </div >
      </>
    );
  }

}

function HelpText() {
  return (
    <div style={{ fontWeight: 300, letterSpacing: '1px', fontSize: 20 }}>

      <p style={{ fontWeight: 500, letterSpacing: '0px', fontSize: 25 }}>FAQ</p>


      <div>
        <div><span style={{ fontWeight: 500 }}>Is this just for art? Can this service be used for photographs, screenshots, designs, etc.?</span></div>
        <div style={{ paddingLeft: 30, marginBottom: 20 }}>
          DejaVu.art is a service that applies to just about any type of image, whether it’s a hobby or profession.
        </div>
      </div>

      <div>
        <div><span style={{ fontWeight: 500 }}>Is my portfolio private? What happens to images I upload onto my account?</span></div>
        <div style={{ paddingLeft: 30, marginBottom: 30 }}>
          Only you (and authorized users if it’s business account) will be able to see the images uploaded to your portfolio. By uploading, you agree that you own the rights to any image on your portfolio. Images on your DejaVu.art portfolio are only used in our internal algorithm to compare to other images around the internet, and will not be shared, sold, or distributed in any other way.
        </div>
      </div>

      <div>
        <div><span style={{ fontWeight: 500 }}>How accurate is this “image recognition” algorithm?</span></div>
        <div style={{ paddingLeft: 30, marginBottom: 20 }}>
          Our image recognition algorithm is more advanced than any other search engine on the planet. That’s right, the multi-billion dollar tech giants can’t even compare. Powered by DejaVuAI, our algorithm can reliably match images that are rotated, mirrored, skewed, cropped, warped, and color corrected, with as little as 2% of an image needed to match, which is why we are able to detect plagiarized images that have been heavily edited
        </div>
      </div>

      <div>
        <div><span style={{ fontWeight: 500 }}>What types of websites are checked for my images?</span></div>
        <div style={{ paddingLeft: 30, marginBottom: 20 }}>
          Right now, we’re focused on publicly-available portfolio sites and online marketplaces, two types of websites where plagiarism is commonplace and can have a negative impact on an artist’s business.
        </div>
      </div>


      <div>
        <div><span style={{ fontWeight: 500 }}>If a match is found, does that mean that image belongs to me?</span></div>
        <div style={{ paddingLeft: 30, marginBottom: 20 }}>
          Not necessarily.<br />
          If your image contains commonly-found elements (a famous building, a product with a logo, a free-to-use background or texture) then there is a possibility of <span style={{ fontWeight: 500 }}>false-positive</span> matches being reported.<br />
          Or, if your image has been used in such a way that falls under Fair Use, then you may be limited in what actions—if any--you can take going forward.<br />
          We leave it up to our users to determine if a matched image constitutes as being stolen and recommend any further actions be <span style={{ fontWeight: 500 }}>taken with caution</span>, as DejaVu.art claims no responsibility of any actions our users may take outside of our website.
        </div>
      </div>

      <div>
        <div><span style={{ fontWeight: 500 }}>What happens when a match is found? What do I do?</span></div>
        <div style={{ paddingLeft: 30, marginBottom: 20 }}>
          When a match has been found, you will be notified via website, email, or text (whichever option you include in your subscription). Your search reports will be listed in the My Reports page, where you can see results with their corresponding images, a URL to the image, Match Confidence Score, and a checkbox to see if it’s been acknowledged (by you).<br />
          Any further action will be the sole responsibility of the user. If it doesn’t fall under Fair Use, be sure to verify that it is indeed a positive match to your image and do your legal research before proceeding. Again, DejaVu.art claims no responsibility of any actions our users may take outside of our website.
        </div>
      </div>


      <div>
        <div><span style={{ fontWeight: 500 }}>
          I was notified of a match, but the image looks nothing like mine?</span></div>
        <div style={{ paddingLeft: 30, marginBottom: 20 }}>
          If your image contains commonly-found elements (a famous building, a product with a logo, a free-to-use background or texture) then it’s possible your match may be a false-positive.
        </div>
      </div>

      <div>
        <div><span style={{ fontWeight: 500 }}>I found my image uploaded on another site, but DejaVu.art didn’t notify me! What gives?</span></div>
        <div style={{ paddingLeft: 30, marginBottom: 20 }}>
          It’s likely the image you’ve found either existed before your image was added to your DejaVu.art portfolio, or is hosted on a website currently outside of our scope. Certain websites (like social media platforms or lesser-known sites) are currently not supported by DejaVu.art, but as we continue to grow, so will our capabilities.
        </div>
      </div>

      <div>
        <div><span style={{ fontWeight: 500 }}>Why would I bother with a larger portfolio when I can just combine all my images into a single, huge image?</span></div>
        <div style={{ paddingLeft: 30, marginBottom: 20 }}>
          Our image matching algorithm is based on percentage rather than resolution. Images in a collage (where each image is only a fraction of the whole thing) would be <span style={{ fontWeight: 500 }}>far less likely to match</span> with stolen images found on the internet, especially if they’ve been cropped or edited. To get the most out of our service, it is recommended each image be uploaded individually to your DejaVu.art portfolio.
        </div>
      </div>

      <p>If you have any other questions, please contact support &nbsp;
        <Link to="/Contact">here</Link>.
      </p>
    </div>

  );
}

export default Help;