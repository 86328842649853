function LogoDejavuAI({ mult }) {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={184 / mult}
            height={49 / mult}
            preserveAspectRatio="none"
            viewBox="93 -1411 488 130"
        >
            <g
                fill="none"
                fillOpacity="1"
                stroke="none"
                strokeDasharray="none"
                strokeOpacity="1"
            >
                <g>
                    <path
                        fill="#567ffa"
                        d="M450.629-1298.46l-4.138 10.5h59.355l-4.138-10.5zm84.623-71.75v-10.25h-45.266c3.279 2.285 5.943 5.702 7.992 10.25zm-8.888 71.75l4.147 10.5h4.74v-10.5zm-285.158-71.75H454.36c2.048-4.548 4.712-7.965 7.991-10.25h-355.51c-7.179 0-13 5.82-13 13v66.5c0 7.18 5.821 13 13 13H212.8v-5.971l6.052-.077c2.808 0 5.564-.195 6.548-1.323.495-.567.802-1.696.986-3.13H110.8a7 7 0 01-7-7v-57.75a7 7 0 017-7h115.827v-4.31h14.58zm.462 71.75c-.192 3.434-.631 6.422-1.318 8.965a26.991 26.991 0 01-.463 1.535h181.939l4.147-10.5z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M137.224-1367.08c9.536 0 16.784 2.656 21.744 7.968 4.96 5.312 7.44 13.472 7.44 24.48 0 10.944-2.48 19.072-7.44 24.384-4.96 5.312-12.208 7.968-21.744 7.968h-22.848c-3.584 0-5.376-1.76-5.376-5.28v-54.24c0-3.52 1.792-5.28 5.376-5.28zm-16.896 52.32c0 .768.416 1.152 1.248 1.152h15.648c6.272 0 10.816-1.664 13.632-4.992 2.816-3.328 4.224-8.704 4.224-16.128 0-7.424-1.392-12.784-4.176-16.08-2.784-3.296-7.344-4.944-13.68-4.944h-15.648c-.832 0-1.248.384-1.248 1.152z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M205.2-1352.968c6.016 0 10.368 1.36 13.056 4.08 2.688 2.72 4.032 6.544 4.032 11.472 0 4.672-1.312 8.272-3.936 10.8-2.624 2.528-7.04 3.792-13.248 3.792h-24.096v3.648c0 2.048.592 3.728 1.776 5.04 1.184 1.312 3.184 1.968 6 1.968h31.008v9.888h-31.008c-3.2 0-5.904-.416-8.112-1.248-2.208-.832-4.032-2-5.472-3.504a13.386 13.386 0 01-3.12-5.28c-.64-2.016-.96-4.24-.96-6.672v-17.28c0-2.368.32-4.576.96-6.624.64-2.048 1.68-3.824 3.12-5.328 1.44-1.504 3.264-2.672 5.472-3.504 2.208-.832 4.912-1.248 8.112-1.248zm-24.192 22.176h23.808c2.752 0 4.688-.544 5.808-1.632 1.12-1.088 1.68-2.56 1.68-4.416 0-1.92-.576-3.456-1.728-4.608-1.152-1.152-2.976-1.728-5.472-1.728h-16.32c-2.816 0-4.816.672-6 2.016-1.184 1.344-1.776 3.008-1.776 4.992z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M239.096-1352.968v53.568c0 3.328-.288 6.112-.864 8.352-.576 2.24-1.472 4.032-2.688 5.376-1.216 1.344-2.768 2.304-4.656 2.88-1.888.576-4.112.864-6.672.864h-8.928v-9.792h6.624c2.496 0 4.272-.544 5.328-1.632 1.056-1.088 1.584-3.072 1.584-5.952v-53.664zm0-19.104v11.808h-10.272v-11.808z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M279.136-1352.968c3.136 0 5.824.416 8.064 1.248 2.24.832 4.08 2 5.52 3.504 1.44 1.504 2.48 3.28 3.12 5.328.64 2.048.96 4.256.96 6.624v23.808c0 1.856-.144 3.424-.432 4.704-.288 1.28-.816 2.32-1.584 3.12-.768.8-1.84 1.392-3.216 1.776-1.376.384-3.12.576-5.232.576h-23.712c-6.016 0-10.352-1.376-13.008-4.128-2.656-2.752-3.984-6.528-3.984-11.328 0-2.304.304-4.352.912-6.144.608-1.792 1.584-3.312 2.928-4.56 1.344-1.248 3.104-2.208 5.28-2.88 2.176-.672 4.832-1.008 7.968-1.008h24.096v-3.84c0-1.984-.592-3.648-1.776-4.992-1.184-1.344-3.184-2.016-6-2.016h-30.336v-9.792zm4.896 40.8c1.856 0 2.784-.864 2.784-2.592v-9.504h-23.904c-2.688 0-4.576.528-5.664 1.584s-1.632 2.512-1.632 4.368c0 1.856.544 3.344 1.632 4.464 1.088 1.12 2.912 1.68 5.472 1.68z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M311.208-1367.08l19.68 52.128c.576 1.472 1.632 2.208 3.168 2.208 1.472 0 2.528-.736 3.168-2.208l20.64-52.128h12.576l-22.848 57.6c-1.216 3.072-2.928 5.232-5.136 6.48-2.208 1.248-5.008 1.872-8.4 1.872-3.2 0-5.968-.592-8.304-1.776-2.336-1.184-4.112-3.376-5.328-6.576l-21.696-57.6z"
                    ></path>
                    <path
                        fill="#fff"
                        d="M382.448-1352.968v30.336c0 3.008 1.072 5.504 3.216 7.488 2.144 1.984 5.232 2.976 9.264 2.976h8.256c4.032 0 7.104-.992 9.216-2.976 2.112-1.984 3.168-4.48 3.168-7.488v-30.336h9.984v30.72c0 2.944-.464 5.632-1.392 8.064a16.804 16.804 0 01-4.176 6.288c-1.856 1.76-4.144 3.136-6.864 4.128-2.72.992-5.904 1.488-9.552 1.488h-9.408c-3.648 0-6.832-.496-9.552-1.488-2.72-.992-5.008-2.368-6.864-4.128a16.804 16.804 0 01-4.176-6.288c-.928-2.432-1.392-5.12-1.392-8.064v-30.72z"
                    ></path>
                    <g>
                        <path
                            fill="#fff"
                            d="M443.198-1287.963h-17.55l31.995-81c1.71-4.32 4.117-7.357 7.222-9.112 3.106-1.755 7.088-2.633 11.948-2.633 4.77 0 8.707.878 11.812 2.633 3.106 1.755 5.468 4.792 7.088 9.112l30.78 81h-17.685l-27.675-73.305c-.81-2.07-2.295-3.105-4.455-3.105s-3.69 1.035-4.59 3.105z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M538.029 -1379.131H553.959V-1288.006H538.029z"
                        ></path>
                    </g>
                    <path
                        fill="#fff"
                        d="M573.026-1376.644c2.171 0 3.832.612 4.983 1.837 1.152 1.225 1.727 3.091 1.727 5.6 0 2.493-.575 4.352-1.727 5.576-1.15 1.225-2.812 1.837-4.983 1.837h-2.024c-2.17 0-3.831-.612-4.983-1.837-1.15-1.224-1.727-3.083-1.727-5.577 0-2.508.576-4.374 1.727-5.599 1.152-1.225 2.813-1.837 4.983-1.837zm-2.024 1.452c-1.7 0-3.006.488-3.916 1.463-.909.976-1.364 2.483-1.364 4.521 0 2.024.455 3.524 1.364 4.5.91.975 2.215 1.462 3.916 1.462h2.024c1.702 0 3.003-.487 3.905-1.463.902-.975 1.353-2.475 1.353-4.499 0-2.038-.45-3.545-1.353-4.52-.902-.976-2.203-1.464-3.905-1.464zm1.914 1.87c.44 0 .822.063 1.144.187.323.125.587.293.792.506.206.213.356.462.451.748a2.869 2.869 0 01-.242 2.332c-.256.462-.685.774-1.287.935l1.826 3.146h-1.518l-1.804-3.036h-2.046c-.132 0-.198.066-.198.198v2.838h-1.364v-3.102c0-.455.1-.777.297-.968.198-.19.51-.286.935-.286h3.058c.396 0 .679-.11.847-.33a1.23 1.23 0 00.253-.77c0-.279-.088-.52-.264-.726-.176-.205-.454-.308-.836-.308h-4.312v-1.364z"
                    ></path>
                    <g>
                        <path
                            fill="#fff"
                            d="M102.164-1409.022c.836 0 1.555.121 2.156.363.601.242 1.096.565 1.485.968.389.404.675.877.858 1.42.183.542.275 1.107.275 1.693 0 .558-.084 1.115-.253 1.672a4.05 4.05 0 01-.825 1.496c-.381.44-.876.8-1.485 1.078-.609.28-1.346.418-2.211.418h-4.62c-.25 0-.374.125-.374.374v5.368h-2.596v-5.874c0-.836.183-1.437.55-1.804.367-.366.96-.55 1.782-.55h5.346c.367 0 .682-.058.946-.176.264-.117.477-.27.638-.462.161-.19.282-.407.363-.649.08-.242.12-.495.12-.759 0-.542-.164-1.008-.494-1.397-.33-.388-.847-.583-1.551-.583h-7.722v-2.596z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M116.332-1405.788c1.54 0 2.765.48 3.674 1.441.91.961 1.364 2.417 1.364 4.367 0 1.966-.455 3.425-1.364 4.378-.91.954-2.134 1.43-3.674 1.43h-2.442c-1.555 0-2.783-.476-3.685-1.43-.902-.953-1.353-2.412-1.353-4.378 0-1.965.45-3.424 1.353-4.378.902-.953 2.13-1.43 3.685-1.43zm-2.222 2.244c-1.012 0-1.75.28-2.211.836-.462.558-.693 1.467-.693 2.728 0 1.262.23 2.167.693 2.717.462.55 1.199.825 2.21.825h2.003c1.012 0 1.749-.275 2.21-.825.463-.55.694-1.455.694-2.717 0-1.26-.231-2.17-.693-2.728-.462-.557-1.2-.836-2.211-.836z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M125.682-1405.788l1.034 9.064c.03.25.106.437.23.561.126.125.327.187.606.187.264 0 .458-.055.583-.165a.966.966 0 00.275-.473l1.65-7.04c.176-.777.52-1.364 1.034-1.76.513-.396 1.232-.594 2.156-.594.924 0 1.628.198 2.112.594.484.396.814.983.99 1.76l1.562 7.04c.088.426.381.638.88.638.264 0 .455-.062.572-.187.117-.124.19-.311.22-.56l1.21-9.065h2.53l-1.298 9.196c-.117.807-.425 1.456-.924 1.947-.499.492-1.276.737-2.332.737-.91 0-1.613-.198-2.112-.594-.499-.396-.836-.997-1.012-1.804l-1.562-7.062c-.044-.205-.143-.352-.297-.44a1.114 1.114 0 00-.561-.132c-.484 0-.77.191-.858.572l-1.65 7.062c-.19.807-.543 1.408-1.056 1.804-.513.396-1.232.594-2.156.594-.543 0-1.001-.066-1.375-.198a2.5 2.5 0 01-.924-.55 2.41 2.41 0 01-.561-.847 4.831 4.831 0 01-.286-1.089l-1.21-9.196z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M153.226-1405.788c1.379 0 2.376.312 2.992.935.616.624.924 1.5.924 2.63 0 1.07-.3 1.895-.902 2.474-.601.58-1.613.87-3.036.87h-5.522v.835c0 .47.136.855.407 1.155.271.301.73.451 1.375.451h7.106v2.266h-7.106c-.733 0-1.353-.095-1.86-.286a3.328 3.328 0 01-1.253-.803 3.067 3.067 0 01-.715-1.21 5.034 5.034 0 01-.22-1.529v-3.96c0-.542.073-1.048.22-1.518.147-.469.385-.876.715-1.22a3.33 3.33 0 011.254-.804c.506-.19 1.126-.286 1.859-.286zm-5.544 5.082h5.456c.63 0 1.074-.124 1.33-.374.258-.249.386-.586.386-1.012 0-.44-.132-.792-.396-1.056s-.682-.396-1.254-.396h-3.74c-.645 0-1.104.154-1.375.462-.271.308-.407.69-.407 1.144z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M167.9-1405.788v2.244h-4.092c-.997 0-1.496.5-1.496 1.496v7.876h-2.332v-8.228c0-1.173.238-2.03.715-2.574.477-.542 1.287-.814 2.43-.814z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M177.756-1405.788c1.379 0 2.376.312 2.992.935.616.624.924 1.5.924 2.63 0 1.07-.3 1.895-.902 2.474-.601.58-1.613.87-3.036.87h-5.522v.835c0 .47.136.855.407 1.155.271.301.73.451 1.375.451h7.106v2.266h-7.106c-.733 0-1.353-.095-1.86-.286a3.328 3.328 0 01-1.253-.803 3.067 3.067 0 01-.715-1.21 5.034 5.034 0 01-.22-1.529v-3.96c0-.542.073-1.048.22-1.518.147-.469.385-.876.715-1.22a3.33 3.33 0 011.254-.804c.506-.19 1.126-.286 1.859-.286zm-5.544 5.082h5.456c.63 0 1.074-.124 1.33-.374.258-.249.386-.586.386-1.012 0-.44-.132-.792-.396-1.056s-.682-.396-1.254-.396h-3.74c-.645 0-1.104.154-1.375.462-.271.308-.407.69-.407 1.144z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M196.5-1410.826v11.682c0 1.687-.363 2.937-1.09 3.751-.725.814-1.785 1.221-3.178 1.221h-3.212c-1.555 0-2.783-.476-3.685-1.43-.902-.953-1.353-2.412-1.353-4.378 0-1.965.45-3.424 1.353-4.378.902-.953 2.13-1.43 3.685-1.43h5.126v-5.038zm-4.95 14.388c.939 0 1.606-.22 2.002-.66.396-.44.594-1.158.594-2.156v-4.29h-4.906c-1.012 0-1.75.28-2.211.836-.462.558-.693 1.467-.693 2.728 0 1.262.23 2.167.693 2.717.462.55 1.199.825 2.21.825z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M213.594-1409.022c.88 0 1.632.1 2.255.297.623.198 1.137.477 1.54.836.403.36.7.789.89 1.287.192.5.287 1.05.287 1.65 0 .866-.18 1.562-.54 2.09-.358.528-.89.932-1.594 1.21.704.294 1.247.715 1.628 1.265.381.55.572 1.258.572 2.123 0 .602-.095 1.152-.286 1.65a3.26 3.26 0 01-.891 1.287c-.403.36-.917.642-1.54.847-.623.206-1.375.308-2.255.308h-6.6c-.821 0-1.232-.403-1.232-1.21v-12.43c0-.806.41-1.21 1.232-1.21zm-5.214 12.034c0 .162.095.242.286.242h5.06c.792 0 1.371-.168 1.738-.506.367-.337.55-.814.55-1.43 0-1.276-.763-1.914-2.288-1.914h-5.346zm0-5.764h5.28c1.525 0 2.288-.594 2.288-1.782 0-.586-.18-1.056-.54-1.408-.358-.352-.934-.528-1.726-.528h-5.016c-.19 0-.286.088-.286.264z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M233.658-1405.788l-6.996 16.28h-2.508l2.178-5.082c-.66 0-1.14-.11-1.441-.33-.3-.22-.546-.586-.737-1.1l-3.894-9.768h2.464l3.498 8.646c.073.206.154.338.242.396.088.06.242.088.462.088h.308l3.938-9.13z"
                        ></path>
                        <path
                            fill="#fff"
                            d="M238.322-1405.48v2.992h-2.508v-2.992zm0 8.316v2.992h-2.508v-2.992z"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default LogoDejavuAI;