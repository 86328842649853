import '../../../src/CSS/global.css';
import '../../../src/CSS/portfolio.css';
import '../../../src/CSS/mobile.css';
import { setSortMenuState } from "../../storage/redux/slice/varSlice";
import { RootState } from "../../storage/redux/store";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from 'react';
import { W1, W2 } from "../../functions";

function SortMenu({ mobile = false }: any) {
    const dispatch = useDispatch();
    const [showSortButton, setShowSortButton] = useState(false);
    const varState: any = useSelector(
        (state: RootState) => state.varStore
    );
    /*************Calculate data for responsive behaviour************************/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    let mult = 1;
    let mult2 = 0;
    let top1 = -5;
    let height1 = 22;
    let margin = 23;
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    if (mobile) { mult = 1.6; top1 = 0; mult2 = 0.7; height1 = 22; margin = 10; }
    /*******  Sort  **********/
    const ClickSort = () => {
        setShowSortButton(!showSortButton);
    }
    const handleSortMostAlerts = () => {
        dispatch(setSortMenuState(1));
        setShowSortButton(false);
    }
    const handleSortNewest = () => {
        dispatch(setSortMenuState(2));
        setShowSortButton(false);
    }
    const handleSortOldest = () => {
        dispatch(setSortMenuState(3));
        setShowSortButton(false);
    }
    const handleSortAZ = () => {
        dispatch(setSortMenuState(4));
        setShowSortButton(false);
    }
    /**********************/

    return (
        <div className="p-mainbox-sort-dropdown" style={{ height: 50 / mult }}>
            <div className="p-mainbox-sort-image" onClick={ClickSort} style={{ marginLeft: margin / mult, marginRight: margin / mult, top: top1 / mult, width: 145 / mult, height: 40 / mult }}>
            </div>
            {(showSortButton) &&
                <div className="p-mainbox-sort-menu" style={{ width: 159 / (mult - mult2), height: 108 / (mult - mult2), top: 45 / (mult - mult2), left: 17 / (mult - mult2) }}>
                    <div className="p-mainbox-sort-menu-mostalerts p-mainbox-sort-menu-item" style={{ width: 150 / (mult - mult2), height: height1 / (mult - mult2) }} onClick={handleSortMostAlerts}>
                    </div>
                    <div className="p-mainbox-sort-menu-newest p-mainbox-sort-menu-item" style={{ width: 150 / (mult - mult2), height: height1 / (mult - mult2) }} onClick={handleSortNewest}>
                    </div>
                    <div className="p-mainbox-sort-menu-oldest p-mainbox-sort-menu-item" style={{ width: 150 / (mult - mult2), height: height1 / mult }} onClick={handleSortOldest}>
                    </div>
                    <div className="p-mainbox-sort-menu-az p-mainbox-sort-menu-item" style={{ width: 150 / (mult - mult2), height: height1 / (mult - mult2) }} onClick={handleSortAZ}>
                    </div>
                </div>
            }
        </div>
    );
}

export default SortMenu;