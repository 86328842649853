import { createSlice } from "@reduxjs/toolkit";
import { accountModel, userBigModel, resultImageModel } from "../../../Interfaces";
export const initialSubscriptionState: accountModel = {
    users: [],
    userNumber: 1,
    swap: true,
    accountType: 2,
    companyName: "",
    companyAddress: "",
    mediaQuota: 10,
    cost: 0,
    error: "",
    clientSecret: "",

    userNumber_pricecode: "",
    mediaQuota_pricecode: "",
    notificationAddOn_pricecode: "",
    notificationWeb_pricecode: "",
    notificationEmail_pricecode: "",
    notificationSMS_pricecode: "",
    frequencyOnDemand_pricecode: "",
    frequency3Times_pricecode: "",
    frequencyDaily_pricecode: "",
    frequencyWeekly_pricecode: "",
    frequencyMonthly_pricecode: "",

    promocode: "",
};

export const subscriptionSlice = createSlice({
    name: "subscriptionSlice",
    initialState: initialSubscriptionState,
    reducers: {
        setSbscObject: (state, action) => {
            state = action.payload;
        },
        setSbscUserNumber_pricecode: (state, action) => {
            state.userNumber_pricecode = action.payload;
        },
        setSbscMediaQuota_pricecode: (state, action) => {
            state.mediaQuota_pricecode = action.payload;
        },
        setSbscNotificationAddOn_pricecode: (state, action) => {
            state.notificationAddOn_pricecode = action.payload;
        },
        setSbscNotificationSMS_pricecode: (state, action) => {
            state.notificationSMS_pricecode = action.payload;
        },
        setSbscFrequencyOnDemand_pricecode: (state, action) => {
            state.frequencyOnDemand_pricecode = action.payload;
        },
        setSbscFrequency3Times_pricecode: (state, action) => {
            state.frequency3Times_pricecode = action.payload;
        },
        setSbscFrequencyDaily_pricecode: (state, action) => {
            state.frequencyDaily_pricecode = action.payload;
        },
        setSbscFrequencyWeekly_pricecode: (state, action) => {
            state.frequencyWeekly_pricecode = action.payload;
        },
        setSbscFrequencyMonthly_pricecode: (state, action) => {
            state.frequencyMonthly_pricecode = action.payload;
        },
        setSbscNotificationEmail_pricecode: (state, action) => {
            state.notificationEmail_pricecode = action.payload;
        },
        setSbscAccType: (state, action) => {
            state.accountType = action.payload;
        },
        setSbscPromoCode: (state, action) => {
            state.promocode = action.payload;
        },
        setSbscUserRole: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload.userid) {
                    element.role = action.payload.role;
                }
            });
        },
        setSbscUserFrequency: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload.userid) {
                    element.frequencyOnDemand = action.payload.frequencyOnDemand;
                    element.frequency3Times = action.payload.frequency3Times;
                    element.frequencyDaily = action.payload.frequencyDaily;
                    element.frequencyWeekly = action.payload.frequencyWeekly;
                    element.frequencyMonthly = action.payload.frequencyMonthly;
                }
            });
        },
        setSbscUserStatus: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload.userid) {
                    element.userStatus = action.payload.data;
                }
            });
        },
        setSbscUserPassword: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload.userid) {
                    element.password = action.payload.data;
                }
            });
        },
        setSbscUserConfPassword: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload.userid) {
                    element.confpassword = action.payload.data;
                }
            });
        },
        setSbscUserEmail: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload.userid) {
                    element.email = action.payload.data;
                }
            });
        },

        setSbscUserNotificationWeb: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload) {
                    element.notificationWeb = !element.notificationWeb;
                }
            });
        },

        setSbscUserAddOnAvailable: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload.userid) {
                    element.addOnAvailable = action.payload.state;
                }
            });
        },

        setSbscUserNotificationAddOn: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload) {
                    element.notificationAddOn = !element.notificationAddOn;
                }
            });
        },

        setSbscUserNotificationEmail: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload) {
                    element.notificationEmail = !element.notificationEmail;
                }
            });
        },
        setSbscUserNotificationSMS: (state, action) => {
            state.users?.forEach((element: userBigModel) => {
                if (element.userTempId === action.payload) {
                    element.notificationSMS = !element.notificationSMS;
                }
            });
        },
        setSbscUsers: (state, action) => {
            state.users = action.payload;
        },

        setSbscUserNumber: (state, action) => {
            state.userNumber = action.payload;
        },
        setSbscSwap: (state, action) => {
            state.swap = !state.swap;
        },
        setSbscMediaQuota: (state, action) => {
            state.mediaQuota = action.payload;
        },
        setSbscCost: (state, action) => {
            state.cost = action.payload;
        },
        setSbscCompanyName: (state, action) => {
            state.companyName = action.payload;
        },
        setSbscCompanyAddress: (state, action) => {
            state.companyAddress = action.payload;
        },
        setSbscClientSecret: (state, action) => {
            state.clientSecret = action.payload;
        },

    },
});

export const { setSbscUserFrequency,
    setSbscUserConfPassword,
    setSbscUserPassword,
    setSbscUserEmail,
    setSbscUserNotificationEmail,
    setSbscUserNotificationSMS,
    setSbscUserNotificationWeb,
    setSbscUserRole, setSbscObject,
    setSbscAccType,
    setSbscUsers,
    setSbscUserNumber,
    setSbscSwap,
    setSbscMediaQuota,
    setSbscCost,
    setSbscCompanyName,
    setSbscCompanyAddress,
    setSbscUserStatus,
    setSbscClientSecret,
    setSbscUserNotificationAddOn,
    setSbscUserAddOnAvailable,
    setSbscPromoCode,
    setSbscUserNumber_pricecode,
    setSbscMediaQuota_pricecode,
    setSbscNotificationAddOn_pricecode,
    setSbscNotificationSMS_pricecode,
    setSbscFrequencyOnDemand_pricecode,
    setSbscFrequency3Times_pricecode,
    setSbscFrequencyDaily_pricecode,
    setSbscFrequencyWeekly_pricecode,
    setSbscFrequencyMonthly_pricecode,
    setSbscNotificationEmail_pricecode
} = subscriptionSlice.actions;
export const subscriptionReducer = subscriptionSlice.reducer;