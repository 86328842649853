import { createSlice } from "@reduxjs/toolkit";
import { imageArrayModel } from "../../../Interfaces";
import { resultImageModel } from "../../../Interfaces";
import { searchImageArrayModel } from "../../../Interfaces";

const initialState:  imageArrayModel = {
    searchImages: [],
  };

export const imageArraySlice = createSlice({
  name: "searchImages",
  initialState: initialState,
  reducers: {
    setImageArray: (state, action) => {
      state.searchImages = action.payload;
    },
    setAddImageData: (state, action) => {
    },
    removeImage: (state, action) => {
    },
    addSearchImage: (state, action) => {
    },
    addResultImages: (state, action) => {
    },
    sendToTrash: (state, action) => {
    },    
    restoreFromTrash: (state, action) => {
    },    
    emptyTrash: (state, action) => {
    },
    markResultAsIgnored: (state, action) => {
    },
    markResultAsAkn: (state, action) => {
    },            
    markResultAsWaiting: (state, action) => {
    },    
  },
});

export const {
  setImageArray,
  setAddImageData,
  removeImage,
  addSearchImage,
  addResultImages,
  sendToTrash,
  restoreFromTrash,
  emptyTrash,
  markResultAsIgnored,
  markResultAsAkn,
  markResultAsWaiting
} = imageArraySlice.actions;
export const imageArrayReducer = imageArraySlice.reducer;