import { useNavigate, useSearchParams } from "react-router-dom";
import BigMsg from "../Misc/BigMsg";
import { useState } from "react";
import jwt_decode from "jwt-decode";
import { useSendConfCodeMutation } from "../../Apis/authApi";
import { apiResponse, userModel } from "../../Interfaces";
import { setLoggedInUser } from "../../storage/redux/slice/userAuthSlice";
import { useDispatch } from "react-redux";

function Enter2FACode() {
  const [searchParams] = useSearchParams();
  let username = searchParams.get("username");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [sendConfCode] = useSendConfCodeMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const InputCode = (e: any) => {
     setCode(e.target.value);
     setError("");
     } 

  const SubmitCode =  async () => {
    if(username !== null) username = username.replace(/@/g, '%40');
    const response: apiResponse =  await sendConfCode({code, username});
    if (response.data) {
      console.log(response);
      console.log("response.data.message");
      console.log(response.data.message);

      if (response.data.message == "wrongcode") {
          setError("Error: You have entered the wrong code");
      }
      else if (response.data.message == "codeok") 
      {
        const { token } = response.data.result;
        console.log(token);
        const { fullName, id, email, role }: userModel = jwt_decode(token);
        console.log(fullName);
        console.log(id);
        console.log(email);
        console.log(role);
        localStorage.setItem("atoken", token);
        dispatch(setLoggedInUser({ fullName, id, email, role }));
        navigate("/Portfolio");
      }

    }else if(response.error) {
        setError("Error: " + response.error.data.title);
    }
  }

  const title = "Log In  (2FA)";
  const subtitle = "We have sent you an email with confirmation code. Please enter the code here..";
  const message = "";
  const inputs = [
    {"toptext": "Confirmation Code", "type":"text", "id":"emailconfcode", "value":"", "handler": InputCode},
    {"toptext": "", "type":"", "id":"", "value":"", "handler": ()=>true},
    {"toptext": "", "type":"", "id":"", "value":"", "handler": ()=>true},        
  ]
  const buttons = [
    {"handler": SubmitCode, "title": "Continue"},
    {"handler": () => navigate(-1), "title": "Back"},
    {"handler": ()=>true, "title": ""},
  ]

  return (
        <BigMsg  title={title} subtitle={subtitle} message={message} error={error} inputs={inputs} buttons={buttons}/>
  );

}

export default Enter2FACode;