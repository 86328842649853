import '../CSS/global.css';
import '../CSS/pages.css';
import '../CSS/trash.css';
import { useState, useEffect, useRef } from 'react';
import { useScrollbar } from '../Hooks/use-scrollbar-y';
import { Link } from "react-router-dom";
import { W0, W1, W2 } from "../functions";


function About() {

  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /**************** Scroll bar  things*********************************/
  const termsWrapper = useRef(null);
  const hasScroll = true;

  useScrollbar(termsWrapper, hasScroll, windowWidth);
  let mult = 1;
  if (windowWidth > 600) {
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (

      <div className="maincontainer2">
        <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="pg-mainbox" style={{ width: 1320 / mult, height: 690 / mult }}>
            <div className="pg-mainbox-top" style={{ width: 1250 / mult, height: 80 / mult }}>
              <span className="pg-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px', marginBottom: 20 / mult }}>About Us</span>
            </div>
            <div className="pg-mainbox-media" style={{ width: 1230 / mult, height: 597 / mult }}>
              <div style={{ width: 1100 / mult, paddingRight: '12px', height: 450 / mult, minHeight: 450 / mult, marginBottom: 100 / mult }} ref={termsWrapper}>
                <div className="" style={{ height: 1000 / mult, fontSize: 16, marginRight: 50 / mult }}>
                  <AboutText />
                </div>
              </div >
            </div >
          </div >
        </div >
      </div >
    );
  } else {
    // Mobile *************************************************************

    mult = 600 / windowWidth;
    const mult1 = 3;
    return (
      <>
        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>How It Works</span>
          </div>
          <div className="main_div_mob" style={{ width: 550 / mult }}>
            <div style={{ width: 500 / mult }}>
              <AboutText />
            </div>
          </div>
        </div >
      </>
    );
  }

}

function AboutText() {
  return (
    <div style={{ fontWeight: 300, letterSpacing: '1px', fontSize: 20 }}>

      <p><span style={{ fontWeight: 500 }}>At DejaVuAI, we believe in using our powers for good instead of evil, and like to offer solutions to those that need it.</span></p>
      <p>We are aware that art-theft and plagiarism is as rampant as ever in this age of online marketplaces, digital portfolios, crypto and AI; it seems as though every recent technological advancement just makes it harder for artists to survive in the digital space. Our aim is to break the pattern and give creators back the control over their own art, and we’re honored to have the ability to do so. Our image-matching technology is superior to every other reverse image search engine out there, able to match images that have been mirrored, skewed, cropped, rotated, warped, and even color corrected. This offers a unique opportunity to tackle an issue that has plagued artists for decades.</p>
      <p>Visit <a href="https://dejavuai.com" target="_blank">DejaVuAI.com</a> to learn more about us and what we can do.</p>
      <p></p>
      <p style={{ fontWeight: 500 }}>Artworks by:</p>

      Cristian Rod<br />
      Charlie Criner<br />
      Alexander Campbell:<br />
      <a href="https://alzandah.com" rel="noreferrer" target="_blank">https://alzandah.com</a><br />
      Nina Reyes:< br />
      <a href="https://kakaibastudio.etsy.com" rel="noreferrer" target="_blank">https://kakaibastudio.etsy.com</a><br />
      MulchMouth: < br />
      <a href="https://mulchmouth.carrd.co" rel="noreferrer" target="_blank">https://mulchmouth.carrd.co</a><br />
      Briana Bromet:< br />
      <a href="https://bybrianabromet.com" rel="noreferrer" target="_blank">https://bybrianabromet.com</a><br />
      Unsplash < br />
    </div >

  );
}

export default About;