import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import ContinueButton from '../Misc/ContinueButton';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { W0, W1, W2 } from "../../functions";

function TrialCreated() {
  const navigate = useNavigate();
  const goTo = () => {
    navigate("/Login");
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < W1) { mult = W1 / windowWidth; }
  if (windowWidth < W2) { mult = W1 / W2; }
  if (windowWidth > W0) {
    return (
      <div className="maincontainer2 ">
        <div style={{ width: 1260 / mult, height: 580 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="mainbox" style={{ height: 560 / mult, width: 1240 / mult, fontSize: 12 / mult }}>
            <span className="colord9d9d9 midfont" style={{ marginBottom: 80 / mult }}>
              Congratulations! Your account with  30 days trial plan is ready.
            </span>
            <ContinueButton nav={goTo} />
          </div>
        </div>
      </div>
    );
  } else {
    mult = W0 / windowWidth;
    return (
      <div className="maincontainer2 ">
        <div className="mainbox" style={{ width: '100%', height: '100%', fontSize: 12 / mult }}>
          <span className="colord9d9d9 midfont" style={{ marginBottom: 80 / mult }}>
            Congratulations! Your account with  30 days trial plan is ready.
          </span>
          <ContinueButton nav={goTo} />
        </div>
      </div>
    );
  }
}

export default TrialCreated; 