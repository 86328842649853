import '../CSS/global.css';
import '../CSS/pages.css';
import '../CSS/trash.css';
import { useState, useEffect, useRef } from 'react';
import { useScrollbar } from '../Hooks/use-scrollbar-y';
import { Link } from "react-router-dom";
import { W0, W1, W2 } from "../functions";


function HowItWorks() {

    /*************Calculate data for responsive behaviour************************/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    /**************** Scroll bar  things*********************************/
    const termsWrapper = useRef(null);
    const hasScroll = true;

    useScrollbar(termsWrapper, hasScroll, windowWidth);
    let mult = 1;
    if (windowWidth > 600) {
        if (windowWidth < W1) { mult = W1 / windowWidth; }
        if (windowWidth < W2) { mult = W1 / W2; }
        return (

            <div className="maincontainer2">
                <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
                    <div className="pg-mainbox" style={{ width: 1320 / mult, height: 690 / mult }}>
                        <div className="pg-mainbox-top" style={{ width: 1250 / mult, height: 80 / mult }}>
                            <span className="pg-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px', marginBottom: 20 / mult }}>How It Works</span>
                        </div>
                        <div className="pg-mainbox-media" style={{ width: 1230 / mult, height: 597 / mult }}>
                            <div style={{ width: 1100 / mult, paddingRight: '12px', height: 450 / mult, minHeight: 450 / mult, marginBottom: 100 / mult }} ref={termsWrapper}>
                                <div className="" style={{ height: 1000 / mult, fontSize: 16, marginRight: 50 / mult }}>
                                    <HowItWorksText />
                                </div>
                            </div >
                        </div >
                    </div >
                </div >
            </div >
        );
    } else {
        // Mobile *************************************************************

        mult = 600 / windowWidth;
        const mult1 = 3;
        return (
            <>
                <div className="bg_div_mob">
                    <div className="title_div_mob" style={{ width: 540 / mult }}>
                        <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>How It Works</span>
                    </div>

                    <div className="main_div_mob" style={{ width: 550 / mult }}>
                        <div style={{ width: 500 / mult }}>
                            <HowItWorksText />
                        </div>
                    </div>
                </div >
            </>
        );
    }

}

function HowItWorksText() {
    return (
        <div style={{ fontWeight: 300, letterSpacing: '1px', fontSize: 20 }}>
            <p style={{ fontWeight: 500, letterSpacing: '0px', fontSize: 25 }}>The solution to a decades-long problem.</p>

            <p><span style={{ fontWeight: 500 }}>DejaVu.art</span> is a tool for artists and creatives to help combat art theft, be it plagiarism, edits, or any other unauthorized type of reupload of the user’s work.</p>

            <p>Users will upload their images to their private <span style={{ fontWeight: 500 }}>DejaVu.art portfolio</span>, which are then searched against recently-uploaded images around the internet using <a href="https://dejavuai.com" target="_blank">DejaVuAI</a>’s unmatched image recognition algorithm. The focus is on checking other art portfolio websites and retailers who may sell unauthorized prints or merchandise of people’s art, even if it has been cropped, color corrected, transformed, or edited in almost any other way. If a match is found, the user will be notified on their account with a report on which image of theirs has been found on another site along with a link to said image, so they can verify and take appropriate action to stop theft in its tracks.</p>

            <p><span style={{ fontWeight: 500 }}>DejaVu.art is not a legal service</span>, and will not offer any legal advice or send Cease & Desist letters on any user’s behalf.<br />
                Rather, it is a tool for artists to keep an eye out for art theft instead of letting it slip by the wayside.</p>

            <p></p>

            <p>If you are </p>
            <p>&nbsp;&nbsp;&nbsp;an artist,</p>

            <p>&nbsp;&nbsp;&nbsp;photographer,</p>

            <p>&nbsp;&nbsp;&nbsp;graphic designer,</p>

            <p>&nbsp;&nbsp;&nbsp;illustrator,</p>

            <p>&nbsp;&nbsp;&nbsp;game developer,</p>

            <p>&nbsp;&nbsp;&nbsp;fashion designer,</p>

            <p>&nbsp;&nbsp;&nbsp;painter,</p>

            <p>&nbsp;&nbsp;&nbsp;…or simply want to keep track of any image of yours</p>

            <p style={{ fontWeight: 500, letterSpacing: '0px', fontSize: 25 }}>We can help.</p>
        </div>

    );
}

export default HowItWorks;