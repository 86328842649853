import React, { useState, useEffect } from "react";
import { useLoginUserMutation } from "../../Apis/authApi";
import { inputHelper } from "../helper";
import { apiResponse, userModel } from "../../Interfaces";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoggedInUser } from "../../storage/redux/slice/userAuthSlice";
import imageApi from "../../Apis/imageApi";
import CheckBoxBlock from "../Misc/CheckBoxBlock";
import MiniLoader from "../Misc/MiniLoader";
import '../../../src/CSS/global.css';
import '../../../src/CSS/loginandreg.css';
import { Link } from "react-router-dom";
import { W0, W1, W2 } from "../../functions";

function Login() {
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [loginUser] = useLoginUserMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    userName: "",
    password: "",
  });
  const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tempData = inputHelper(e, userInput);
    setUserInput(tempData);
    setError("");
  };

  const [checkbox, setCheckBox] = useState(false);
  const checkboxhandler = () => {
    setCheckBox(!checkbox)
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (userInput.userName !== "" && userInput.password !== "") {
      dispatch(imageApi.util.resetApiState());

      setIsLoading(true);
      const response: apiResponse = await loginUser({
        login: userInput.userName,
        password: userInput.password,
      });
      setIsLoading(false);
      if (response.data) {
        if (response.data.message === "emailnotconfirmed") {
          setError("Error: your email address is not confirmed yet.");
        } else if (response.data.message === "codesent") {
          navigate("/Enter2FACode?username=" + userInput.userName);
        } else if (response.data.message === "wronguserpassword") {
          setError("Error: user name or password is wrong.");
        } else if (response.data.message === "ok") {
          const { token } = response.data.result;
          const { fullName, id, email, role }: userModel = jwt_decode(token);
          if (checkbox) {
            localStorage.setItem("atoken", token);
          } else {
            sessionStorage.setItem("atoken", token);
          }
          const actas_email = email;
          const actas_id = id;
          dispatch(setLoggedInUser({ fullName, id, email, role, actas_id, actas_email, }));
          navigate("/Portfolio");
        } else {
          setError("Unknown Error");
        }
      } else if (response.error) {
        setError("Error: " + response.error.data.title);
      }
    }

  };


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const buttonsarray = [{ "id": "cb1111", "title": "", "buttonstate": checkbox, "buttonhandler": checkboxhandler },]

  let mult = 1;
  if (windowWidth > 600) {
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (
      <>
        <div className="maincontainer2 colorcccccc">
          <div id="dgdfgdfg34" style={{ marginTop: 90, width: 1280 / mult, height: 570 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
            <div className="loginbox " style={{ width: 1260 / mult, height: 560 / mult }}>
              <div className="login-title loginfont" style={{ width: 1100 / mult, height: 125 / mult, fontSize: 17 / mult, fontWeight: 500 / mult }}>
                <span className='login-bigtitle colord9d9d9' style={{ fontSize: 40 / mult }}>Sign In</span><br />
                Welcome back<br />
                <span className="login-errormessage">{error}&nbsp;&nbsp;</span>
              </div>
              <div className="login-emaildiv" style={{ width: 645 / mult, height: 84 / mult }}>
                <span className="login-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Email Address</span>
                <input
                  type="email"
                  id="loginemail"
                  name="userName"
                  value={userInput.userName}
                  onChange={handleUserInput}
                  size={50}
                  style={{ outline: 'none', width: 640 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                  required />
              </div>
              <div className="login-passworddiv" style={{ width: 645 / mult, height: 70 / mult }}>
                <span className="login-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Password</span>
                <input
                  type="password"
                  id="loginpassword"
                  value={userInput.password}
                  onChange={handleUserInput}
                  name="password"
                  size={50}
                  style={{ outline: 'none', width: 640 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                  required />
              </div>
              <div className="login-forgotpass" style={{ width: 500 / mult, height: 80 / mult }}>

                <div className="login-keepme1">
                  <CheckBoxBlock buttonsarray={buttonsarray} layout={"column"} />
                  <span className="loginfont" style={{ fontSize: 17 / mult, fontWeight: 400 / mult }}>Keep me signed in </span>
                </div>
                <div style={{ marginTop: '4px' }}>
                  <Link to="/CreateTrial">
                    <span className="login-forgotpasslink color698ff9 loginfont" style={{ fontSize: 17 / mult, fontWeight: 400 / mult }}>New user?</span>
                  </Link>
                </div>
                <div style={{ marginTop: '4px' }}>
                  <Link to="/ForgotPassword" >
                    <span className="login-forgotpasslink color698ff9 loginfont" style={{ fontSize: 17 / mult, fontWeight: 400 / mult }}>Forgot password?</span>
                  </Link>
                </div>
              </div>
              {loading ? (<MiniLoader />) :
                (
                  <div className="login-buttondiv" onClick={handleSubmit} style={{ width: 470 / mult, height: 70 / mult }}>
                  </div>
                )
              }
            </div>
          </div>
        </div >

      </>
    );
  } else {
    mult = 600 / windowWidth;
    return (
      <>
        <div className="maincontainer2 colorcccccc">
          <div className="loginbox-mob" style={{ width: '100%', height: '100%' }}>
            <div className="login-title loginfont" style={{ textAlign: 'center', width: '100%', height: 125 / mult, fontSize: 17 / mult, fontWeight: 500 / mult }}>
              <span className='login-bigtitle colord9d9d9' style={{ fontSize: 40 / mult }}>Sign In</span><br />
              Welcome back<br />
              <span className="login-errormessage">{error}&nbsp;&nbsp;</span>
            </div>
            <div className="login-emaildiv" style={{ alignItems: 'center', width: 350 / mult, height: 80 / mult }}>
              <span className="login-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Email Address</span>
              <input
                type="email"
                id="loginemail"
                name="userName"
                value={userInput.userName}
                onChange={handleUserInput}
                size={50}
                style={{ outline: 'none', width: 350 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                required />
            </div>
            <div className="login-passworddiv" style={{ alignItems: 'center', width: 350 / mult, height: 80 / mult }}>
              <span className="login-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Password</span>
              <input
                type="password"
                id="loginpassword"
                value={userInput.password}
                onChange={handleUserInput}
                name="password"
                size={50}
                style={{ outline: 'none', width: 350 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                required />
            </div>

            <div className="login-keepme1" style={{ marginTop: '20px' }}>
              <CheckBoxBlock buttonsarray={buttonsarray} layout={"column"} />
              <span className="loginfont" style={{ fontSize: 17 / mult, fontWeight: 400 / mult }}>Keep me signed in </span>
            </div>

            <div className="login-forgotpass" style={{ width: 300 / mult, height: 80 / mult, marginTop: '20px' }}>
              <div style={{ marginTop: '4px' }}>
                <Link to="/CreateTrial">
                  <span className="login-forgotpasslink color698ff9 loginfont" style={{ fontSize: 17 / mult, fontWeight: 400 / mult }}>New user?</span>
                </Link>
              </div>
              <div style={{ marginTop: '4px' }}>
                <Link to="/ForgotPassword" >
                  <span className="login-forgotpasslink color698ff9 loginfont" style={{ fontSize: 17 / mult, fontWeight: 400 / mult }}>Forgot password?</span>
                </Link>
              </div>
            </div>
            {loading ? (<MiniLoader />) :
              (
                <div className="login-buttondiv" onClick={handleSubmit} style={{ width: 330 / mult, height: 50 / mult }}>
                </div>
              )
            }
          </div>
        </div>
      </>
    );
  }
}

export default Login;