import { createSlice } from "@reduxjs/toolkit";
import { accountModel,userBigModel,resultImageModel } from "../../../Interfaces";
export const initialAccountState: accountModel = {
    users: [],
    userNumber: 1,
    swap: true,
    accountType: 2,
    companyName: "",
    companyAddress: "",  
    mediaQuota: 10,
    cost: 0,
    error: "",
    primaryUser: true
  };

export const accountSlice = createSlice({
    name: "accountSlice",
    initialState:initialAccountState,
    reducers:{
        updateaction: (state,action) => {
            state.users = action.payload;
        },

    },
});

export const {updateaction} =  accountSlice.actions;
export const accountReducer = accountSlice.reducer;