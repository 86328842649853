import { Routes, Route } from "react-router-dom";
import ProtectingYourArtCmp from '../assets/img/HomePage1/ProtectingYourArtCmp';
import { useState, useEffect } from 'react';
//import '../CSS/global.css';
//import '../CSS/homepage.css';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "../storage/redux/store";
import { userModel } from "../Interfaces";
import { useSelector, useDispatch } from "react-redux";
import bgimage from "../assets/img/Bg/bg_v1_1920x1080.png";
import GetStartedButton from "../assets/svgtojsx/GetStartedButton";
import HowItWorks from "../assets/svgtojsx/HowItWorks";
import LogoDejavuAIHome from "../assets/svgtojsx/LogoDejavuAIHome";



const HomePage1 = () => {
  const navigate = useNavigate();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );

  /***************Consent****************************/
  const consentPropertyName = 'c_consent';
  const shouldShowConsent = () => !localStorage.getItem(consentPropertyName);
  const saveConsentToStorage = () => localStorage.setItem(consentPropertyName, "1");

  const HideConsent = () => {
    const consentPopup = document.getElementById('consent-popup');
    if (consentPopup !== null) {
      consentPopup.classList.add('hideconsent');
    }
  };

  useEffect(() => {
    const consentPopup = document.getElementById('consent-popup');
    if (consentPopup !== null && shouldShowConsent()) {
      setTimeout(() => {
        consentPopup.classList.remove('hideconsent');
      }, 1000);
    }
  });

  const ClickDecline = () => {
    HideConsent();
  };

  const ClickAccept = () => {
    saveConsentToStorage();
    HideConsent();
  };
  //document.body.style.backgroundImage = " url('../assets/img/Bg/bg_v1_1920x1080.png') no-repeat center center"
  //document.body.style.background = "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('../assets/img/Bg/bg_v1_1920x1080.png') no-repeat center center;"

  /***************end of Consent****************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowWidth]);
  let mult = 1920 / windowWidth;
  /************************/

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("p") == "1") {
      navigate("/EnterNewPassword?token=" + searchParams.get("token") + "&email=" + searchParams.get("email"));
    }
    if (searchParams.get("c") == "1") {
      navigate("/EmailConfirmed?token=" + searchParams.get("token") + "&email=" + searchParams.get("email"));
    }
  }, []);

  if (windowWidth > 600) {
    return (
      <div className='maincontainer' style={{

        width: '100vw', height: '100vh', background: `url(${bgimage}) no-repeat center center`, backgroundSize: 'cover'
      }}>
        <div className='homepage1left' >


          <div className='protectingpourart' >
            <ProtectingYourArtCmp w={windowWidth / 2.537} h={(windowWidth / 2.937) / 2.142} />
          </div >
        </div >

        <div className='homepage1right colord9d9d9 smallfont'>
          <div className="homepage1rightbg"></div>
          <div className='homepage1text'>
            <span style={{ fontSize: '3vw', fontWeight: 500 }}>WHAT WE DO: </span><br />
            <span style={{ fontSize: '1.5vw', fontWeight: 300 }}>
              We help artists and organizations that create or collect art, helping them find if their art is being uploaded or sold somewhere else without their consent.
            </span>
            <div className="divider1">&nbsp;</div>
            <span style={{ fontSize: '2vw', fontWeight: 300 }}> What type of Art? </span>
            <span style={{ fontSize: '1.5vw', fontWeight: 300 }}>Digital and traditional illustration, painting, photography, concept art, background and texture art, graphic design...</span>
            <div className="divider1">&nbsp;</div>
            <span style={{ fontSize: '2vw', fontWeight: 300 }}>Any Image you consider art* </span><br />
            <span style={{ fontSize: '1.5vw', fontWeight: 300 }}>We're here to help you protect your portfolio.</span><br /><br /><br />
          </div>
          <div className='homepage1buttons'>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <span className="homepage1imagerec" style={{ fontSize: '2vw', fontWeight: 500 }}>Image recognition, powered by&nbsp;</span>
              </div>
              <a href="https://www.dejavuai.com" rel="noreferrer" target="_blank" >
                <div style={{ marginTop: 10 / mult }}>
                  <LogoDejavuAIHome mult={mult} />
                </div>
              </a>
            </div>

            <div className="divider1">&nbsp;</div>
            <Link to="/HowItWorks">
              <HowItWorks mult={1} />
            </Link>

            {(userData.id == "") &&
              <>
                <span className="homepage1or" style={{ fontSize: '2vw', fontWeight: 500 / mult }}>Or</span>
                <Link to="/CreateTrial">

                  <GetStartedButton mult={1} />

                </Link>
              </>
            }
          </div>
        </div>

        <div id="consent-popup" className="consent-popup hideconsent colore6e6e6" style={{ height: 135 / mult, fontSize: 30 / mult, fontWeight: 300 }}>
          <p style={{ marginTop: 15 / mult, marginLeft: 15 / mult, letterSpacing: '2px' }}>This site uses cookies to verify customer's data :&nbsp;
            <a href="#" className="color698ff9" style={{ height: 135 / mult, fontSize: 30 / mult, fontWeight: 500 }} onClick={ClickAccept}>Accept</a>&nbsp;/&nbsp;
            <a href="#" className="color698ff9" style={{ height: 135 / mult, fontSize: 30 / mult, fontWeight: 500 }} onClick={ClickDecline}>Decline</a>
          </p>
        </div>
      </div >
    )
  } else {
    mult = mult / 2.2
    /*
    if (userData.id == "") {
      mult = mult / 2.2
    } else {
      mult = mult / 2.5
    }*/

    return (
      <div className='maincontainer'>
        <div className='homepage1left'>


          <div className='protectingpourart'>
            <ProtectingYourArtCmp w={windowWidth / 2.537} h={(windowWidth / 2.937) / 2.142} />
          </div >
        </div >

        <div className='homepage1right colord9d9d9 smallfont' style={{ justifyContent: 'center' }}>
          <div className="homepage1rightbg"></div>
          <div className='homepage1text' style={{ marginTop: 130, paddingLeft: 30 }}>
            <span className="bigfont" style={{ fontSize: 50 / mult + 'px', fontWeight: 500 / mult }}>WHAT WE DO: </span><br />
            <span style={{ fontSize: 22 / mult + 'px', fontWeight: 300 / mult, lineHeight: 'normal' }}>
              We help artists and organizations that create or collect art, helping them find if their art is being uploaded or sold somewhere else without their consent.
            </span>
            <br />
            <span className="midfont" style={{ fontSize: 28 / mult + 'px', fontWeight: 400 / mult }}>What type of Art? </span>
            <span style={{ fontSize: 22 / mult + 'px', fontWeight: 300 / mult }}>Digital and traditional illustration, painting, photography, concept art, background and texture art, graphic design ...</span>
            <br />
            <span className="midfont" style={{ fontSize: 28 / mult + 'px', fontWeight: 400 / mult }}>Any Image you consider art* </span><br />
            <span style={{ fontSize: 22 / mult + 'px', fontWeight: 300 / mult }}>We're here to help you protect your portfolio..</span><br /><br /><br />
          </div>



          <div className='homepage1buttons' style={{ marginBottom: 170 }}>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <span className="homepage1imagerec" style={{ fontSize: '2vw', fontWeight: 500 }}>Image recognition, powered by&nbsp;</span>
              </div>
              <a href="https://www.dejavuai.com" rel="noreferrer" target="_blank" >
                <div style={{ marginTop: 10 / mult }}>
                  <LogoDejavuAIHome mult={mult + 0.6} />
                </div>
              </a>
            </div>


            <Link to="/HowItWorks">

              <HowItWorks mult={1.5} />

            </Link>

            {(userData.id == "") &&
              <>
                <span className="homepage1or" style={{ fontSize: 22 / mult + 'px', fontWeight: 500 / mult }}>Or</span>

                <Link to="/CreateTrial">

                  <GetStartedButton mult={1.5} />

                </Link>
              </>
            }
          </div>
        </div>

        <div id="consent-popup" className="consent-popup hideconsent colore6e6e6" style={{ height: 200 / mult, fontSize: 30 / mult, fontWeight: 300 }}>
          <p style={{ marginTop: 15 / mult, marginLeft: 15 / mult, letterSpacing: '2px', textAlign: 'center' }}>This site uses cookies to verify customer's data :&nbsp;</p>
          <p style={{ textAlign: 'center' }}>
            <a href="#" className="color698ff9" style={{ height: 135 / mult, fontSize: 30 / mult, fontWeight: 500 }} onClick={ClickAccept}>Accept</a>&nbsp;/&nbsp;
            <a href="#" className="color698ff9" style={{ height: 135 / mult, fontSize: 30 / mult, fontWeight: 500 }} onClick={ClickDecline}>Decline</a>
          </p>
        </div>
      </div>
    )

  }
}

export default HomePage1;