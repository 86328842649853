import { Routes, Route, useLocation, useSearchParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { userModel } from "../Interfaces";
import { useDispatch } from "react-redux";
import { setLoggedInUser } from "../storage/redux/slice/userAuthSlice";
import '../CSS/headerandhome.css';
import { useState, useEffect } from 'react';
import HomePage1 from '../pages/HomePage1';
import Header from '../components/Header/Header'
import Login from "../components/Auth/Login";
import EmailConfirmed from "../components/Auth/EmailConfirmed";
import ForgotPassword from "../components/Auth/ForgotPassword";
import ResetPasswordEmailSent from "../components/Auth/ResetPasswordEmailSent";
import CreateAccount from "../components/Account/CreateAccount";
import UpgradeAccount from "../components/Account/UpgradeAccount";
import EnterPaymentInfo from "../components/Account/EnterPaymentInfo";
import CheckoutI from "../components/Account/CheckoutI";
import CheckoutB from "../components/Account/CheckoutB";
import Completion from "../components/Account/Completion";
import ConfirmEmail from "../components/Auth/ConfirmEmail";
import EnterNewPassword from "../components/Auth/EnterNewPassword";
import PasswordChanged from "../components/Auth/PasswordChanged";
import CreateTrial from "../components/Auth/CreateTrial";
import Enter2FACode from "../components/Auth/Enter2FACode";
import TrialCreated from "../components/Auth/TrialCreated";
import Reports from "../components/Reports/Reports";
import Account from "../components/Account/Account";
import BigMsg from "../components/Misc/BigMsg";
import SimpleMsg1 from "../components/Misc/SimpleMsg1";
import Test1 from "../components/Misc/Test1";
import Portfolio from "../components/Portfolio/Portfolio";
import Trash from "../components/Trash/Trash";
import WhatAccType from "../components/SmallMsgs/WhatAccType";
import WantCancel from "../components/SmallMsgs/WantCancel";

import Terms from "../../src/pages/Terms";
import Privacy from "../../src/pages/Privacy";
import Help from "../../src/pages/Help";
import Contact from "../pages/Contact";
import News from "../../src/pages/News";
import Settings from "../../src/pages/Settings";
import About from "../../src/pages/About";
import NotYet from "../../src/pages/NotYet";
import HowItWorks from "../../src/pages/HowItWorks";
import { useNavigate } from "react-router-dom";


//            <Route path="/" element={<HomePage1 />} />
//<Route path="/Trash" element={<Trash />} />

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /***************Consent****************************/
  const consentPropertyName = 'c_consent';
  const shouldShowConsent = () => !localStorage.getItem(consentPropertyName);
  const saveConsentToStorage = () => localStorage.setItem(consentPropertyName, "1");

  const HideConsent = () => {
    const consentPopup = document.getElementById('consent-popup');
    if (consentPopup !== null) {
      consentPopup.classList.add('hideconsent');
    }
  };

  useEffect(() => {
    const consentPopup = document.getElementById('consent-popup');
    if (consentPopup !== null && shouldShowConsent()) {
      setTimeout(() => {
        consentPopup.classList.remove('hideconsent');
      }, 1000);
    }
  });

  const location = useLocation();


  const [searchParams] = useSearchParams();

  useEffect(() => {
    let localToken = localStorage.getItem("atoken");
    if (localToken) {
      const { fullName, id, email, role }: userModel = jwt_decode(localToken);
      const actas_email = email;
      const actas_id = id;
      dispatch(setLoggedInUser({ fullName, id, email, role, actas_id, actas_email, }));
      if (searchParams.get("redirect_status") === "succeeded") {
        navigate("/Completion?payment_intent=" + searchParams.get("payment_intent") + "&payment_intent_client_secret=" + searchParams.get("payment_intent_client_secret"));
      }
      // navigate("/Portfolio");
    }
    localToken = sessionStorage.getItem("atoken");
    if (localToken) {
      const { fullName, id, email, role }: userModel = jwt_decode(localToken);
      const actas_email = email;
      const actas_id = id;
      dispatch(setLoggedInUser({ fullName, id, email, role, actas_id, actas_email, }));
      if (searchParams.get("redirect_status") === "succeeded") {
        navigate("/Completion?payment_intent=" + searchParams.get("payment_intent") + "&payment_intent_client_secret=" + searchParams.get("payment_intent_client_secret"));
      }
      //navigate("/Portfolio");
    }
  }, []);

  const ClickDecline = () => {
    HideConsent();
  };

  const ClickAccept = () => {
    saveConsentToStorage();
    HideConsent();
  };
  /***************end of Consent****************************/

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  let mult = 1920 / windowWidth;
  //<Route path="/" element={<HomePage1 />} />            
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage1 />} />
        <Route path="/SimpleMsg1" element={<SimpleMsg1 />} />
        <Route path="/HowItWorks" element={<HowItWorks />} />
        <Route path="/WantCancel" element={<WantCancel />} />
        <Route path="/CheckoutI" element={<CheckoutI />} />
        <Route path="/CheckoutB" element={<CheckoutB />} />
        <Route path="/Completion" element={<Completion />} />
        <Route path="/EnterPaymentInfo" element={<EnterPaymentInfo />} />
        <Route path="/Test1" element={<Test1 />} />
        <Route path="/EmailConfirmed" element={<EmailConfirmed />} />
        <Route path="/Enter2FACode" element={<Enter2FACode />} />
        <Route path="/ConfirmEmail" element={<ConfirmEmail />} />
        <Route path="/EnterNewPassword" element={<EnterNewPassword />} />
        <Route path="/ResetPasswordEmailSent" element={<ResetPasswordEmailSent />} />
        <Route path="/PasswordChanged" element={<PasswordChanged />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/CreateTrial" element={<CreateTrial />} />
        <Route path="/TrialCreated" element={<TrialCreated />} />
        <Route path="/Reports" element={<Reports />} />
        <Route path="/Account" element={<Account />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Trash" element={<Trash />} />
        <Route path="/About" element={<About />} />
        <Route path="/News" element={<News />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Help" element={<Help />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/NotYet" element={<NotYet />} />
        <Route path="/WhatAccType" element={<WhatAccType />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/CreateAccount" element={<CreateAccount />} />
        <Route path="/UpgradeAccount" element={<UpgradeAccount />} />
      </Routes>
      <p style={{
        width: '100%',
        color: '#ccc',
        fontSize: '0.9em',
        textAlign: 'center',
        position: 'absolute',
        bottom: 0,
        letterSpacing: '1px',
        zIndex: 5,
      }}>&copy; 2023 DejaVuAI Inc.</p>
    </>
  )
}

export default App;