import { userModel } from "../Interfaces";
import jwt_decode from "jwt-decode";

const withAuth = (WrappedComponent: any) => {
  return (props: any) => {
    let accessToken = localStorage.getItem("atoken");
    if (!accessToken) {
      accessToken = sessionStorage.getItem("atoken");
    }
    if (accessToken) {
      const { fullName, id, email, role }: userModel = jwt_decode(accessToken);
      if (id) {
        return <WrappedComponent {...props} />;
      }
    }
    window.location.replace("/Login");
    return null;
  };
};

export default withAuth;