import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import '../../../src/CSS/headerandhome.css';
import '../../../src/CSS/global.css';
import { NavLink, useNavigate } from "react-router-dom";
import { userModel } from "../../Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../storage/redux/store";
import {
    emptyUserState,
    setLoggedInUser,
} from "../../storage/redux/slice/userAuthSlice";
import {
    useGetAllImagesInfoQuery,
} from "../../Apis/imageApi";
import SignInButton from "../../assets/svgtojsx/SignInButton";

//import { SD_Roles } from "../../Utility/SD";

// <Link to=''> </Link>

const Header = () => {

    const [unreadReports, setUnreadReports] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    let mult = 1920 / windowWidth;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
    );

    // Are unreaded results
    const userid = userData.id;
    let userasid = userData.actas_id;
    let sort = 1;
    const { data } = useGetAllImagesInfoQuery({ userid, userasid, sort });
    let unreadReportsFlag = false;
    if (data?.notaknnumber !== undefined && data.notaknnumber > 0) { unreadReportsFlag = true }
    useEffect(() => {
        setUnreadReports(unreadReportsFlag);
    }, [unreadReportsFlag]);
    //*****************

    const handleLogout = () => {
        setDropdown(!dropdown);
        localStorage.removeItem("atoken");
        sessionStorage.removeItem("atoken");
        dispatch(setLoggedInUser({ ...emptyUserState }));
        navigate("/Login");
    }

    const handleDropdown = () => {
        setDropdown(!dropdown);
    }
    const handleAccount = () => {
        setDropdown(!dropdown);
        navigate("/Account");
    }
    const handleSettings = () => {
        setDropdown(!dropdown);
        navigate("/Settings");
    }
    const handleHowItWork = () => {
        setDropdown(!dropdown);
        navigate("/HowItWorks");
    }
    const handleAbout = () => {
        setDropdown(!dropdown);
        navigate("/About");
    }
    const handleHelp = () => {
        setDropdown(!dropdown);
        navigate("/Help");
    }
    const handleContact = () => {
        setDropdown(!dropdown);
        navigate("/Contact");
    }
    const handlePrivacy = () => {
        setDropdown(!dropdown);
        navigate("/Privacy");
    }
    const handleTerms = () => {
        setDropdown(!dropdown);
        navigate("/Terms");
    }
    const handleNews = () => {
        setDropdown(!dropdown);
        navigate("/News");
    }

    if (windowWidth > 600) {  /*                 <div className='headerrightbox2' > */
        return (
            <>
                <header>
                    <Link to="/">
                        <div className='headerleftbox'>

                            <div className='headerlogo' style={{ width: 499, height: 89, marginBottom: 10, marginLeft: 0 }}>
                            </div>
                        </div>
                    </Link>

                    <div className='headerrightbox' >
                        {userData.id ? (
                            <>
                                <Link to="/Login">
                                    <div className='' style={{ display: 'none' }}>
                                        <SignInButton mult={1} />
                                    </div>
                                </Link>
                                <div className="container-row-center" style={{ height: 50 }}>
                                    <Link to="/Portfolio">
                                        <div className='headerportfoliobutton' style={{ display: 'flex' }}>
                                        </div>
                                    </Link>
                                    <Link to="/Reports">
                                        {unreadReports ?
                                            <div className='headerunreadreportsbutton' style={{ display: 'flex' }}>
                                            </div>
                                            :
                                            <div className='headerreportsbutton' style={{ display: 'flex' }}>
                                            </div>
                                        }
                                    </Link>
                                    <Link to="/Account">
                                        <div className='headeraccountbutton' style={{ display: 'flex' }}>
                                        </div>
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <>
                                <Link to="/Login">
                                    <div className='' style={{ marginRight: 30, marginTop: 10, display: 'flex' }}>
                                        <SignInButton mult={1} />
                                    </div>
                                </Link>
                                <div className='headerportfoliobutton' style={{ display: 'none' }}>
                                </div>
                                <div className='headerreportsbutton' style={{ display: 'none' }}>
                                </div>
                                <div className='headeraccountbutton' style={{ display: 'none' }}>
                                </div>
                            </>
                        )}

                        <div className="dropdown">
                            <div className='headerburgermenu' onClick={handleDropdown} style={{ marginTop: 5, width: 70, height: 50 }}>
                            </div>
                            {dropdown &&
                                <div className="dropdown-content" >


                                    <div id="idhow" className="dropdown-item-hover" >
                                        <div className="dropdown-how dropdown-item" onClick={handleHowItWork}>
                                        </div>
                                    </div>
                                    <div id="idabout" className="dropdown-item-hover" >
                                        <div className="dropdown-about dropdown-item" onClick={handleAbout}>
                                        </div>
                                    </div>
                                    <div className="dropdown-2empty">
                                    </div>


                                    <div id="idnews" className="dropdown-item-hover" >
                                        <div className="dropdown-news dropdown-item" onClick={handleNews}>
                                        </div>
                                    </div>
                                    <div id="idhelp" className="dropdown-item-hover" >
                                        <div className="dropdown-help dropdown-item" onClick={handleHelp}>
                                        </div>
                                    </div>
                                    <div id="idcontact" className="dropdown-item-hover" >
                                        <div className="dropdown-contact dropdown-item" onClick={handleContact}>
                                        </div>
                                    </div>
                                    <div className="dropdown-2empty">
                                    </div>
                                    <div id="idpolicy" className="dropdown-item-hover" >
                                        <div className="dropdown-policy dropdown-item" onClick={handlePrivacy}>
                                        </div>
                                    </div>
                                    <div id="idterms" className="dropdown-item-hover" >
                                        <div className="dropdown-terms dropdown-item" onClick={handleTerms}>
                                        </div>
                                    </div>
                                    <div className="dropdown-2empty">
                                    </div>

                                    {userData.id ? (
                                        <div id="idsignout" className="dropdown-item-hover" >
                                            <div className="dropdown-signout dropdown-item" onClick={handleLogout}>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="dropdown-signout dropdown-item dropdown-item-opacity">
                                        </div>
                                    )}

                                </div>
                            }
                        </div>
                    </div>

                </header >
            </>
        );
    } else {
        return (
            <header>
                <div className='headerbar_mob' style={{ height: 116 / mult * 2 }}>
                    <Link to="/">
                        <div className='headerlogo' style={{ width: 460 / mult * 1.8, height: 82 / mult * 1.8, marginBottom: 8 / mult, marginLeft: 30 / mult }}>
                        </div>
                    </Link>

                    {userData.id ? (
                        <>
                            <Link to="/Login">
                                <div className='headersigninbutton' style={{ display: 'none' }}>
                                </div>
                            </Link>
                            <Link to="/Portfolio">
                                <div className='headerportfoliobuttonmob' style={{ width: 170 / mult, height: 160 / (mult), top: 0, display: 'flex' }}>
                                </div>
                            </Link>
                            <Link to="/Reports">
                                {unreadReports ?
                                    <div className='headerunreadreportsbuttonmob' style={{ marginLeft: 10, width: 150 / mult, height: 100 / (mult), top: 0, display: 'flex' }}>
                                    </div>
                                    :
                                    <div className='headerreportsbuttonmob' style={{ marginLeft: 10, width: 150 / mult, height: 100 / (mult), top: 0, display: 'flex' }}>
                                    </div>
                                }
                            </Link>
                            <Link to="/Account">
                                <div className='headeraccountbuttonmob' style={{ marginLeft: 15, width: 90 / mult, height: 140 / (mult), top: 0, display: 'flex' }}>
                                </div>
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to="/Login">

                                <div className='' style={{ position: "absolute", top: 75 / mult, left: 1240 / mult, display: 'flex' }}>
                                    <SignInButton mult={mult * 0.45} />
                                </div>
                            </Link>

                            <div className='headerportfoliobutton' style={{ display: 'none' }}>
                            </div>
                            <div className='headerreportsbutton' style={{ display: 'none' }}>
                            </div>
                            <div className='headeraccountbutton' style={{ display: 'none' }}>
                            </div>
                        </>
                    )}
                    <div className="dropdown">
                        <div className='headerburgermenu' onClick={handleDropdown} style={{ width: 200 / mult, height: 120 / mult, top: 0, left: 11 }}>
                        </div>
                        {dropdown &&
                            <div className="dropdown-content-mob" >
                                <div className="dropdown-how-mob dropdown-item-mob" onClick={handleHowItWork}>
                                </div>
                                <div className="dropdown-about-mob dropdown-item-mob" onClick={handleAbout}>
                                </div>
                                <div className="dropdown-2empty-mob" >
                                </div>
                                <div className="dropdown-news-mob dropdown-item-mob" onClick={handleNews}>
                                </div>
                                <div className="dropdown-help-mob dropdown-item-mob" onClick={handleHelp}>
                                </div>
                                <div className="dropdown-contact-mob dropdown-item-mob" onClick={handleContact}>
                                </div>
                                <div className="dropdown-2empty-mob">
                                </div>
                                <div className="dropdown-policy-mob dropdown-item-mob" onClick={handlePrivacy}>
                                </div>
                                <div className="dropdown-terms-mob dropdown-item-mob" onClick={handleTerms}>
                                </div>
                                <div className="dropdown-2empty-mob">
                                </div>
                                {userData.id ? (
                                    <div className="dropdown-signout-mob dropdown-item-mob" onClick={handleLogout}>
                                    </div>
                                ) : (
                                    <div className="dropdown-signout-mob dropdown-item-mob dropdown-item-opacity">
                                    </div>
                                )}
                            </div>
                        }
                    </div>

                </div>
            </header>

        );
    }


    /*
        return (
                <header>
                        <div className="logo-dropdown">
    
                            <img alt="" className="logoimage" src={ArtLogo} onClick={LogoClick}/>
    
                                <div className="dropdown-content-logo">
                                    <Link to='/'>
                                        <div className="aligned">
                                            <img alt=""  src={AboutUs} />
                                            <div className="gmenutitle gmenutitle_aboutus">
                                                <span>About us</span>
                                                <div className="bar"></div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to='/'>
                                        <div className="aligned" onClick={TermsLink}>
                                        <img alt=""  src={AboutUs} />
                                            <div className="gmenutitle gmenutitle_terms" >
                                                <span >Terms and<br/>Conditions</span>
                                                <div className="bar"></div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to='/'>
                                        <div className="aligned">
                                            <img alt=""  src={AboutUs} />
                                            <div className="gmenutitle gmenutitle_news">
                                                <span>News</span>
                                                <div className="bar"></div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                        </div>
                    {userData.id ? (
                    <div id="headermenudiv" className="headermenu" style={{ display:  'flex'}}>
                        <Headermenu  logoutonclick={handleLogout} aboutclick={AboutButton} reportsclick={ReportsButton} mediaclick={MediaButton} accountclick={AccountButton}/>
                    </div>
                    ) : (
                        <div id="headermenudiv" className="headermenu" style={{ display:  'none'}}>
                        <Headermenu logoutonclick={handleLogout} aboutclick={AboutButton} reportsclick={ReportsButton} mediaclick={MediaButton} accountclick={AccountButton}/>
                    </div>
                    )}
                </header>        
        );
        */
}

export default Header;