import { useState, useEffect } from "react";
import '../../../src/CSS/global.css';
import '../../../src/CSS/bigmsg.css';
import '../../../src/CSS/loginandreg.css';
import EmptyButton from './EmptyButton';
import { W1, W2 } from "../../functions";


function RadioButton({ id, title, buttonstate, buttonhandler, fontsize, fontweight, letterspacing }: any) {

  useEffect(() => {
    const checkRButton = document.getElementById(id);
    if (checkRButton !== null) {
      if (buttonstate) {
        checkRButton.style.opacity = '1';
      } else {
        checkRButton.style.opacity = '0';
      }
    }

  }, [buttonstate]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < W1) { mult = W1 / windowWidth; }
  if (windowWidth < W2) { mult = W1 / W2; }

  return (
    <>
      <div className="radiobutton-outerbox" id={"id3" + id} onClick={(e) => { buttonhandler(e) }} style={{ letterSpacing: letterspacing, marginBottom: 3, fontSize: fontsize / mult, fontWeight: fontweight, cursor: "pointer" }} >
        <div className="radiobutton-box" id={"id2" + id} onClick={(e) => { buttonhandler(e) }} style={{ width: 25 / mult, height: 25 / mult, borderRadius: 20 / mult }} >
          <div className="radiobutton-mark" id={id} onClick={(e) => { buttonhandler(e) }} style={{ width: 10 / mult, height: 10 / mult, borderRadius: 10 / mult }}  >
          </div>
        </div>
        {title}&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </>
  );
}
function RadioButtonBlock({ buttonsarray, layout, fontsize = 20, fontweight = 400, letterspacing = 1 }: any) {
  if (buttonsarray === null) { return (<></>); }
  console.log(buttonsarray);
  return (
    <>
      <div className="radiobutton-block" style={{ flexDirection: layout }} >
        {buttonsarray.map((button: any) => {
          return (
            <RadioButton
              key={button.id}
              id={button.id}
              title={button.title}
              buttonstate={button.buttonstate}
              buttonhandler={button.buttonhandler}
              fontsize={fontsize}
              fontweight={fontweight}
            />
          );
        })}
      </div>

    </>
  );
}

export default RadioButtonBlock;