import React from "react";

function TrashFull({ mult }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38 / mult}
            height={57 / mult}
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 5.413 8.768"
        >
            <path
                fill="#a6abb9"
                fillOpacity="1"
                strokeWidth="0.469"
                d="M2.662 0L.672 1.921l.744.771c.244.06.514.099.793.118l-.995-1.03L2.673.37 2.68.368l1.75 1.813-.496.48c.07-.02.136-.042.2-.065.342-.125.545-.28.608-.442zM1.216.183l-.198 1.251.33-.318.103-.65.49.078.218-.212zm2.16.342l.28.29.284.045-.034.214.253.262.107-.67zM2.695.95a.208.208 0 10.005 0h-.005zm1.917.645c.105.102.169.21.19.32.29.287.118.622-.517.853-.872.316-2.285.316-3.157 0-.622-.226-.8-.552-.535-.836a.602.602 0 01.184-.327C-.264 2.029-.26 2.71.793 3.132c1.057.423 2.77.423 3.827 0 1.057-.424 1.057-1.11 0-1.534-.003 0-.005-.002-.008-.003zm-2.067.07l-.68.334.887.887c.101-.005.202-.013.301-.022l.08-.189.275-.522-.44.014-.046-.34-.402.197zM.88 2.31l-.028.177a2.055 2.055 0 00.327.132zM0 2.62l.002.025a.33.33 0 01.002-.025zm5.412 0v.004l.001-.004zm-5.401.134l.416 5.177h.009c.011.212.233.424.665.586.887.334 2.325.334 3.212 0 .489-.184.708-.43.658-.671L5.4 2.78c-.063.238-.322.47-.778.652-1.057.424-2.77.424-3.828 0-.471-.189-.732-.43-.783-.677z"
                display="inline"
            ></path>
        </svg>
    );
}

export default TrashFull;