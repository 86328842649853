import { useState, useEffect } from "react";
import '../../../src/CSS/global.css';
import '../../../src/CSS/bigmsg.css';
import '../../../src/CSS/loginandreg.css';
import EmptyButton from './EmptyButton';

function CheckBox({ id, title, buttonstate, buttonhandler, avail, bigfix }: any) {


  useEffect(() => {
    const checkBoxMark = document.getElementById(id);
    if (checkBoxMark !== null) {
      if (buttonstate) {
        checkBoxMark.style.opacity = '1';
      } else {
        checkBoxMark.style.opacity = '0';
      }
    }

  }, [buttonstate]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1; //1920/windowWidth;
  if (windowWidth < 600) { mult = 1.7; }
  if (bigfix) { mult = 1; }
  return (
    <>
      <div className="radiobutton-outerbox" style={{ fontSize: 20 / mult }} >
        {avail ?
          <div className="checkbox-box" style={{ width: 25 / mult, height: 25 / mult, borderRadius: 7 / mult, marginBottom: 7 }} >
            <div className="checkbox-mark" id={id} style={{ width: 25 / mult, height: 25 / mult, borderRadius: 7 / mult }} onClick={(e) => { buttonhandler(e) }}>
            </div>
          </div>
          :
          <div className="checkbox-box" style={{ width: 25 / mult, height: 25 / mult, borderRadius: 7 / mult, marginBottom: 7, background: '#cccddd', cursor: 'pointer' }} >

          </div>
        }
        {title}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
    </>
  );
}
//          <div className="radiobutton-outerbox" style={{width: 26/mult,height: 26/mult, fontSize: 20/mult}} >
function CheckBoxBlock({ buttonsarray, layout, bigfix = false }: any) {

  return (
    <>
      <div className="checkbox-block" style={{ flexDirection: layout }} >
        {buttonsarray.map((button: any) => {
          let avail = true;
          if (button.avail !== null && button.avail === false) { avail = false; }
          return (
            <CheckBox
              key={button.id}
              id={button.id}
              title={button.title}
              buttonstate={button.buttonstate}
              buttonhandler={button.buttonhandler}
              avail={avail}
              bigfix={bigfix}
            />
          );
        })}
      </div>

    </>
  );
}

export default CheckBoxBlock;