import { useState, useEffect } from "react";
import { useSendNewPasswordMutation } from "../../Apis/authApi";
import { apiResponse, userModel } from "../../Interfaces";
import jwt_decode from "jwt-decode";
import { useNavigate, useSearchParams } from "react-router-dom";
import BigMsg from "../Misc/BigMsg";

function EnterNewPassword() {

    const [searchParams] = useSearchParams();
    const email = searchParams.get("email");
    let token = searchParams.get("token");
    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [sendNewPassword] = useSendNewPasswordMutation();

    const navigate = useNavigate();


    const ToPasswordChangedMsg = async () => {
        if (Password !== ConfirmPassword) {
            setError("Error: Passwords do not match!");
        } else {

            if (token !== null) { token = token.replace(/\s/g, '+'); }
            const tempobject = {
                "password": Password,
                "confirm_password": ConfirmPassword,
                "email": email,
                "token": token
            }
            console.log(tempobject);
            const response: apiResponse = await sendNewPassword(tempobject);

            if (response.data) {
                console.log(response);
                console.log("response.data.message");
                console.log(response.data.message);

                if (response.data.message == "resetpasserrors") {
                    setError("Error: Password change  failed  . Please contact our support");
                }
                if (response.data.message == "nouser") {
                    setError("Error: Password change  failed. Please try again.");
                }
                if (response.data.message == "passwordchanged") {
                    console.log("here");
                    navigate("/PasswordChanged");
                }
            } else if (response.error) {
                setError("Error: " + response.error.data.title);
            }
        }
    }


    const title = "Password Reset";
    const subtitle = "Please enter your new password";
    const message = "";
    const inputs = [
        { "toptext": "New Password", "type": "password", "id": "passwordfp1", "value": Password, "handler": (e: any) => setPassword(e.target.value) },
        { "toptext": "Confirm New Password", "type": "password", "id": "passwordfp2", "value": ConfirmPassword, "handler": (e: any) => setConfirmPassword(e.target.value) },
        { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
    ]
    const buttons = [
        { "handler": ToPasswordChangedMsg, "title": "Submit" },
        { "handler": () => true, "title": "" },
        { "handler": () => true, "title": "" },
    ]


    return (
        <BigMsg title={title} subtitle={subtitle} message={message} error={error} inputs={inputs} buttons={buttons} />
    );

}

export default EnterNewPassword;