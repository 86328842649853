import React from "react";

function ButtonTemplateArt({ mult, id, children, minibuttons = false }) {
    let t = -40;
    if (minibuttons) { t = -50; }
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={300 / mult}
                height={55 / mult}
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 41.598 7.539"
            >
                <g
                    display="inline"
                    transform="matrix(2.01587 0 0 2.01587 -85.04 -42.444)"
                >
                    <path
                        fill="#698ff9"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="square"
                        strokeLinejoin="bevel"
                        strokeOpacity="0"
                        strokeWidth="0"
                        d="M43.724 21.717h15.482c-.036.027-.072.06-.102.086-.682.649-.485 1.539.065 2.198.376.37.706.594 1.149.794H43.724l-.077-.002c-.846-.06-1.41-.715-1.462-1.537.019-.848.643-1.445 1.462-1.537l.077-.002"
                    ></path>
                    <g>
                        <path
                            fill="#e6e6e6"
                            fillOpacity="1"
                            strokeWidth="0.194"
                            d="M62.779 21.055c-.05 0-.14.05-.267.157a4.984 4.984 0 00-.937 1.119c-.204.355-.115.43.198.167.314-.263.734-.764.938-1.119.121-.21.139-.323.068-.324zm-2.422.562c-.14-.002-.277.01-.41.035-.944.18-1.328 1.005-.86 1.844.47.84 1.613 1.374 2.556 1.194.173-.033.458-.178.458-.178a.236.236 0 00-.042-.43v-.003a.313.313 0 01.15-.607v-.004a.312.312 0 00.38-.43l.005-.003a1.74 1.74 0 00-.092-.19 2.104 2.104 0 00-.344-.452c-.15.166-.304.32-.452.449a.34.34 0 01-.317.361 6.378 6.378 0 01-.089.095c-.34.355-.305.234-.443.286-.138.051-.174.218-.183.203-.01-.015-.143-.297.075-.77.067-.144.127-.221.18-.259a.341.341 0 01.324-.319c.094-.154.207-.318.33-.48a2.629 2.629 0 00-1.226-.342zm.15.241c.13.01.252.092.303.218a.3.3 0 01-.188.404.355.355 0 01-.433-.202.3.3 0 01.188-.404.338.338 0 01.13-.016zm-.772.347c.13.01.252.092.302.218a.3.3 0 01-.188.404.354.354 0 01-.432-.202.3.3 0 01.188-.404.338.338 0 01.13-.016zm1.528.436a.134.134 0 00-.087.237l.108.091c.057.048.141.04.189-.016a.135.135 0 00-.017-.19l-.108-.09a.133.133 0 00-.085-.032zm-.24.307c-.031.003-.073.032-.12.136-.103.222-.082.354-.077.36.004.008.032-.069.096-.093.065-.024.079.032.239-.135.16-.167.1-.13.052-.141-.05-.011-.054-.01-.054-.01l-.082-.086s-.02-.033-.055-.03zm-1.235.042c.13.01.251.092.302.218a.3.3 0 01-.188.404.355.355 0 01-.433-.202.3.3 0 01.188-.404.338.338 0 01.13-.016zm1.847 1.123a.182.182 0 01.155.11.154.154 0 01-.097.208.182.182 0 01-.221-.103c-.003-.002-.004-.004-.004-.007a.149.149 0 01-.01-.063.158.158 0 01.11-.138.177.177 0 01.067-.007z"
                        ></path>
                        <path
                            fill="#567ffa"
                            stroke="#000"
                            strokeLinecap="square"
                            strokeLinejoin="bevel"
                            strokeOpacity="0"
                            strokeWidth="0"
                            d="M59.952 23.253a.167.167 0 01-.105.224.197.197 0 01-.24-.112.167.167 0 01.105-.224.197.197 0 01.24.112"
                        ></path>
                        <path
                            fill="#fe3521"
                            stroke="#000"
                            strokeLinecap="square"
                            strokeLinejoin="bevel"
                            strokeOpacity="0"
                            strokeWidth="0"
                            d="M59.9 22.468a.167.167 0 01-.105.224.197.197 0 01-.24-.112.167.167 0 01.104-.224.197.197 0 01.24.112"
                        ></path>
                        <path
                            fill="#ff0"
                            stroke="#000"
                            strokeLinecap="square"
                            strokeLinejoin="bevel"
                            strokeOpacity="0"
                            strokeWidth="0"
                            d="M60.68 22.113a.167.167 0 01-.104.225.197.197 0 01-.24-.112.167.167 0 01.104-.225.197.197 0 01.24.112"
                        ></path>
                    </g>
                </g>
            </svg>
            <span id={"span" + id} style={{ height: 0, zIndex: 300, position: "relative", top: t / mult, color: 'white' }} >{children}</span>
        </>
    );
}

export default ButtonTemplateArt;
