import React, { useState, useEffect } from "react";
import { useContactUsMutation } from "../Apis/authApi";
import { inputHelper } from "../components/helper";
import { apiResponse, userModel } from "../Interfaces";
import '../CSS/global.css';
import '../CSS/loginandreg.css';
import EmptyButton from '../components/Misc/EmptyButton';
import MiniLoader from "../components/Misc/MiniLoader";
import { W1, W2 } from "../functions";
import { RootState } from "../storage/redux/store";
import { useSelector } from "react-redux";

function Contact() {
  const [contactUs] = useContactUsMutation();
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [thankYou, setThankYou] = useState(" ");
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  const [contactInput, setContactInput] = useState({
    name: "",
    email: "",
    subject: "",
    text: ""
  });
  const handleContactInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const tempData = inputHelper(e, contactInput);
    setContactInput(tempData);
    setError("");
    setThankYou(" ");
  };

  useEffect(() => {
    setContactInput({
      name: "",
      email: userData.email,
      subject: "",
      text: ""
    });
  }, [userData.email]);


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setThankYou(" ");
    console.log("contactInput.email");
    console.log(contactInput.email);
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(contactInput.email))) {
      setError("Error: You have entered an invalid email address!");
    } else if (contactInput.name === "") {
      setError("Error: Name field is empty");
    } else if (contactInput.subject === "") {
      setError("Error: Subject field is empty");
    } else if (contactInput.text === "") {
      setError("Error: Message field is empty");
    } else if (contactInput.email === "") {
      setError("Error: Email field is empty");
    } else if (contactInput.subject.length > 100) {
      setError("Error:  Subject should be  less than  100 characters");
    } else if (contactInput.name.length > 100) {
      setError("Error:  Name should be  less 100 characters");
    } else if (contactInput.text.length > 600) {
      setError("Error:  Message should be less than 600 characters");
    } else {
      setIsLoading(true);
      console.log(contactInput);
      const response: apiResponse = await contactUs({
        name: contactInput.name,
        email: contactInput.email,
        subject: contactInput.subject,
        text: contactInput.text
      });
      setIsLoading(false);
      if (response.data) {
        setThankYou("Message has been sent. Thank you!");
        setContactInput({
          name: "",
          email: userData.email,
          subject: "",
          text: ""
        });
      } else if (response.error) {
        setError("Error: " + response.error.data);
      }

    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth > 600) {
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (
      <>
        <div className="maincontainer2 colorcccccc">
          <div style={{ marginTop: 90, width: 1337 / mult, height: 600 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
            <div className="createtrial-box " style={{ width: 1240 / mult, height: 560 / mult }}>
              <div className="createtrial-title loginfont" style={{ width: 1100 / mult, height: 50 / mult, fontSize: 17 / mult, fontWeight: 500 / mult, marginBottom: 40 }}>
                <span className='createtrial-bigtitle colord9d9d9' style={{ fontSize: 40 / mult }}>Contact Us </span><br />
                <br />
                <span className="login-errormessage">{error}&nbsp;&nbsp;</span>
              </div>

              <div style={{ width: 1000 / mult, display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 0 }}>
                <div style={{ width: '50%' }}>
                  <div className="createtrial-input" style={{ width: 400 / mult, height: 70 / mult, marginBottom: 15 / mult }}>
                    <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Name</span>
                    <input
                      type="text"
                      id="contactus-name"
                      name="name"
                      value={contactInput.name}
                      onChange={handleContactInput}
                      style={{ outline: 'none', width: 400 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult, borderRadius: 9 / mult }}
                      required />
                  </div>
                  <div className="createtrial-input" style={{ width: 400 / mult, height: 70 / mult, marginBottom: 15 / mult }}>
                    <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Email</span>
                    {(userData.id !== "") ?
                      <span style={{ backgroundColor: 'white', color: '#888888', outline: 'none', width: 408 / mult, height: 36 / mult, fontSize: 20 / mult, fontWeight: 300 / mult, borderRadius: 8 / mult }}>
                        &nbsp;{contactInput.email}
                      </span>
                      :
                      <input
                        type="email"
                        id="contactus-email"
                        name="email"
                        value={contactInput.email}
                        onChange={handleContactInput}
                        style={{ outline: 'none', width: 400 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult, borderRadius: 9 / mult }}
                        required />
                    }
                  </div>
                  <div className="createtrial-input" style={{ width: 400 / mult, height: 70 / mult, marginBottom: 15 / mult }}>
                    <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Subject</span>
                    <input
                      type="text"
                      id="contactus-subject"
                      name="subject"
                      value={contactInput.subject}
                      onChange={handleContactInput}
                      style={{ outline: 'none', width: 400 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult, borderRadius: 9 / mult }}
                      required />
                  </div>
                </div>
                <div style={{ width: '50%' }}>

                  <div className="createtrial-input" style={{ width: 400 / mult, height: 240 / mult, marginBottom: 20 / mult }}>
                    <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Message</span>
                    <textarea
                      id="contactus-text"
                      name="text"
                      value={contactInput.text}
                      onChange={handleContactInput}
                      style={{ resize: 'none', outline: 'none', width: 400 / mult, height: 200 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                      required />
                  </div>

                </div>
              </div>
              <span className="thankyoumessage">{thankYou}</span>
              {loading ? (<MiniLoader />) :
                (
                  <EmptyButton nav={handleSubmit} title={"Submit"} id={""} />
                )
              }
            </div>
          </div>
        </div >

      </>
    );
  } else {
    // Mobile *************************************************************

    mult = 600 / windowWidth;
    return (
      <>
        <div className="maincontainer2 colorcccccc">
          <div className="loginbox-mob" style={{ width: '100%', height: '100%' }}>
            <div className="login-title loginfont" style={{ textAlign: 'center', width: '100%', height: 125 / mult, fontSize: 17 / mult, fontWeight: 500 / mult }}>
              <span className='createtrial-bigtitle colord9d9d9' style={{ fontSize: 40 / mult }}>Contact Us </span><br />
              <br />
              <span className="login-errormessage">{error}&nbsp;&nbsp;</span>
            </div>

            <div className="createtrial-input" style={{ width: 350 / mult, height: 70 / mult, marginBottom: 15 / mult }}>
              <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Name</span>
              <input
                type="text"
                id="contactus-name"
                name="name"
                value={contactInput.name}
                onChange={handleContactInput}
                style={{ outline: 'none', width: 350 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult, borderRadius: 9 / mult }}
                required />
            </div>
            <div className="createtrial-input" style={{ width: 350 / mult, height: 70 / mult, marginBottom: 15 / mult }}>
              <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Email</span>
              {(userData.id !== "") ?
                <span style={{ backgroundColor: 'white', color: '#888888', outline: 'none', width: 360 / mult, height: 36 / mult, fontSize: 20 / mult, fontWeight: 300 / mult, borderRadius: 8 / mult }}>
                  &nbsp;{contactInput.email}
                </span>
                :
                <input
                  type="email"
                  id="contactus-email"
                  name="email"
                  value={contactInput.email}
                  onChange={handleContactInput}
                  style={{ outline: 'none', width: 350 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult, borderRadius: 9 / mult }}
                  required />
              }

            </div>

            <div className="createtrial-input" style={{ width: 350 / mult, height: 70 / mult, marginBottom: 15 / mult }}>
              <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Subject</span>
              <input
                type="text"
                id="contactus-subject"
                name="subject"
                value={contactInput.subject}
                onChange={handleContactInput}
                style={{ outline: 'none', width: 350 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult, borderRadius: 9 / mult }}
                required />
            </div>


            <div className="createtrial-input" style={{ width: 350 / mult, height: 240 / mult, marginBottom: 60 / mult }}>
              <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Message</span>
              <textarea
                id="contactus-text"
                name="text"
                value={contactInput.text}
                onChange={handleContactInput}
                style={{ resize: 'none', outline: 'none', width: 350 / mult, height: 200 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                required />
            </div>
            <span className="thankyoumessage">{thankYou}</span>
            {loading ? (<MiniLoader />) :
              (
                <EmptyButton nav={handleSubmit} title={"Submit"} id={""} />
              )
            }
          </div>
        </div >

      </>
    );
  }
}

export default Contact;