import '../../CSS/global.css';
import React, { useState, useEffect } from "react";

function ContinueButton({ nav }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < 1240) { mult = 1240 / windowWidth; }
  return (
    <div className='continuebutton' style={{ height: 70 / mult, width: 380 / mult }} onClick={nav}>
    </div>
  );

}

export default ContinueButton; 
