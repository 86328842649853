import { useNavigate } from "react-router-dom";
import '../../../src/CSS/global.css';
import '../../../src/CSS/loginandreg.css';
import BigMsg from "../Misc/BigMsg";

function PasswordChanged() {

  const navigate = useNavigate();
  const RedirectToLogin = () => {
    navigate("/Login");;
  }
  const title = "Password Reset";
  const subtitle = "";
  const message = "You have successfully changed your password.";
  const error = "";
  const inputs = [
    { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
    { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
    { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
  ]
  const buttons = [
    { "handler": RedirectToLogin, "title": "Continue" },
    { "handler": () => true, "title": "" },
    { "handler": () => true, "title": "" },
  ]

  return (
    <BigMsg title={title} subtitle={subtitle} message={message} error={error} inputs={inputs} buttons={buttons} />
  );

}

export default PasswordChanged;