import React, { useState } from "react";
import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import EmptyButton from './EmptyButton';
import { useNavigate } from 'react-router-dom';
//    <div className="maincontainer2 " style={{width: 800/mult,height: 390/mult,marginBottom: '80px', zIndex: 100, top: 230 / mult, left: 600 / mult }}>
function SimpleMsg1({ target, text, title, mult, mobile = false }: any) {
  let w = 700;
  let f = 20;
  if (mobile) {
    w = 450;
    f = 30;
  }
  if (mobile) {
    return (
      <>
        <div className="maincontainer2 " style={{ zIndex: 200 }}>
          <div className="mainbox" style={{ position: 'absolute', width: '100vw', height: '100vh', textAlign: "center" }}>
            <span className="colord9d9d9 midfont" style={{ width: w / mult, fontSize: f / mult, marginBottom: 60 / mult }}>{text}</span>
            <EmptyButton nav={target} title={title} id={""} />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="maincontainer2 " style={{ zIndex: 200 }}>
          <div className="mainbox" style={{ width: 900 / mult, height: 390 / mult, textAlign: "center" }}>
            <span className="colord9d9d9 midfont" style={{ width: w / mult, fontSize: f / mult, marginBottom: 60 / mult }}>{text}</span>
            <EmptyButton nav={target} title={title} id={""} />
          </div>
        </div>
      </>
    );
  }

}

export default SimpleMsg1; 