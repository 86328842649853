import '../../../src/CSS/global.css';
import '../../../src/CSS/trash.css';
import { useState, useEffect, useRef } from 'react';
import {
  useGetAllImagesInfoQuery,
  useGetImageDataQuery,
  useRestoreFromTrashMutation,
  useDeleteFromTrashMutation,
  useDeleteAllFromTrashMutation,
  useGetUsedQuotaQuery,
} from "../../Apis/imageApi";
import { useGetSubscriptionDetailsQuery } from "../../Apis/authApi";
import { userModel } from "../../Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../storage/redux/store";
import { useScrollbar } from '../../Hooks/use-scrollbar-y';
import { useNavigate } from "react-router-dom";
import { withAuth } from '../../HOC';
import SimpleMsg1 from "../Misc/SimpleMsg1";
import { setSortMenuState } from "../../storage/redux/slice/varSlice";
import SortMenu from "../Misc/SortMenu";
import { W1, W2 } from "../../functions";
import EmptyButton from '../Misc/EmptyButton';
/***************** Trash components ****************************/
const EmptyTrash = ({ buttonempty, buttoncancel, mobile = false }: any) => {


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < 1100) { mult = 1100 / windowWidth; }
  let mult1 = 2500 / windowWidth;
  if (mobile) {
    mult = 1.75;
    return (
      <div className="mainbox" style={{ position: 'absolute', width: '100vw', height: '100vh', textAlign: "center", zIndex: 200 }}>
        <div className="t-emptytraahdbox-title colord9d9d9" style={{ width: 798 / mult, height: 122 / mult, fontSize: 25 / mult + 'px' }}>
          Empty all trash?
        </div>
        <div className="t-emptytraahdbox-text colord9d9d9" style={{ width: 798 / mult, height: 108 / mult, fontSize: 25 / mult + 'px' }}>

          <div>
            All items in My Trash
          </div>
          <div>
            will be permanently deleted from this account.
          </div>
          <div>
            Are you sure?
          </div>

        </div>
        <div className="t-emptytraahdbox-buttons" style={{ marginTop: 40, flexDirection: 'column', width: 798 / mult, height: 160 / mult }}>
          <EmptyButton nav={buttoncancel} classname={"emptybuttongray"} title={"Cancel"} id={""} flex={true} />
          <div style={{ width: 30 / mult, height: 50 / mult }}>
          </div>
          <EmptyButton nav={buttonempty} classname={"emptybuttonred"} title={"Empty trash"} id={""} flex={true} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="t-emptytrashbox" style={{ width: 850 / mult, height: 391 / mult, top: 180, left: 510 / (mult1 - 0.4) }}>
        <div className="t-emptytraahdbox-title colord9d9d9" style={{ width: 798 / mult, height: 122 / mult, fontSize: 25 / mult + 'px' }}>
          Empty all trash?
        </div>
        <div className="t-emptytraahdbox-text colord9d9d9" style={{ width: 798 / mult, height: 108 / mult, fontSize: 15 / mult + 'px' }}>

          <div>
            All items in My Trash will be permanently deleted from this account.
          </div>
          <div>
            Are you sure?
          </div>

        </div>
        <div className="t-emptytraahdbox-buttons" style={{ width: 798 / mult, height: 160 / mult }}>
          <EmptyButton nav={buttoncancel} classname={"emptybuttongray"} title={"Cancel"} id={""} flex={true} />
          &nbsp;&nbsp;&nbsp;
          <EmptyButton nav={buttonempty} classname={"emptybuttonred"} title={"Empty trash"} id={""} flex={true} />
        </div>
      </div>
    );
  }
}

const SmallMedia = ({ id, userid, uuid4name, restorefromtrash, deletefromtrash, m, mult, mobile = false }: any) => {
  //const data64 = BytesTo64(data);
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  let w = 30;
  let l = 85;
  let t = -98;
  if (mobile) { w = 60; l = 45; t = -113; }

  const sorr = "s";
  const { data, isLoading, isSuccess, isError, error } = useGetImageDataQuery({ userid, sorr, uuid4name });
  if (data) {

    return (
      <>
        <div className="t-smallmediadiv1" style={{ width: 240 / mult, height: 170 / mult, marginRight: 10 / mult, marginBottom: 10 }}>
          <div className="t-smallmediadiv" style={{ width: 240 / mult, height: 170 / mult, marginRight: 10 / mult }}>
            <div style={{ background: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
              <img className="" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} id={id} src={`data:image/jpeg;base64,${data}`}
                style={{ maxWidth: 240 / mult, maxHeight: 170 / mult, opacity: isHover ? 0.7 : 1 }} />
            </div>
          </div>
          <div className="t-image-battons" style={{ top: t / mult, left: l / mult, display: isHover ? 'flex' : 'none' }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
            <div className="t-image-delete" id={id} onClick={deletefromtrash} title="Delete forever" style={{ width: w / mult, height: w / mult }}></div>&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="t-image-restore" id={id} onClick={restorefromtrash} title="Restore" style={{ width: w / mult, height: w / mult }}></div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="smallmediadiv" style={{ width: 140 / mult, height: 90 / mult }}>
    </div>
  );
}

const SmallMediaList = ({ mediadata, userid, restorefromtrash, deletefromtrash, mult, mobile }: any) => {
  if (mediadata === undefined) {
    return (<></>);
  }
  if (mobile) {
    return (
      <div style={{ width: 490 / mult, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        {mediadata.map((imagedata: any) => {
          return (
            <SmallMedia
              id={imagedata.search_item_id}
              //userid={userid}
              userid={imagedata.application_user_id}
              imagedata
              uuid4name={imagedata.uuid4name}
              restorefromtrash={restorefromtrash}
              deletefromtrash={deletefromtrash}
              mult={2}
              mobile={mobile}
            />
          );
        })}
      </div>
    );
  } else {
    return (
      <div className="t-smallmedialist" style={{ height: 430 / mult }}>
        {mediadata.map((imagedata: any) => {
          return (
            <SmallMedia
              id={imagedata.search_item_id}
              //userid={userid}
              userid={imagedata.application_user_id}
              imagedata
              uuid4name={imagedata.uuid4name}
              restorefromtrash={restorefromtrash}
              deletefromtrash={deletefromtrash}
              mult={mult}
            />
          );
        })}
      </div>
    );
  }
}
/***************** END of Portifilio components ****************************/
function Trash() {
  const [showEmptyTrashMsg, setShowEmptyTrashMsg] = useState(false);
  const [restoreFromTrash] = useRestoreFromTrashMutation();
  const [deleteFromTrash] = useDeleteFromTrashMutation();
  const [deleteAllFromTrash] = useDeleteAllFromTrashMutation();
  const [mediaquota, setMediaQuota] = useState(0);
  const [usedmediaquota, setUsedMediaQuota] = useState(0);
  const [showNoQuotahMsg, setShowNoQuotahMsg] = useState(false);
  const [showSortButton, setShowSortButton] = useState(false);
  const [allDark, setAllDark] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  const varState: any = useSelector(
    (state: RootState) => state.varStore
  );

  const usedquota: any = useGetUsedQuotaQuery(userData.id);
  useEffect(() => {
    if (usedquota.data !== undefined) {
      setUsedMediaQuota(usedquota.data);
    }
  }, [usedquota.data]);


  const usersubscription: any = useGetSubscriptionDetailsQuery(userData.id);
  useEffect(() => {
    if (usersubscription.data !== undefined) {
      setMediaQuota(usersubscription.data.SearchMediaQuota);
    }
  }, [usersubscription.data]);

  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /**************** Scroll bar  things*********************************/
  const trashWrapper = useRef(null);
  const hasScroll = true;

  useScrollbar(trashWrapper, hasScroll, windowWidth);

  /****************Get Initial image info *********************/
  //const {data, isLoading, isSuccess, isError, error} = useGetAllImagesInfoQuery(userData.id);

  const userid = userData.id;
  const userasid = userData.actas_id;
  let sort = varState.sortMenuState;
  const { data } = useGetAllImagesInfoQuery({ userid, userasid, sort });
  const searchimagesintrash = data?.intrash;

  /*******Button Handlers***************/
  const ClickCancel = () => {
    setShowEmptyTrashMsg(false)
    setAllDark(false);
  }
  const ClickSort = () => {
    setShowSortButton(!showSortButton);
  }
  //const ShowEmptyTrashMsg = () => {
  //  setShowEmptyTrashMsg(true)
  //} 
  const ClickRestoreFromTrash = (event: any) => {
    if (event.detail === 1) {
      if (usedmediaquota < mediaquota) {
        console.log(event.target.id);
        const temp = event.target.id;
        const response = restoreFromTrash({
          search_item_id: temp
        });
      } else {
        setShowNoQuotahMsg(true);
        setAllDark(true);
      }
    }
  }
  const ClickCancelRestore = (event: any) => {
    setShowNoQuotahMsg(false);
    setAllDark(false);
  }
  const ClickDeleteFromTrash = (event: any) => {
    if (event.detail === 1) {
      const response = deleteFromTrash({
        search_item_id: event.target.id
      });
    }
  }
  const ClickEmptyTrash = (event: any) => {
    if (event.detail === 1) {
      const response = deleteAllFromTrash({
        user_id: userData.id,
        as_user_id: userData.actas_id,
      });
      setShowEmptyTrashMsg(false);
      setAllDark(false);
    }
  }
  /******* END OF Button Handlers***************/
  let mult = 1;
  if (windowWidth > 600) {
    //if (windowWidth < 1340) { mult = 1340 / windowWidth; }
    //if (windowWidth < 1200) { mult = 1337 / 1200; }
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (
      <>
        {showEmptyTrashMsg && <EmptyTrash buttonempty={ClickEmptyTrash} buttoncancel={ClickCancel} mult={mult} windowWidth={windowWidth} />
        }
        {showNoQuotahMsg && <SimpleMsg1 target={ClickCancelRestore} text={"All quota is used"} title={"Continue"} mult={mult} />
        }
        <div className="maincontainer2">
          <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
            <div className="p-mainbox" style={{ width: 1325 / mult, height: 695 / mult }}>
              <div className="p-mainbox-top" style={{ width: 1250 / mult, height: 75 / mult }}>
                <span className="p-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px' }}>My Trash</span>
                <div className="t-mainbox-emptytrash-image" onClick={() => { setShowEmptyTrashMsg(true); setAllDark(true); }}
                  style={{ width: 200 / mult, height: 49 / mult, marginBottom: 10 / mult, marginRight: 20 / mult }}>
                </div>
              </div>
              <div className="p-mainbox-sort" style={{ width: 1283 / mult, height: 82 / mult }}>
                <div className="p-mainbox-sort-left" style={{ height: 50 / mult }}>
                  <SortMenu />
                </div>
              </div>

              <div className="p-mainbox-media" ref={trashWrapper} style={{ width: 1083 / mult, height: 440 / mult }}>
                <SmallMediaList mediadata={searchimagesintrash} userid={userData.id} restorefromtrash={ClickRestoreFromTrash} deletefromtrash={ClickDeleteFromTrash} mult={mult} mobile={false} />
              </div>

              <div className="p-mainbox-scroll" style={{ width: 1283 / mult, height: 33 / mult }}>
              </div>
              <div className="p-mainbox-bottom" style={{ width: 1283 / mult, height: (82 + 48) / mult }}>
              </div>
            </div>
          </div>
        </div>
        {allDark &&
          <div id="darkdiv" style={{ width: 4000, height: 2500, backgroundColor: 'black', position: 'absolute', opacity: 0.5, top: -200, zIndex: 50 }}></div>
        }
      </>
    );
  } else {
    // Mobile *************************************************************

    mult = 600 / windowWidth;
    const mult1 = 3;
    return (
      <>
        {showEmptyTrashMsg && <EmptyTrash buttonempty={ClickEmptyTrash} buttoncancel={ClickCancel} mult={mult} windowWidth={windowWidth} mobile={true} />
        }
        {showNoQuotahMsg && <SimpleMsg1 target={ClickCancelRestore} text={"All quota is used"} title={"Continue"} mult={mult} mobile={true} />
        }
        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>My Trash</span>
            <div className="t-mainbox-emptytrash-image" onClick={() => { setShowEmptyTrashMsg(true); setAllDark(true); }}
              style={{ width: 150 / mult, height: 36 / mult, marginBottom: 13 / mult, marginRight: 20 / mult }}>
            </div>
          </div>

          <div className="main_div_mob" style={{ width: 550 / mult }}>
            <div className="p-mainbox-sort" style={{ width: 550 / mult, height: 40, marginTop: 5 }}>
              <div style={{ height: 45 / mult, display: 'flex', alignItems: 'center' }}>
                <div className="p-mainbox-sort-left" style={{ height: 45, marginTop: 12 }}>
                  <SortMenu mobile={true} />
                </div>
              </div>
            </div>

            <div>
              <SmallMediaList mediadata={searchimagesintrash} userid={userData.id} restorefromtrash={ClickRestoreFromTrash} deletefromtrash={ClickDeleteFromTrash} mult={mult} mobile={true} />
            </div>
          </div>
        </div >
      </>
    );

  }

}

export default withAuth(Trash);