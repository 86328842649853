import React from "react";

function TrashEmpty({ mult }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38 / mult}
            height={57 / mult}
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 6.095 8.432"
        >
            <path
                fill="#a6abb9"
                fillOpacity="1"
                strokeWidth="0.469"
                d="M3.048 0c-.78 0-1.56.12-2.155.358-1.19.477-1.19 1.25 0 1.726 1.19.477 3.12.477 4.31 0 1.19-.476 1.19-1.25 0-1.726C4.608.119 3.828 0 3.048 0zm0 .116c.643 0 1.287.089 1.777.267.982.357.982.935 0 1.292-.981.356-2.573.356-3.555 0C.288 1.318.288.74 1.27.383 1.76.205 2.404.116 3.048.116zM0 1.51l.002.027.002-.027zm6.095 0v.004-.004zm-6.083.15l.469 5.83h.01c.012.24.262.477.748.66 1 .376 2.618.376 3.617 0 .551-.207.798-.485.74-.756l.484-5.706c-.07.268-.362.53-.876.735-1.19.477-3.12.477-4.31 0C.364 2.21.07 1.939.012 1.66z"
                display="inline"
            ></path>
        </svg>
    );
}

export default TrashEmpty;