import React, { useState, useEffect } from "react";
import '../../CSS/global.css';


function SmallButton({ nav, title, mult, type = "regular" }: any) {
  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult1;
  mult1 = 1920 / windowWidth;


  if (type == "promo") {
    return (
      <div className='smallbutton' onClick={nav} style={{ width: 186 / mult, height: 38 / mult, fontSize: 15 / mult + 'px', marginTop: 12 / mult + 'px' }}>
        <span className='emptybuttonspan'>{title}</span>
      </div>
    );
  }
  else {
    return (
      <div className='smallbutton' onClick={nav} style={{ width: 170 / mult1, height: 35 / mult1, fontSize: 15 / mult1 + 'px', margin: 6 / mult1 + 'px' }}>
        <span className='emptybuttonspan'>{title}</span>
      </div>
    );
  }


}

export default SmallButton; 