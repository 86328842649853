import { useState, useEffect } from 'react';
import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import ContinueButton from '../Misc/ContinueButton';
import { useNavigate } from 'react-router-dom';
import { RootState } from "../../storage/redux/store";
import { accountModel, userModel } from "../../Interfaces";
import { useSelector } from "react-redux";
import { useActivateNewSubscriptionMutation } from "../../Apis/authApi";
import { withAuth } from '../../HOC';
import EmptyButton from "../Misc/EmptyButton";
import { W0, W1, W2 } from "../../functions";

function Completion() {
  const navigate = useNavigate();

  const [activateNewSubscription] = useActivateNewSubscriptionMutation();
  const [error, setError] = useState("");

  const subscriptionData: accountModel = useSelector(
    (state: RootState) => state.subscriptionStore
  );

  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );

  useEffect(() => {
    const activatenewsubscription = async () => {
      const userid = userData.id;
      const response: any = await activateNewSubscription(JSON.stringify(userData.id));
      if (response.data) {
      } else if (response.error) {
        setError("Error: " + response.error.data);
      } else {
        setError("UnknownError");
      }
    }
    if ((userData.id !== '')) {
      activatenewsubscription();
    }
  }, [userData]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < W1) { mult = W1 / windowWidth; }
  if (windowWidth < W2) { mult = W1 / W2; }
  if (windowWidth > W0) {
    return (
      <div className="maincontainer2 ">
        <div style={{ width: 1260 / mult, height: 580 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="mainbox" style={{ height: 560 / mult, width: 1240 / mult }}>
            <span className="colord9d9d9 midfont" style={{ marginBottom: 80 / mult, fontSize: 28 / mult }}>
              Thank you! The payment was successful.<br />
              {error}
            </span>
            <EmptyButton nav={() => { navigate("/Portfolio"); }} title={""} id={"complition"} classname={"continuebutton"} />
          </div>
        </div>
      </div>
    );
  } else {
    mult = W0 / windowWidth;
    return (
      <div className="maincontainer2 ">
        <div className="mainbox" style={{ width: '100%', height: '100%' }}>
          <span className="colord9d9d9 midfont" style={{ marginBottom: 80 / mult, fontSize: 28 / mult }}>
            Thank you! The payment was successful.<br />
            {error}
          </span>
          <EmptyButton nav={() => { navigate("/Portfolio"); }} title={""} id={"complition"} classname={"continuebutton"} />
        </div>
      </div>
    );
  }

}

export default withAuth(Completion);