import '../../CSS/global.css';
import '../../CSS/smallmsgs.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../storage/redux/store";
import { userModel } from "../../Interfaces";
import { useCancelSubscriptionMutation } from "../../Apis/authApi";
import { withAuth } from '../../HOC';
import { W0, W1, W2 } from "../../functions";
import EmptyButton from '../Misc/EmptyButton';

function WantCancel() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
    );
    const [cancelSubscription] = useCancelSubscriptionMutation();

    const ClickCancel = async () => {
        const response: any = await cancelSubscription(JSON.stringify(userData.id));
        if (response.data) {
            navigate("/Account");
        } else if (response.error) {
            setError("Error: " + response.error.data);
        } else {
            setError("UnknownError");
        }
    }

    /*************Calculate data for responsive behaviour************************/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    let mult = 1;
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    /******************************************************************************/
    //If you cancel the current subscription, it will be active until the next billing period. We won't charge your card anymore.
    if (windowWidth > W0) {
        return (
            <>
                <div className="maincontainer2 colore6e6e6">

                    <div style={{ marginTop: 90, width: 1270 / mult, height: 570 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
                        <div className="sm-mainbox" style={{ width: 1240 / mult, height: 560 / mult }}>
                            <div className="sm-buttonbox" style={{ width: 1000 / mult, height: 90 / mult, fontWeight: 300, fontSize: 30 / mult + 'px' }}>
                                Your are about to cancel your subscription.
                                If you cancel the current subscription, you still will be able to log in, but the system will not execute searches on your images. We won't charge your card anymore.
                            </div>

                            <div style={{ width: 1300 / mult, height: 100 / mult }}>
                                {error}
                            </div>
                            <div className="sm-buttonbox" style={{ width: 1300 / mult, height: 90 / mult, marginBottom: 90 / mult }}>
                                <div className="p-upgradebutton" onClick={() => { navigate(-1); }} style={{ width: 380 / mult, height: 70 / mult, marginRight: 110 / mult, fontWeight: 300, fontSize: 22 / mult + 'px' }}>
                                    <span style={{ textAlign: 'center' }}>No,<br /> don't  cancel the subscriptions</span>
                                </div>
                                <div className="p-upgradebutton" onClick={ClickCancel} style={{ width: 380 / mult, height: 70 / mult, marginLeft: 110 / mult, fontWeight: 300, fontSize: 22 / mult + 'px' }}>
                                    <span style={{ textAlign: 'center' }}>Yes,<br /> please, cancel the subscriptions</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="maincontainer2 colore6e6e6">
                    <div className="sm-mainbox" style={{ position: 'absolute', width: '100vw', height: '100vh' }}>
                        <div className="sm-buttonbox" style={{ height: 90 / mult, fontWeight: 300, fontSize: 30 / mult + 'px', padding: 20 }}>
                            Your are about to cancel your subscription.
                            If you cancel the current subscription, you still will be able to log in, but the system will not execute searches on your images. We won't charge your card anymore.
                        </div>

                        <div style={{ width: 1300 / mult, height: 100 / mult }}>
                            {error}
                        </div>
                        <div className="sm-buttonbox" style={{ flexDirection: 'column', height: 90 / mult, marginBottom: 90 / mult }}>

                            <EmptyButton nav={() => { navigate(-1); }} title={"No, don't cancel the subscriptions"} id={""} />
                            <div style={{ width: 30 / mult, height: 50 / mult }}>
                                &nbsp;
                            </div>
                            <EmptyButton nav={ClickCancel} title={"Yes, please, cancel the subscriptions"} id={""} />
                        </div>
                    </div>


                </div>
            </>
        );
    }

}

export default withAuth(WantCancel);