import React, { useState, useEffect, useRef } from "react";
import '../../CSS/global.css';
import '../../CSS/account.css';
import '../../CSS/mobile.css';
import { useScrollbar } from '../../Hooks/use-scrollbar-y';
import { W0, W1, W2 } from "../../functions";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userModel } from "../../Interfaces";
import { useGetDetailsByUserQuery } from "../../Apis/authApi";
import { RootState } from "../../storage/redux/store";
import { setShowAll, setActAs } from "../../storage/redux/slice/userAuthSlice";
import CheckBoxBlock from "../Misc/CheckBoxBlock";
import EmptyButton from '../Misc/EmptyButton';
import { useGetSubscriptionDetailsQuery, useUpdateUserMutation } from "../../Apis/authApi";
import { useGetUsedQuotaQuery } from "../../Apis/imageApi";
import MiniLoader from "../Misc/MiniLoader";
import { withAuth } from '../../HOC';
import LogoDejavuAI from "../../assets/svgtojsx/LogoDejavuAI";

function Account() {
  type userElement = {
    label: string;
    value: string;
  }
  type userFromSubscription = {
    userid: string;
    email: string;
    primary: boolean;
    password1: string;
    password2: string;
    notification: string;
    frequency: string;
    frequencyaddon: boolean;
    telephone: string;
    userlastname: string;
    userfirstname: string;
    twofa: boolean;
  }

  const [accounttype, setAccountType] = useState(3);
  const [mediaquota, setMediaQuota] = useState(0);
  const [cost, setCost] = useState(0);
  const [swap, setSwap] = useState("Unknown");
  const [subscriptionStatus, setSubscriptionStatus] = useState(0);
  const [userstate, setUserState] = useState<userFromSubscription[]>([]);
  const [userlist, setUserList] = useState<userElement[]>([]);
  const [error, setError] = useState("");
  const [editmode, setEditMode] = useState("");
  const [seeallbox, setSeeAllBox] = useState(false);
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUserMutation();
  const [usedmediaquota, setUsedMediaQuota] = useState(0);
  const [thankYou, setThankYou] = useState("");

  /****************Get Account info *********************/
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  const usersubscription: any = useGetSubscriptionDetailsQuery(userData.id);

  useEffect(() => {
    setSeeAllBox(userData.a);
  }, []);
  /****************Get used quota *********************/
  const usedquota: any = useGetUsedQuotaQuery(userData.id);
  useEffect(() => {
    if (usedquota.data !== undefined) {
      setUsedMediaQuota(usedquota.data);
    }
  }, [usedquota.data]);
  /******************** Create state for users **************************/
  //let userlist: userElement[] = [];
  function GetSubscriptionData(sb: any) {
    console.log("sb");
    console.log(sb);
    setUserList(userlist => []);
    if (sb !== undefined) {
      let usersfromsubscription: Array<userFromSubscription> = [];
      setAccountType(sb.AppSubscriptionType);
      console.log("sb.AppSubscriptionType");
      console.log(sb.AppSubscriptionType);
      setUserList(userlist => []);
      usersfromsubscription.length = 0;
      for (let i = 0; i < sb.AppSubscriptionUsers.length; i++) {
        let tempuser: userFromSubscription = {
          userid: "",
          email: "",
          primary: false,
          password1: "",
          password2: "",
          notification: "",
          frequency: "",
          frequencyaddon: false,
          telephone: "",
          userlastname: "",
          userfirstname: "",
          twofa: false
        };

        let userelement = { label: sb.AppSubscriptionUsers[i].ApplicationUser.Email, value: sb.AppSubscriptionUsers[i].ApplicationUser.Id };

        userlist.push(userelement);

        tempuser.userid = sb.AppSubscriptionUsers[i].ApplicationUser.Id;
        tempuser.userlastname = sb.AppSubscriptionUsers[i].ApplicationUser.LastName;
        tempuser.email = sb.AppSubscriptionUsers[i].ApplicationUser.Email;
        tempuser.primary = sb.AppSubscriptionUsers[i].ApplicationUser.PrimaryUser;
        tempuser.userfirstname = sb.AppSubscriptionUsers[i].ApplicationUser.FirstName;
        setMediaQuota(sb.SearchMediaQuota);
        tempuser.telephone = sb.AppSubscriptionUsers[i].ApplicationUser.PhoneNumber;
        tempuser.twofa = sb.AppSubscriptionUsers[i].ApplicationUser.TwoFactorEnabled;
        setCost(sb.Cost / 100);
        setSubscriptionStatus(sb.AppSubscriptionStatus);
        switch (sb.Swap) {
          case false:
            setSwap("No");
            break;
          case true:
            setSwap("Yes");
            break;
          default:
            setSwap("Unknown");
        }
        switch (sb.AppSubscriptionUsers[i].NotificationType) {
          case 1:
            tempuser.notification = "Online";
            break;
          case 2:
            tempuser.notification = "SMS";
            break;
          case 3:
            tempuser.notification = "Email";
            break;
          case 4:
            tempuser.notification = "Email, SMS";
            break;
          default:
            tempuser.notification = "Unknown";
        }
        switch (sb.AppSubscriptionUsers[i].FrequencyType) {
          case 1:
            tempuser.frequency = "Monthly Scan";
            break;
          case 2:
            tempuser.frequency = "Weekly Scan";
            break;
          case 3:
            tempuser.frequency = "Daily Scan";
            break;
          case 4:
            tempuser.frequency = "3 Scans/Day";
            break;
          case 5:
            tempuser.frequency = "By Demand";
            break;
          default:
            tempuser.frequency = "Unknown";
        }
        //tempuser.frequencyaddon =  sb.AppSubscriptionUsers[i].FrequencyAddOn;
        if (sb.AppSubscriptionUsers[i].FrequencyAddOn) {
          tempuser.frequency = tempuser.frequency + " with OnDemand add-on";
        }

        // } //if
        usersfromsubscription.push(tempuser);
      } // for
      setUserList(prevUserState => [...userlist])
      setUserState(prevUserState => [...usersfromsubscription]);
    }
  }

  useEffect(() => {
    GetSubscriptionData(usersubscription.data);
  }, [usersubscription.data]);


  /******************** HANDLERS **************************/
  const seeallboxhandler = () => {
    dispatch(setShowAll(!seeallbox));
    setSeeAllBox(!seeallbox);
  };

  const edithandler = (e: any) => {
    setError("");
    setThankYou("");
    const [btnid, userid] = e.target.id.split("_");
    setEditMode(userid);
  };
  const cancelhandler = () => {
    setEditMode("");
    setError("");
    //GetSubscriptionData(usersubscription.data);
  };

  const savehandler = (e: any) => {
    console.log(e);
    setError("");
    const [btnid, userid] = e.target.id.split("_");
    userstate.forEach(async (element: userFromSubscription) => {
      if (element.userid === userid) {
        const t = element.telephone.replace(/ /g, '');
        if (element.password1 !== element.password2) {
          setError("The passwords you entered do not match.");
        } else if (t.length > 0 && t.length < 11) {
          setError("The phone number must have  11 or more digits. Country code + area code + number.");
        } else {
          setEditMode("");
          console.log(3);
          const response: any = await updateUser({
            "telephon": t,
            "last_name": element.userlastname,
            "first_name": element.userfirstname,
            "passsword": element.password1,
            "two_fa": element.twofa,
            "user_id": element.userid,
          });
          if (response.data) {
            if (response.data === "POK") {
              setThankYou("Your password has been changed successfully.");
            }

          } else if (response.error) {
            setError("Error: " + response.error.data);
          }
        }
      }
    });

  };
  const fnhandler = (e: any) => {
    const [btnid, userid] = e.target.id.split("_");
    userstate.forEach((element: userFromSubscription) => {
      if (element.userid === userid) {
        element.userfirstname = e.target.value;
      }
    });
    setUserState(prevUserState => [...userstate]);
  }
  const lnhandler = (e: any) => {
    const [btnid, userid] = e.target.id.split("_");
    userstate.forEach((element: userFromSubscription) => {
      if (element.userid === userid) {
        element.userlastname = e.target.value;
      }
    });
    setUserState(prevUserState => [...userstate]);
  };

  const formatPhoneNumber = (n: string) => {
    if (n.length > 16) return n.slice(0, -1);
    const phoneNumber = n.replace(/[^\d|\s]/g, "");
    //const phoneNumber = n.replace(/[^\d]/g, "");
    return phoneNumber;
    const phoneNumberLength = n.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3, 6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const telhandler = (e: any) => {
    const [btnid, userid] = e.target.id.split("_");
    userstate.forEach((element: userFromSubscription) => {
      if (element.userid === userid) {
        console.log("telhandler");
        element.telephone = formatPhoneNumber(e.target.value);

      }
    });
    setUserState(prevUserState => [...userstate]);
  };
  const twofahandler = (e: any) => {
    const [btnid, userid] = e.target.id.split("_");
    userstate.forEach((element: userFromSubscription) => {
      if (element.userid === userid) {
        element.twofa = !element.twofa;
      }
    });
    setUserState(prevUserState => [...userstate]);
  };
  const p1handler = (e: any) => {
    const [btnid, userid] = e.target.id.split("_");
    userstate.forEach((element: userFromSubscription) => {
      if (element.userid === userid) {
        element.password1 = e.target.value;
      }
    });
    setUserState(prevUserState => [...userstate]);
  };
  const p2handler = (e: any) => {
    const [btnid, userid] = e.target.id.split("_");
    userstate.forEach((element: userFromSubscription) => {
      if (element.userid === userid) {
        element.password2 = e.target.value;
      }
    });
    setUserState(prevUserState => [...userstate]);
  };
  const seeallboxarray = [{ "id": "seeallbox", "title": "", "buttonstate": seeallbox, "buttonhandler": seeallboxhandler },]

  /*************Calculate data for responsive behaviour****************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /**************** Scroll bar  things********************/
  const accountWrapper = useRef(null);
  const hasScroll = true;
  useScrollbar(accountWrapper, hasScroll, windowWidth);
  /***********************Acting As User*********************************/
  let [actingasuser, setActingAsUser] = useState(userData.actas_id);
  let handleUseList = (e: any) => {
    setActingAsUser(e.target.value);
    userlist.forEach(element => {
      if (element.value === e.target.value) {
        dispatch(setActAs({ "actas_id": e.target.value, "actas_email": element.label }));
      }
    });
  }
  /*********************************************************************/
  let plan;
  switch (accounttype) {
    case 1:
      plan = "Commercial";
      break;
    case 2:
      plan = "Individual";
      break;
    case 3:
      plan = "30 days Trial";
      break;
    default:
      plan = "Unknown";
  }
  if ([0, 1, 4, 5, 7].indexOf(subscriptionStatus) > -1) {
    plan = "No Plan";
  }
  let roletemp = userData.role;
  let temp2 = null;
  if (userlist.length > 0) {
    temp2 = userlist.map((user: userElement) => <option key={user.label} value={user.value} >{user.label}</option>);
  }
  let mult = 1;
  if (windowWidth < W1) { mult = W1 / windowWidth; }
  if (windowWidth < W2) { mult = W1 / W2; }

  const fakehandler = (e: any) => {
  };

  if (windowWidth > W0) {

    return (
      <div className="maincontainer2">
        <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="p-mainbox" style={{ width: 1325 / mult, height: 695 / mult }}>
            <div className="ac-mainbox-top" style={{ width: 1210 / mult, height: 50 / mult, justifyContent: 'space-between', marginTop: 13 / mult }}>
              <span className="ac-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px' }}>My Account</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div id="accountbuttons1" style={{ padding: '0px' }}>
                {(roletemp === "admin" && ([2, 3, 6].indexOf(subscriptionStatus) > -1)) &&
                  (
                    <>
                      {(accounttype === 3) ?
                        (
                          <div className="container-row-center">
                            <Link to='/WhatAccType' style={{ textDecoration: 'none' }}>
                              <EmptyButton nav={fakehandler} title={"Upgrade"} id={"dsaxas1"} />
                            </Link>&nbsp;&nbsp;
                          </div>
                        ) : (
                          <div className="container-row-center">
                            <Link to='/UpgradeAccount' style={{ textDecoration: 'none' }}>
                              <EmptyButton nav={fakehandler} title={"Upgrade"} id={"dsaxas2"} />
                            </Link>
                            &nbsp; &nbsp;&nbsp; &nbsp;
                            <Link to='/WantCancel' style={{ textDecoration: 'none' }}>
                              <EmptyButton nav={fakehandler} title={"Cancel Subscription"} id={"dsaxa1s"} classname={"emptybuttonred"} /> </Link>
                          </div>
                        )
                      }
                    </>
                  )
                }
                {(roletemp === "admin" && ([1, 4, 5, 7].indexOf(subscriptionStatus) > -1)) &&
                  (
                    <>
                      {(accounttype === 3) ?
                        (
                          <div className="container-row-center">
                            <Link to='/WhatAccType' style={{ textDecoration: 'none' }}>
                              <EmptyButton nav={fakehandler} title={"Upgrade"} id={"dsaxas3"} />
                            </Link>&nbsp;&nbsp;
                          </div>
                        ) : (
                          <div className="container-row-center">
                            <Link to='/UpgradeAccount' style={{ textDecoration: 'none' }}>
                              <EmptyButton nav={fakehandler} title={"Upgrade"} id={"dsaxas4"} />
                            </Link>&nbsp;&nbsp;
                          </div>
                        )
                      }
                    </>
                  )
                }
              </div>
              {(subscriptionStatus === 0) &&
                (<>
                  <MiniLoader />
                </>)
              }
            </div>
            <div className="ac-mainbox-content" style={{ width: 1230 / mult, height: 640 / mult }}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'right', fontSize: 20 / mult, fontWeight: 500 / mult }}><Link to="/Settings">Sites to ignore</Link></div>


              <div style={{ width: 1200 / mult, paddingRight: '12px', height: 500 / mult, minHeight: 500 / mult, marginBottom: 20 }} ref={accountWrapper}>
                <div className="" style={{ width: 600 / mult, height: 470 / mult, marginRight: 50 / mult, fontWeight: 300, fontSize: 20 / mult + 'px' }}>

                  <div className="ac-mainbox-top" style={{ width: 1000 / mult, height: 40 / mult }}>

                    Plan: &nbsp;<span className="ac-spanbold">{plan}</span>
                    {(roletemp === "admin") &&
                      (<>&nbsp;&nbsp; Cost :&nbsp;&nbsp;<span className="ac-spanbold">${cost}</span></>)
                    }
                    <span style={{ color: 'red', marginLeft: 30 }}>{error}</span>
                    <span style={{ color: 'green', marginLeft: 30 }}>{thankYou}</span>
                  </div>

                  <div className="ac-mainbox-top" style={{ width: 1210 / mult, height: 40 / mult }}>
                    User  : &nbsp;<span className="ac-spanbold">{userData.email} / {userData.role}</span>
                    {((roletemp === "admin" && accounttype === 1) || roletemp === "mng") && (
                      <>
                        <div className="ac-mainbox-top" style={{ height: 30 / mult }}>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Show all media : &nbsp;&nbsp;<CheckBoxBlock buttonsarray={seeallboxarray} layout={"column"} />
                        </div>
                        <div className="ac-mainbox-top" style={{ paddingLeft: '20px', height: 30 / mult }}>
                          Act as :&nbsp;&nbsp;
                          <select onChange={handleUseList} style={{ border: '0px', backgroundColor: 'white', width: 300 / mult, textAlign: 'center', height: 30 / mult, fontSize: 20 / mult + 'px', fontWeight: 400, fontFamily: 'Oswald', letterSpacing: '1px', outline: 'none', borderRadius: '10px' }}  >
                            <option value={userData.actas_id}>{userData.actas_email} </option>
                            {temp2}
                          </select>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="ac-mainbox-top" style={{ width: 1210 / mult, height: 40 / mult }}>
                    Portfolio size: &nbsp;<span className="ac-spanbold">{mediaquota}</span>
                    &nbsp;&nbsp;(<span className="ac-spanbold">{mediaquota - usedmediaquota}  slots remaining</span> )
                  </div>

                  <div className="ac-mainbox-top" style={{ width: 1210 / mult, height: 40 / mult }}>
                    Swap :&nbsp;<span className="ac-spanbold">{swap}</span>
                  </div>
                  {/*============================================================================================*/}
                  {
                    userstate.map((user: userFromSubscription) => {
                      return (
                        <ShowUser
                          key={user.userid}
                          user1={user}
                          userData={userData}
                          fnhandler={fnhandler}
                          telhandler={telhandler}
                          p1handler={p1handler}
                          p2handler={p2handler}
                          lnhandler={lnhandler}
                          savehandler={savehandler}
                          cancelhandler={cancelhandler}
                          edithandler={edithandler}
                          twofahandler={twofahandler}
                          mult={mult}
                          editmode={editmode}
                          windowWidth={windowWidth}
                        />
                      );
                    })}
                  {/*============================================================================================*/}
                </div>
              </div>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
                <LogoDejavuAI mult={mult} />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  } else {

    // Mobile *************************************************************

    mult = 600 / windowWidth;
    const mult1 = 3;
    return (
      <>
        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>My Account</span>
            {(roletemp === "admin" && ([2, 3, 6].indexOf(subscriptionStatus) > -1)) &&
              (
                <>
                  {(accounttype === 3) ?
                    (
                      <div className="container-row-center">
                        <Link to='/WhatAccType' style={{ textDecoration: 'none' }}>
                          <EmptyButton nav={fakehandler} title={"Upgrade"} id={"dsaxas1"} minibuttons={true} />
                        </Link>&nbsp;&nbsp;
                      </div>
                    ) : (
                      <div className="container-row-center">
                        <Link to='/UpgradeAccount' style={{ textDecoration: 'none' }}>
                          <EmptyButton nav={fakehandler} title={"Upgrade"} id={"dsaxas2"} minibuttons={true} />
                        </Link>
                        &nbsp; &nbsp;&nbsp; &nbsp;
                        <Link to='/WantCancel' style={{ textDecoration: 'none' }}>
                          <EmptyButton nav={fakehandler} title={"Cancel Subscription"} id={"dsaxa1s"} classname={"emptybuttonred"} minibuttons={true} /> </Link>
                      </div>
                    )
                  }
                </>
              )
            }
            {(roletemp === "admin" && ([1, 4, 5, 7].indexOf(subscriptionStatus) > -1)) &&
              (
                <>
                  {(accounttype === 3) ?
                    (
                      <div className="container-row-center">
                        <Link to='/WhatAccType' style={{ textDecoration: 'none' }}>
                          <EmptyButton nav={fakehandler} title={"Upgrade"} id={"dsaxas3"} minibuttons={true} />
                        </Link>&nbsp;&nbsp;
                      </div>
                    ) : (
                      <div className="container-row-center">
                        <Link to='/UpgradeAccount' style={{ textDecoration: 'none' }}>
                          <EmptyButton nav={fakehandler} title={"Upgrade"} id={"dsaxas4"} minibuttons={true} />
                        </Link>&nbsp;&nbsp;
                      </div>
                    )
                  }
                </>
              )
            }
          </div>
          <div className="main_div_mob" style={{ width: 550 / mult }}>
            <div style={{ width: 500 / mult, marginBottom: 30 }}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'right', fontSize: 20 / mult, fontWeight: 500 / mult }}><Link to="/Settings">Sites to ignore</Link></div>








              <span style={{ color: 'red', height: 40 / mult }}>{error}</span>
              <span style={{ color: 'green', height: 40 / mult }}>{thankYou}</span><br />
              <div className="ac-mainbox-top-mob" style={{ height: 40 / mult }}>
                Plan: &nbsp;<span className="ac-spanbold">{plan}</span>
                {(roletemp === "admin") &&
                  (<>&nbsp;&nbsp; Cost :&nbsp;&nbsp;<span className="ac-spanbold">${cost}</span></>)
                }
              </div>

              <div className="ac-mainbox-top-mob" >
                <div style={{ height: 40 / mult }}>User  : &nbsp;<span className="ac-spanbold">{userData.email} / {userData.role}</span></div>
                {((roletemp === "admin" && accounttype === 1) || roletemp === "mng") && (
                  <>
                    <div className="ac-mainbox-top" style={{ height: 40 / mult }}>
                      Show all media : &nbsp;&nbsp;<CheckBoxBlock buttonsarray={seeallboxarray} layout={"column"} />
                    </div>
                    <div className="ac-mainbox-top" style={{ height: 40 / mult }}>
                      Act as :&nbsp;&nbsp;
                      <select onChange={handleUseList} style={{ border: '0px', backgroundColor: 'white', width: 300 / mult, textAlign: 'center', height: 30 / mult, fontSize: 20 / mult + 'px', fontWeight: 400, fontFamily: 'Oswald', letterSpacing: '1px', outline: 'none', borderRadius: '10px' }}  >
                        <option value={userData.actas_id}>{userData.actas_email} </option>
                        {temp2}
                      </select>
                    </div>
                  </>
                )}
              </div>

              <div className="ac-mainbox-top" style={{ height: 40 / mult }}>
                Portfolio size: &nbsp;<span className="ac-spanbold">{mediaquota}</span>
                &nbsp;&nbsp;(<span className="ac-spanbold">{mediaquota - usedmediaquota}  slots remaining</span> )
              </div>

              <div className="ac-mainbox-top" style={{ height: 40 / mult }}>
                Swap :&nbsp;<span className="ac-spanbold">{swap}</span>
              </div>
              {/*============================================================================================*/}
              {
                userstate.map((user: userFromSubscription) => {
                  return (
                    <ShowUser
                      key={user.userid}
                      user1={user}
                      userData={userData}
                      fnhandler={fnhandler}
                      telhandler={telhandler}
                      p1handler={p1handler}
                      p2handler={p2handler}
                      lnhandler={lnhandler}
                      savehandler={savehandler}
                      cancelhandler={cancelhandler}
                      edithandler={edithandler}
                      twofahandler={twofahandler}
                      mult={mult}
                      editmode={editmode}
                      windowWidth={windowWidth}
                    />
                  );
                })}
              {/*============================================================================================*/}








            </div>
          </div>
        </div >
      </>
    );

  }
}

function ShowUser({ user1, userData, fnhandler, telhandler, p1handler, p2handler, lnhandler, savehandler, cancelhandler, edithandler, twofahandler, mult, editmode, windowWidth }: any) {
  let editmode2 = false;
  if (editmode === user1.userid) {
    editmode2 = true;
  }

  let primaryaccount = "";
  if (user1.primary) { primaryaccount = "Primary "; }
  const twofaarray = [{ "id": "twofa_" + user1.userid, "title": "", "buttonstate": user1.twofa, "buttonhandler": twofahandler },]

  if (userData.role !== "admin" && userData.id !== user1.userid) { return (<></>); }
  if (windowWidth > W0) {
    return (
      <div className="container-column" style={{ margin: 0, width: 1100 / mult, height: 320 / mult }}>
        <div className="ac-mainbox-top" style={{ width: '100%', marginBottom: 20 / mult, justifyContent: 'center' }}>
          {primaryaccount} User: &nbsp; <span className="ac-spanbold">{user1.email} </span>
        </div>
        <div className="container-row-up" style={{ margin: 0, width: 1000 / mult, justifyContent: 'center' }}>
          <div className="container-l-l-column" style={{ marginRight: 40 / mult, width: '50%' }}>
            <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
              <span>First name : </span>
              {editmode2 ?
                <input
                  type="text"
                  id={"userfn_" + user1.userid}
                  name={"userfn_" + user1.userid}
                  value={user1.userfirstname}
                  onChange={fnhandler}
                  style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
                />

                : <span className="ac-spanbold">{user1.userfirstname}</span>
              }
            </div>
            <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
              <span>Last Name : </span>
              {editmode2 ?
                <input
                  type="text"
                  id={"userln_" + user1.userid}
                  name={"userln_" + user1.userid}
                  value={user1.userlastname}
                  onChange={lnhandler}
                  style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
                />
                : <span className="ac-spanbold">{user1.userlastname}</span>
              }
            </div>
            <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
              <span>Search frequency : </span>
              <span className="ac-spanbold">{user1.frequency}</span>
            </div>
            <div className="container-row-between" style={{ width: '100%' }}>
              <span>Notification : </span>
              <span className="ac-spanbold">{user1.notification}</span>
            </div>
          </div>
          <div className="container-l-l-column" style={{ marginRight: 40 / mult, width: '50%' }}>
            <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
              <span>Cell Phone: </span>
              {editmode2 ?
                <input
                  type="text"
                  id={"tel_" + user1.userid}
                  name={"tel_" + user1.userid}
                  value={user1.telephone}
                  placeholder="1 123 1234567"
                  onChange={telhandler}
                  style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
                />
                : <span className="ac-spanbold">{user1.telephone}</span>
              }
            </div>
            <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
              <span>2FA : </span>
              <span className="ac-spanbold">No</span>
              {/*
            {editmode2 ?
              <CheckBoxBlock buttonsarray={twofaarray} layout={"column"} />
              : <span className="ac-spanbold">{user1.twofa ? "Yes" : "No"}</span>
            }
          */}
            </div>
            <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
              <span>Change Password: </span>
              {editmode2 ?
                <input
                  type="password"
                  id={"p1_" + user1.userid}
                  name={"p1_" + user1.userid}
                  value={user1.password1}
                  onChange={p1handler}
                  style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
                />
                : <span className="ac-spanbold">*****</span>
              }
            </div>
            <div className="container-row-between" style={{ width: '100%' }}>
              <span>Confirm password : </span>
              {editmode2 ?
                <input
                  type="password"
                  id={"p2_" + user1.userid}
                  name={"p2_" + user1.userid}
                  value={user1.password2}
                  onChange={p2handler}
                  style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
                />
                : <span className="ac-spanbold">*****</span>
              }
            </div>
          </div>
        </div>

        {editmode2 ?
          <>
            <div className="container-row-center" style={{ width: '100%', marginTop: 30 / mult }}>
              <EmptyButton nav={savehandler} title={"Save"} id={"saveuser_" + user1.userid} />&nbsp;&nbsp;&nbsp;&nbsp;

              <EmptyButton nav={cancelhandler} title={"Cancel"} id={""} />
            </div>
          </>
          :
          <div className="container-row-center" style={{ width: '100%', marginTop: 30 / mult }}>
            <EmptyButton nav={edithandler} title={"Edit Info"} id={"edituser_" + user1.userid} />
          </div>
        }
      </div>
    );
  } else {
    // MOBILE **************************************

    return (
      <div>
        <div className="ac-mainbox-top" style={{ width: '100%', marginBottom: 20 / mult, marginTop: 30 / mult, justifyContent: 'left' }}>
          ***** {primaryaccount} User: &nbsp; <span className="ac-spanbold">{user1.email} </span> *****
        </div>

        <div className="container-l-l-column" style={{ marginRight: 40 / mult }}>
          <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
            <span>First name : </span>
            {editmode2 ?
              <input
                type="text"
                id={"userfn_" + user1.userid}
                name={"userfn_" + user1.userid}
                value={user1.userfirstname}
                onChange={fnhandler}
                style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
              />

              : <span className="ac-spanbold">{user1.userfirstname}</span>
            }
          </div>
          <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
            <span>Last Name : </span>
            {editmode2 ?
              <input
                type="text"
                id={"userln_" + user1.userid}
                name={"userln_" + user1.userid}
                value={user1.userlastname}
                onChange={lnhandler}
                style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
              />
              : <span className="ac-spanbold">{user1.userlastname}</span>
            }
          </div>
          <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
            <span>Search frequency : </span>
            <span className="ac-spanbold">{user1.frequency}</span>
          </div>
          <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
            <span>Notification : </span>
            <span className="ac-spanbold">{user1.notification}</span>
          </div>
        </div>
        <div className="container-l-l-column" style={{ marginRight: 40 / mult }}>
          <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
            <span>Cell Phone: </span>
            {editmode2 ?
              <input
                type="text"
                id={"tel_" + user1.userid}
                name={"tel_" + user1.userid}
                value={user1.telephone}
                placeholder="1 123 1234567"
                onChange={telhandler}
                style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
              />
              : <span className="ac-spanbold">{user1.telephone}</span>
            }
          </div>
          <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
            <span>2FA : </span>
            <span className="ac-spanbold">No</span>
            {/*
                {editmode2 ?
                  <CheckBoxBlock buttonsarray={twofaarray} layout={"column"} />
                  : <span className="ac-spanbold">{user1.twofa ? "Yes" : "No"}</span>
                }
              */}
          </div>
          <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
            <span>Change Password: </span>
            {editmode2 ?
              <input
                type="password"
                id={"p1_" + user1.userid}
                name={"p1_" + user1.userid}
                value={user1.password1}
                onChange={p1handler}
                style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
              />
              : <span className="ac-spanbold">*****</span>
            }
          </div>
          <div className="container-row-between" style={{ width: '100%' }}>
            <span>Confirm password : </span>
            {editmode2 ?
              <input
                type="password"
                id={"p2_" + user1.userid}
                name={"p2_" + user1.userid}
                value={user1.password2}
                onChange={p2handler}
                style={{ outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'center' }}
              />
              : <span className="ac-spanbold">*****</span>
            }
          </div>
        </div>


        {editmode2 ?
          <>
            <div className="container-row-center" style={{ width: '100%', marginTop: 30 / mult }}>
              <EmptyButton nav={savehandler} title={"Save"} id={"saveuser_" + user1.userid} minibuttons={true} />&nbsp;&nbsp;&nbsp;&nbsp;

              <EmptyButton nav={cancelhandler} title={"Cancel"} id={""} minibuttons={true} />
            </div>
          </>
          :
          <div className="container-row-center" style={{ width: '100%', marginTop: 30 / mult }}>
            <EmptyButton nav={edithandler} title={"Edit Info"} id={"edituser_" + user1.userid} minibuttons={true} />
          </div>
        }
      </div>
    );


  }
}

export default withAuth(Account);
