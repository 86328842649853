import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { accountModel, ccModel, userModel } from "../../Interfaces";
import { RootState } from "../../storage/redux/store";
import { useNavigate } from 'react-router-dom';
import { useGetPKQuery } from "../../Apis/paymentsApi";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { withAuth } from '../../HOC';

function EnterPaymentInfo() {
    const [error, setError] = useState("");

    const [stripePromise, setStripePromise] = useState(null);  // stripe_keys.data.publishable_key
    const [clientSecret, setClientSecret] = useState("");

    const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
    );
    const subscriptionData: accountModel = useSelector(
        (state: RootState) => state.subscriptionStore
    );
    const paymentData: ccModel = useSelector(
        (state: RootState) => state.paymentStore
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        if (subscriptionData.clientSecret !== undefined) {
            setClientSecret(subscriptionData.clientSecret);
        }
    }, []);


    const stripe_keys: any = useGetPKQuery(1);

    if (stripe_keys.data !== undefined && stripePromise === null) {
        const temp: any = loadStripe(stripe_keys.data.publishableKey);
        setStripePromise(temp);
    }

    const proceedhandler = () => {
    }


    /*************Calculate data for responsive behaviour************************/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    let mult = 1920 / windowWidth;


    /******************************************************************************/

    return (
        <>
            <div className="maincontainer2 colore6e6e6" >
                <div style={{ width: 300, minHeight: 500, maxHeight: 700, padding: 20, background: '#494b53', borderRadius: '32px' }}>
                    <div style={{ height: 40, fontWeight: 400, fontSize: '20px', textAlign: 'center' }}>
                        Please enter your payment info :
                    </div>

                    <div style={{}}>
                        {clientSecret && stripePromise && (
                            <Elements stripe={stripePromise} options={{ clientSecret }}>
                                <CheckoutForm clientSecret1={{ clientSecret }} />
                            </Elements>
                        )}
                    </div>
                </div >
            </div >
        </>
    );
}
//{clientSecret && stripePromise && (

/*
            {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <CheckoutForm clientSecret1={{ clientSecret }} />
                </Elements>
            )}
            */

export default withAuth(EnterPaymentInfo);
