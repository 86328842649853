import React from "react";

function TrashEmptyMob({ mult }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28 / mult}
      height={42 / mult}
      version="1.1"
      viewBox="0 0 6.095 8.432"
    >
      <g transform="translate(76.9 -129.99)">
        <path
          fill="#494b53"
          fillOpacity="1"
          strokeWidth="0.469"
          d="M-73.853 129.99c-.78 0-1.56.12-2.155.358-1.19.477-1.19 1.25 0 1.726 1.19.477 3.12.477 4.31 0 1.19-.476 1.19-1.25 0-1.726-.595-.239-1.375-.358-2.155-.358zm0 .116c.643 0 1.287.089 1.778.267.982.357.982.935 0 1.292-.982.356-2.574.356-3.556 0-.982-.357-.982-.935 0-1.292.491-.178 1.135-.267 1.778-.267zM-76.9 131.5l.002.027a.45.45 0 01.002-.027zm6.095 0v.004-.004zm-6.083.15l.47 5.83h.01c.011.24.261.477.748.66.998.376 2.618.376 3.616 0 .551-.207.798-.485.741-.756l.484-5.706c-.07.268-.363.53-.876.735-1.19.477-3.12.477-4.31 0-.531-.213-.825-.484-.883-.762z"
          display="inline"
        ></path>
      </g>
    </svg>
  );
}

export default TrashEmptyMob;
