import '../../../src/CSS/global.css';
import '../../CSS/portfolio.css';
import '../../../src/CSS/mobile.css';

import { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from "react-router-dom";
import { withAuth } from '../../HOC';

import {
  useGetAllImagesInfoQuery,
  useGetImageDataQuery,
  useAddSearchFileMutation,
  useGetSearchOnDubleClickMutation,
  useSendToTrashMutation,
  useGetUsedQuotaQuery,
  useRunByDemandMutation,
  useGetByDemandNextTimeQuery,
  useSetByDemandNextTimeMutation,
} from "../../Apis/imageApi";
import { userModel } from "../../Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../storage/redux/store";
import { useScrollbar } from '../../Hooks/use-scrollbar-x';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useGetSubscriptionDetailsQuery } from "../../Apis/authApi";
import SimpleMsg1 from "../Misc/SimpleMsg1";
import SortMenu from "../Misc/SortMenu";
import MiniLoader from "../Misc/MiniLoader";
import { start } from 'repl';
import { setSortMenuState } from "../../storage/redux/slice/varSlice";
import { baseUrl, W0, W1, W2 } from "../../functions";
import EmptyButton from '../Misc/EmptyButton';
import LogoDejavuAI from "../../assets/svgtojsx/LogoDejavuAI";
import TrashFull from "../../assets/svgtojsx/TrashFull";
import TrashEmpty from "../../assets/svgtojsx/TrashEmpty";
import TrashFullMob from "../../assets/svgtojsx/TrashFullMob";
import TrashEmptyMob from "../../assets/svgtojsx/TrashEmptyMob";

/***************** Portifilio components ****************************/

const MobileLargeMedia = ({ result_images, id, userid, uuid4name, dbclick, subscriptionStatus, clicksendtotrash, mult, item_name, frequency, frequencyAddOn }: any) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const sorr = "s";
  const { data, isLoading, isSuccess, isError, error }: any = useGetImageDataQuery({ userid, sorr, uuid4name });
  if (data) {
    let i = new Image();
    i.src = `data:image/jpeg;base64,${data}`;
    const wh_relations = i.width / i.height;
    const h_name = ((500 / mult) / wh_relations) / 2 - 20;

    let not_akn_number = 0;
    if ((result_images !== undefined && result_images.length)) {
      for (let i = 0; i < result_images.length; i++) {
        if (result_images[i].result_status === 1) {
          not_akn_number++;
        }
      }
    }
    // style={{ padding: 0, height: 430 / mult, opacity: isHover ? 0.6 : 1 }} />

    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="largemediadiv" style={{ position: 'relative', width: 500 / mult, marginBottom: 20 / mult }}>
        <div style={{ background: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }} >
          <img className=""
            src={`data:image/jpeg;base64,${data}`}
            style={{ padding: 0, width: 500 / mult, opacity: isHover ? 0.6 : 1 }} />
        </div>

        {(not_akn_number > 0) ?
          <Link to="/Reports" style={{ textDecoration: 'none' }}>
            <div className="p-image-notakn-mob" title="Alerts" style={{ position: 'absolute', width: 52 / mult, height: 53 / mult, top: 10 / mult, left: 440 / mult, cursor: 'pointer' }}>
              <span style={{ fontSize: 23 / mult }}>{not_akn_number}</span>
            </div>
          </Link>
          :
          <div className="p-image-notakn-mob" title="Alerts" style={{ position: 'absolute', width: 52 / mult, height: 53 / mult, visibility: 'hidden' }}>
          </div>
        }

        <div className="p-image-delete" id={id} title="Move to Trash" onClick={clicksendtotrash}
          style={{ position: 'absolute', width: 52 / mult, height: 53 / mult, top: 10 / mult, left: 10 / mult }}></div>


        {((frequency === "By Demand" || frequencyAddOn) && ([0, 1, 4, 5, 7].indexOf(subscriptionStatus) === -1)) &&
          <div className="p-single-image-search" id={id} onClick={dbclick}
            style={{ position: 'absolute', width: 150 / mult, height: 45 / mult, top: 13 / mult, left: 70 / mult, fontWeight: 400 / mult, fontSize: 16 / mult, borderWidth: 4 / mult }}>
            Single Image Search
          </div>
        }

        <div className="p-item-name"
          style={{ position: 'absolute', left: 0, top: h_name, fontWeight: 500 / mult, fontSize: 20 / mult }}>
          {item_name}
        </div>


      </div>

    );
  }
  return (
    <div>
    </div>
  );

}

const LargeMedia = ({ result_images, id, userid, uuid4name, dbclick, subscriptionStatus, clicksendtotrash, mult, item_name, frequency, frequencyAddOn }: any) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const sorr = "s";
  const { data, isLoading, isSuccess, isError, error }: any = useGetImageDataQuery({ userid, sorr, uuid4name });
  if (data) {
    let i = new Image();
    i.src = `data:image/jpeg;base64,${data}`;
    let realwidth = i.width / (i.height / 430) - 46;
    let left1 = 70;
    let top1 = -508;

    if (realwidth < 220) {
      left1 = 15;
      top1 = -450;
    }
    let not_akn_number = 0;
    if ((result_images !== undefined && result_images.length)) {
      for (let i = 0; i < result_images.length; i++) {
        if (result_images[i].result_status === 1) {
          not_akn_number++;
        }
      }
    }

    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="largemediadiv" style={{ height: 430 / mult, marginBottom: 50 / mult }}>
        <div style={{ background: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0, marginLeft: 10, }} >
          <img className="largemediaimage"
            src={`data:image/jpeg;base64,${data}`}
            style={{ padding: 0, height: 430 / mult, opacity: isHover ? 0.6 : 1 }} />
        </div>
        {(not_akn_number > 0) ?
          <Link to="/Reports" style={{ textDecoration: 'none' }}>
            <div className="p-image-notakn" title="Alerts" style={{ width: 52 / mult, height: 53 / mult, top: -402 / mult, left: realwidth / mult, cursor: 'pointer' }}>
              <span style={{ fontSize: 23 / mult }}>{not_akn_number}</span>
            </div>
          </Link>
          :
          <div className="p-image-notakn" title="Alerts" style={{ width: 52 / mult, height: 53 / mult, top: -4 / mult, left: realwidth / mult, visibility: 'hidden' }}>
          </div>
        }
        <div className="p-image-delete" id={id} title="Move to Trash" onClick={clicksendtotrash}
          style={{ width: 51 / mult, height: 52 / mult, top: -455 / mult, left: 15 / mult }}></div>

        {((frequency === "By Demand" || frequencyAddOn) && ([0, 1, 4, 5, 7].indexOf(subscriptionStatus) === -1)) &&
          <div className="p-single-image-search" id={id} onClick={dbclick}
            style={{ width: 150 / mult, height: 45 / mult, top: top1 / mult, left: left1 / mult, fontWeight: 400 / mult, fontSize: 16 / mult, borderWidth: 4 / mult }}>
            Single Image Search
          </div>
        }
        <div className="p-item-name"
          style={{ width: realwidth / mult, left: 10, top: -400 / mult, fontWeight: 500 / mult, fontSize: 20 / mult, margin: 20 }}>
          {item_name}
        </div>

      </div>

    );
  }
  return (
    <div>
    </div>
  );

}
const SmallMedia = ({ result_images, id, userid, uuid4name, dbclick, clicksendtotrash, m, mult }: any) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const sorr = "s";
  const { data, isLoading, isSuccess, isError, error } = useGetImageDataQuery({ userid, sorr, uuid4name });
  if (data) {
    let i = new Image();
    i.src = `data:image/jpeg;base64,${data}`;
    let realwidth = i.width / (i.height / 90);
    let not_akn_number = 0;
    if ((result_images !== undefined && result_images.length)) {
      for (let i = 0; i < result_images.length; i++) {
        if (result_images[i].result_status === 1) {
          not_akn_number++;
        }
      }
    }
    return (
      <>
        <div className="smallmediadiv1" style={{ width: 140 / mult, height: 90 / mult }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className="smallmediadiv" style={{ width: 140 / mult, height: 90 / mult }} >
            <div style={{ background: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }} >
              <img className="smallmediaimage" id={id} src={`data:image/jpeg;base64,${data}`} style={{ maxWidth: 132 / mult, maxHeight: 82 / mult, opacity: isHover ? 0.6 : 1 }} />
            </div>
          </div>
          {(not_akn_number > 0) ?
            <Link to="/Reports" style={{ textDecoration: 'none' }}>
              <div className="p-image-notakn" title="Alerts" style={{ width: 30 / mult, height: 31 / mult, top: -88 / mult, left: 120 / mult, cursor: 'pointer' }}>
                <span >{not_akn_number}</span>
              </div>
            </Link>
            :
            <div className="p-image-notakn" title="Alerts" style={{ width: 30 / mult, height: 31 / mult, top: -88 / mult, left: 120 / mult, visibility: 'hidden' }}>
            </div>
          }
          <div className="p-image-delete" id={id} title="Move to Trash" onClick={clicksendtotrash}
            style={{ width: 30 / mult, height: 30 / mult, top: -120 / mult, left: 0 }}>
          </div>
        </div>

      </>
    );
  }
  return (
    <div className="smallmediadiv" style={{ width: 140 / mult, height: 90 / mult }}>
    </div>
  );
}

const SmallMediaList = ({ mediadata, dbclick, clicksendtotrash, userid, m, mult, mobile = false }: any) => {
  if (mediadata === undefined) {
    return (<></>);
  }
  if (mobile) {
    return (
      <div style={{ width: 450 / mult, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        {mediadata.map((imagedata: any) => {
          return (
            <SmallMedia
              key={imagedata.search_item_id}
              result_images={imagedata.result_images}
              id={imagedata.search_item_id}
              userid={imagedata.application_user_id}
              uuid4name={imagedata.uuid4name}
              dbclick={dbclick}
              clicksendtotrash={clicksendtotrash}
              mult={mult}
            />
          );
        })}
      </div>
    );
  } else {
    return (
      <div className='smallmedialist' style={{ height: 430 / mult }}>
        {mediadata.map((imagedata: any) => {
          return (
            <SmallMedia
              key={imagedata.search_item_id}
              result_images={imagedata.result_images}
              id={imagedata.search_item_id}
              userid={imagedata.application_user_id}
              uuid4name={imagedata.uuid4name}
              dbclick={dbclick}
              clicksendtotrash={clicksendtotrash}
              mult={mult}
            />
          );
        })}
      </div>
    );
  }
}
const LargeMediaList = ({ mediadata, dbclick, clicksendtotrash, userid, subscriptionStatus, mult, frequency, frequencyAddOn, mobile = false }: any) => {
  if (mediadata === undefined) {
    return (<></>);
  }
  if (mobile) {
    return (
      <div style={{ width: 500 / mult, marginTop: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {mediadata.map((imagedata: any) => {
          return (
            <MobileLargeMedia
              key={imagedata.search_item_id}
              result_images={imagedata.result_images}
              id={imagedata.search_item_id}
              userid={imagedata.application_user_id}
              uuid4name={imagedata.uuid4name}
              dbclick={dbclick}
              subscriptionStatus={subscriptionStatus}
              clicksendtotrash={clicksendtotrash}
              mult={mult}
              item_name={imagedata.item_name}
              frequency={frequency}
              frequencyAddOn={frequencyAddOn}
            />
          );
        })}
      </div>
    );
  } else {
    return (
      <div className='largemedialist'>
        {mediadata.map((imagedata: any) => {
          return (
            <LargeMedia
              key={imagedata.search_item_id}
              result_images={imagedata.result_images}
              id={imagedata.search_item_id}
              userid={imagedata.application_user_id}
              uuid4name={imagedata.uuid4name}
              dbclick={dbclick}
              subscriptionStatus={subscriptionStatus}
              clicksendtotrash={clicksendtotrash}
              mult={mult}
              item_name={imagedata.item_name}
              frequency={frequency}
              frequencyAddOn={frequencyAddOn}
            />
          );
        })}
      </div>
    );
  }
}
/***************** END of Portifilio components ****************************/

function Portfolio() {
  const [getSearchOnDubleClick] = useGetSearchOnDubleClickMutation();
  const [notification, setNotification] = useState("Unknown");
  const [frequency, setFrequency] = useState("Unknown");
  const [accounttype, setAccountType] = useState(3);
  const [subscriptionNotActive, setSubscriptionNotActive] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(0);
  const [mediaquota, setMediaQuota] = useState(0);
  const [usedmediaquota, setUsedMediaQuota] = useState(0);
  const [showAllQuotaUsed, setShowAllQuotaUsed] = useState(false);
  const [frequencyAddOn, setFrequencyAddOn] = useState(false);
  const [showNoSwapForYou, setShowNoSwapForYou] = useState(false);
  const [showSomeResultsFound, setShowSomeResultsFound] = useState("");
  const [showNoResultsFound, setShowNoResultsFound] = useState(false);
  const [showByDemandWin, setShowByDemandWin] = useState(false);
  const [showSortButton, setShowSortButton] = useState(false);
  const [showSelectedTooMany, setShowSelectedTooMany] = useState(false);
  const [numberImagesFound, setNumberImagesFound] = useState(0);
  const [usersnumber, setUsersNumber] = useState("wait...");
  const [processed, setProcessed] = useState(0);
  const [allDark, setAllDark] = useState(false);
  const [sendToTrash] = useSendToTrashMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  const varState: any = useSelector(
    (state: RootState) => state.varStore
  );


  /****************Get Initial image info *********************/
  //url: `initload/getsearchimagesinfo/${id}/${asid}`,
  const userid = userData.id;
  let userasid = userData.actas_id;
  if (userData.a) {
    userasid = "a";
  }
  let sort = varState.sortMenuState;
  const { data } = useGetAllImagesInfoQuery({ userid, userasid, sort });
  const searchimages = data?.notintrash;
  const resultnumber = data?.notaknnumber; // number of not acknowledged  results
  const numberintrash = data?.numberintrash;

  //  const { current, isLoading, isSuccess, isError, error }
  const usersubscription: any = useGetSubscriptionDetailsQuery(userid);

  const usedquota: any = useGetUsedQuotaQuery(userid);

  useEffect(() => {
    if (usedquota.data !== undefined) {
      setUsedMediaQuota(usedquota.data);
    }
  }, [usedquota.data]);


  document.body.style.background = "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),url('../assets/img/Bg/bg_v1_1920x1080.png') no-repeat center center;"
  //useEffect(() => {
  //  document.body.style.background = "linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),url('../assets/img/Bg/bg_v1_1920x1080.png') no-repeat center center;"
  //}, []);
  /****************Get Account info *********************/
  let fr = "";
  let nt = "";
  useEffect(() => {
    if (usersubscription.data !== undefined) {

      console.log("usersubscription.data");
      console.log(usersubscription.data);
      setAccountType(usersubscription.data.AppSubscriptionType);
      setSubscriptionStatus(usersubscription.data.AppSubscriptionStatus);
      setMediaQuota(usersubscription.data.SearchMediaQuota);
      let un = usersubscription.data.AppSubscriptionUsers.length;
      if (un === 1) {
        setUsersNumber("1 user");
      } else if (un > 1) {
        setUsersNumber(un + " users");
      } else {
        setUsersNumber("error");
      }
      for (let i = 0; i < un; i++) {
        if (usersubscription.data.AppSubscriptionUsers[i].ApplicationUserId === userData.id) {
          setFrequencyAddOn(usersubscription.data.AppSubscriptionUsers[i].FrequencyAddOn);
          switch (usersubscription.data.AppSubscriptionUsers[i].NotificationType) {
            case 1:
              setNotification("Online");
              break;
            case 2:
              setNotification("SMS");
              break;
            case 3:
              setNotification("Email");
              break;
            case 4:
              setNotification("Email, SMS");
              break;
            default:
              setNotification("Unknown");
          }
          switch (usersubscription.data.AppSubscriptionUsers[i].FrequencyType) {
            case 1:
              setFrequency("Monthly Scan");
              break;
            case 2:
              setFrequency("Weekly Scan");
              break;
            case 3:
              setFrequency("Daily Scan");
              break;
            case 4:
              setFrequency("3 Scans/Day");
              break;
            case 5:
              setFrequency("By Demand");
              break;
            default:
              setFrequency("Unknown");
          }
        }
      }
    }
  }, [usersubscription.data]);

  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /**************** Scroll bar  things*********************************/
  const mediaWrapper = useRef(null);
  const hasScroll = true;

  useScrollbar(mediaWrapper, hasScroll, windowWidth);
  /************* Switcj Small/Large View************************/
  const [smallicons, setIconsSize] = useState(false);
  const switchSmallLargeIcons = () => {
    let temp1 = document.getElementById('iconsizediv1')!;
    let temp2 = document.getElementById('iconsizediv2')!;
    if (smallicons) {
      temp1.style.display = 'block';
      temp2.style.display = 'none';
    } else {
      temp2.style.display = 'block';
      temp1.style.display = 'none';
    }
    setIconsSize(!smallicons);
  };

  /************************* Button handlers ********************************************/
  const DoubleClickForSearch = async (event: any) => {
    //if (event.detail === 2) {
    const SearchImageId = event.target.id;
    const response: any = await getSearchOnDubleClick({
      SearchImageId
    });
    if (response.data) {
      if (response.data === "subscriptionnotactive") {
        setSubscriptionNotActive(true);
        setAllDark(true);
      } else {
        if (response.data != 0) {
          if (response.data == 1) {
            setShowSomeResultsFound('1 result found. Please, visit "My Reports" to see details');
          } else {
            setShowSomeResultsFound(response.data + ' results found. Please, visit "My Reports" to see details');
          }
          setAllDark(true);
        } else {
          setShowNoResultsFound(true);
          setAllDark(true);
        }
      }
    } else if (response.error) {
      ;
    }
    //}
  }

  const ClickCancelNoResults = () => {
    setShowNoResultsFound(false);
    setAllDark(false);
  }

  const ClickSendToTrash = (event: any) => {
    if (event.detail === 1) {
      if (usersubscription.data.AppSubscriptionType == 3) {
        setShowNoSwapForYou(true);
        setAllDark(true);
      } else {
        const response = sendToTrash({
          search_item_id: event.target.id,
        }
        );
      }
    }
  }
  /*******  Sort  **********/
  const ClickSort = () => {
    setShowSortButton(!showSortButton);
  }
  const handleSortMostAlerts = () => {
    dispatch(setSortMenuState(1));
    setShowSortButton(false);
  }
  const handleSortNewest = () => {
    dispatch(setSortMenuState(2));
    setShowSortButton(false);
  }
  const handleSortOldest = () => {
    dispatch(setSortMenuState(3));
    setShowSortButton(false);
  }
  const handleSortAZ = () => {
    dispatch(setSortMenuState(4));
    setShowSortButton(false);
  }
  /**********************/
  const ClickCancel = () => {
    setAllDark(false);
    setShowAllQuotaUsed(false)
    setShowNoSwapForYou(false);
    setShowSelectedTooMany(false);
  }

  const ClickByDemand = () => {
    setProcessed(0);
    setAllDark(true);
    setNumberImagesFound(0);
    setShowByDemandWin(true);
  }
  const ClickCancelByDemand = () => {
    setAllDark(false);
    setShowByDemandWin(false);
    setSubscriptionNotActive(false);
  }
  const ClickCancelSomeResuls = () => {
    setAllDark(false);
    setShowSomeResultsFound("");
    setSubscriptionNotActive(false);
  }

  const ClickContinue = () => {
    navigate(-1);
  }

  const ClickUpgrade = () => {
    setAllDark(false);
    if (usersubscription.data.AppSubscriptionType == 3) { navigate("/WhatAccType"); }
    if (usersubscription.data.AppSubscriptionType == 1 || usersubscription.data.AppSubscriptionType == 2) { navigate("/UpgradeAccount"); }
  }

  let mult = 1;
  if (windowWidth > W0) {
    // PC *************************************************************
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (
      <>
        {showByDemandWin && <SearchByDemand text={"Do you wish to run a real-time search for your portfolio?"}
          buttonupgrade={ClickUpgrade} buttoncancel={ClickCancelByDemand} setAllDark={setAllDark} usedmediaquota={usedmediaquota}
          searchimages={searchimages} getSearchOnDubleClick={getSearchOnDubleClick} processed={processed}
          setProcessed={setProcessed} numberImagesFound={numberImagesFound} setNumberImagesFound={setNumberImagesFound}
          subscriptionNotActive={subscriptionNotActive} setSubscriptionNotActive={setSubscriptionNotActive} userid={userData.id} />
        }
        {subscriptionNotActive && <SimpleMsg1 target={ClickCancelSomeResuls} text={"For some reason your subscription is not active. Please contact our support."} title={"Continue"} mult={mult} />
        }
        {showSomeResultsFound && <SimpleMsg1 target={ClickCancelSomeResuls} text={showSomeResultsFound} title={"Continue"} mult={mult} />
        }
        {showNoResultsFound && <SimpleMsg1 target={ClickCancelNoResults} text={"No new results found"} title={"Continue"} mult={mult} />
        }
        {showSelectedTooMany && <SimpleMsg1 target={ClickCancel} text={"You have selected more files than your subscription allows. Please go to 'My account' and upgrade"} title={"Continue"} mult={mult} />
        }
        {(showAllQuotaUsed && (userData.role === "user" || userData.role === "mng")) && <SimpleMsg1 target={ClickCancel} text={"You used all your media quota. To inrease it please contact admin"} title={"Continue"} mult={mult} />
        }
        {showAllQuotaUsed && <AllQuotaUsed text={"You used all your media quota. Please upgrade your account to add more images."} buttonupgrade={ClickUpgrade} buttoncancel={ClickCancel} mult={mult} />
        }
        {showNoSwapForYou && <AllQuotaUsed text={"You have Trial Plan. The plan doew not allow  Swap. If you wish to remove some images and add new ones , please upgrade."} buttonupgrade={ClickUpgrade} buttoncancel={ClickCancel} mult={mult} />
        }
        <div className="maincontainer2">

          <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
            <div className="p-mainbox" style={{ width: 1325 / mult, height: 695 / mult }}>
              <div className="p-mainbox-top" style={{ width: 1250 / mult, height: 75 / mult }}>
                <div style={{ display: 'flex' }}>
                  <span className="p-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px' }}>My Portfolio</span>
                  <div style={{ marginTop: 3 / mult, marginLeft: 50 / mult }}>
                    <LogoDejavuAI mult={mult} />
                  </div>
                </div>
                {(usedmediaquota >= mediaquota) ?
                  (<div className="p-add" onClick={() => { setShowAllQuotaUsed(true); setAllDark(true); }} style={{ width: 187 / mult, height: 48 / mult }}>
                  </div>)
                  :
                  (
                    <>
                      <MyDropzone mult={mult} usedmediaquota={usedmediaquota} mediaquota={mediaquota} hook={setShowSelectedTooMany} setAllDark={setAllDark} userid={userData.id} mobile={false} />
                    </>
                  )
                }
              </div>
              <div className="p-mainbox-sort" style={{ width: 1280 / mult, height: 82 / mult }}>

                <div className="p-mainbox-sort-left" style={{ height: 50 / mult }}>
                  <SortMenu />
                </div>

                <div className="p-mainbox-sort-right1" id="iconsizediv1" onClick={switchSmallLargeIcons} style={{ width: 108 / mult, height: 44 / mult }}>
                </div>
                <div className="p-mainbox-sort-right2" id="iconsizediv2" onClick={switchSmallLargeIcons} style={{ width: 108 / mult, height: 44 / mult }}>
                </div>
              </div>
              <div className="p-mainbox-media" ref={mediaWrapper} style={{ width: 1183 / mult, height: 440 / mult }}>



                {
                  smallicons ? (
                    <div>
                      <SmallMediaList mediadata={searchimages} dbclick={DoubleClickForSearch} clicksendtotrash={ClickSendToTrash} userid={userData.id} mult={mult} />
                    </div>
                  ) : (
                    <div>
                      <LargeMediaList mediadata={searchimages} dbclick={DoubleClickForSearch} clicksendtotrash={ClickSendToTrash} userid={userData.id} subscriptionStatus={subscriptionStatus} mult={mult} frequency={frequency} frequencyAddOn={frequencyAddOn} mobile={false} />
                    </div>
                  )

                }
              </div>

              <div className="p-mainbox-scroll" style={{ width: 1283 / mult, height: 33 / mult }}>
              </div>
              <div className="p-mainbox-bottom" style={{ width: 1265 / mult, height: 75 / mult }}>
                <div className="p-mainbox-bottom-left" style={{ width: 1080 / mult, height: 80 / mult, fontSize: 25 / mult + 'px' }}>
                  {(resultnumber != undefined && resultnumber > 0) &&
                    <Link to="/Reports" style={{ textDecoration: 'none' }}>
                      <div className="p-mainbox-bottom-totalalerts  p-flex" style={{ color: 'white', width: 79 / mult, height: 50 / mult, paddingLeft: 10 / mult, textAlign: 'center', cursor: 'pointer' }}>
                        <span style={{ marginLeft: 32 / mult }}>{resultnumber}</span>
                      </div>
                    </Link>
                  }
                  <div className="p-mainbox-bottom-capacity p-flex" style={{ width: 170 / mult, height: 51 / mult }}>
                    {usedmediaquota + "/" + mediaquota}
                  </div>
                  {((frequency === "By Demand" || frequencyAddOn) && ([0, 1, 4, 5, 7].indexOf(subscriptionStatus) === -1)) ?
                    (
                      <div className="p-mainbox-bottom-fr p-flex color698ff9" onClick={ClickByDemand} style={{ width: 235 / mult, height: 51 / mult, cursor: 'pointer' }}>
                        {frequency}
                      </div>
                    ) : (
                      <div className="p-mainbox-bottom-fr p-flex" style={{ width: 235 / mult, height: 51 / mult }}>
                        {frequency}
                      </div>
                    )
                  }
                  <div className="p-mainbox-bottom-notifications p-flex" style={{ width: 253 / mult, height: 51 / mult }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{notification}
                  </div>

                  {(accounttype === 1 && userData.role !== "user") &&
                    <div className="p-mainbox-bottom-users p-flex" style={{ width: 182 / mult, height: 51 / mult }}>
                      {usersnumber}
                    </div>
                  }
                </div>
                <Link to="/Trash">
                  <div style={{ margin: 5 / mult }}>
                    {numberintrash > 0 ? (
                      <TrashFull mult={mult} />
                    ) : (
                      <TrashEmpty mult={mult} />
                    )
                    }
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div >
        {allDark &&
          <div id="darkdiv" style={{ width: '100%', height: '100%', backgroundColor: 'black', position: 'absolute', opacity: 0.5, top: 0, zIndex: 50 }}></div>
        }
      </>

    );
  } else {
    // Mobile *************************************************************
    mult = W0 / windowWidth;
    return (
      <>
        {showByDemandWin && <SearchByDemand text={"Do you wish to run a real-time search for your portfolio?"}
          buttonupgrade={ClickUpgrade} buttoncancel={ClickCancelByDemand} setAllDark={setAllDark} usedmediaquota={usedmediaquota}
          searchimages={searchimages} getSearchOnDubleClick={getSearchOnDubleClick} processed={processed}
          setProcessed={setProcessed} numberImagesFound={numberImagesFound} setNumberImagesFound={setNumberImagesFound}
          subscriptionNotActive={subscriptionNotActive} setSubscriptionNotActive={setSubscriptionNotActive} userid={userData.id} mobile={true} />
        }
        {subscriptionNotActive && <SimpleMsg1 target={ClickCancelSomeResuls} text={"For some reason your subscription is not active. Please contact our support."} title={"Continue"} mult={mult} mobile={true} />
        }
        {showSomeResultsFound && <SimpleMsg1 target={ClickCancelSomeResuls} text={showSomeResultsFound} title={"Continue"} mult={mult} mobile={true} />
        }
        {showNoResultsFound && <SimpleMsg1 target={ClickCancelNoResults} text={"No new results found"} title={"Continue"} mult={mult} mobile={true} />
        }
        {showSelectedTooMany && <SimpleMsg1 target={ClickCancel} text={"You have selected more files than your subscription allows. Please go to 'My account' and upgrade"} title={"Continue"} mult={mult} mobile={true} />
        }
        {(showAllQuotaUsed && (userData.role === "user" || userData.role === "mng")) && <SimpleMsg1 target={ClickCancel} text={"You used all your media quota. To inrease it please contact admin"} title={"Continue"} mult={mult} mobile={true} />
        }
        {showAllQuotaUsed && <AllQuotaUsed text={"You used all your media quota. Please upgrade your account to add more images."} buttonupgrade={ClickUpgrade} buttoncancel={ClickCancel} mult={mult} mobile={true} />
        }
        {showNoSwapForYou && <AllQuotaUsed text={"You have Trial Plan. The plan doew not allow  Swap. If you wish to remove some images and add new ones , please upgrade."} buttonupgrade={ClickUpgrade} buttoncancel={ClickCancel} mult={mult} mobile={true} />
        }

        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 30 / mult + 'px' }}>My Portfolio&nbsp;</span>
            {(resultnumber != undefined && resultnumber > 0) &&
              <div className="p-mainbox-bottom-totalalerts  p-flex" style={{ width: 53 / mult, height: 38 / mult, paddingLeft: 10, textAlign: 'center', marginBottom: 4 }}>
                <span style={{ marginLeft: 14 / mult }}>{resultnumber}</span>
              </div>
            }

            <div className="p-mainbox-bottom-capacity p-flex colord9d9d9" style={{ width: 100 / mult, height: 35 / mult, marginBottom: 4 }}>
              {usedmediaquota + "/" + mediaquota}
            </div>
            <div style={{ marginBottom: 5, marginLeft: 7 }}>
              {(usedmediaquota >= mediaquota) ?
                (<div className="p-add" onClick={() => { setShowAllQuotaUsed(true); setAllDark(true); }} style={{ width: 126 / mult, height: 32 / mult, marginBottom: 0, marginLeft: 0 }}>
                </div>)
                :
                (<MyDropzone mult={mult} usedmediaquota={usedmediaquota} mediaquota={mediaquota} hook={setShowSelectedTooMany} setAllDark={setAllDark} userid={userData.id} mobile={true} />)
              }
            </div>
          </div>

          <div className="main_div_mob" style={{ width: 550 / mult }}>
            <div className="p-mainbox-sort" style={{ width: 550 / mult, height: 40, marginTop: 5 }}>
              <div style={{ height: 45 / mult, display: 'flex', alignItems: 'center' }}>
                {((frequency === "By Demand" || frequencyAddOn) && ([0, 1, 4, 5, 7].indexOf(subscriptionStatus) === -1)) &&
                  <div className="p-mainbox-bydemand-mob" onClick={ClickByDemand} style={{ width: 89 / mult, height: 37 / mult, marginLeft: 17, marginBottom: 3 }}>
                  </div>
                }
                <div className="p-mainbox-sort-left" style={{ height: 45, marginTop: 12 }}>
                  <SortMenu mobile={true} />
                </div>
                <div className="p-mainbox-sort-right1-mob" id="iconsizediv1" onClick={switchSmallLargeIcons} style={{ width: 95, height: 25 }}>
                </div>
                <div className="p-mainbox-sort-right2-mob" id="iconsizediv2" onClick={switchSmallLargeIcons} style={{ width: 95, height: 25 }}>
                </div>
              </div>

              <Link to="/Trash">
                <div style={{ margin: 10 / mult }}>
                  {numberintrash > 0 ? (
                    <TrashFullMob mult={mult} />
                  ) : (
                    <TrashEmptyMob mult={mult} />
                  )
                  }
                </div>
              </Link>
            </div>
            {

              smallicons ? (
                <div>
                  <SmallMediaList mediadata={searchimages} dbclick={DoubleClickForSearch} clicksendtotrash={ClickSendToTrash} userid={userData.id} mult={mult} mobile={true} />
                </div>
              ) : (
                <div>
                  <LargeMediaList mediadata={searchimages} dbclick={DoubleClickForSearch} clicksendtotrash={ClickSendToTrash} userid={userData.id} subscriptionStatus={subscriptionStatus} mult={mult} frequency={frequency} frequencyAddOn={frequencyAddOn} mobile={true} />
                </div>
              )
            }
          </div>
        </div >
      </>
    );
  }

}


function MyDropzone({ mult, usedmediaquota, mediaquota, hook, setAllDark, userid, mobile }: any) {

  const dispatch = useDispatch();
  const [addSearchFile] = useAddSearchFileMutation();
  const [sendToTrash] = useSendToTrashMutation();
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );

  const onDrop = useCallback((acceptedFiles: any) => {
    const newfilenumber = acceptedFiles.length;
    if (mediaquota < newfilenumber + usedmediaquota) {
      hook(true);
      setAllDark(true);
    } else {

      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {

          // convert and send   file to backend
          const buffer: any = reader.result;
          const binaryString = Array.from(new Uint8Array(buffer), byte => String.fromCharCode(byte)).join("");
          //const base64String = btoa(binaryString);
          //const base64String = Buffer.from(binaryString, 'binary').toString('base64')
          const base64String = btoa(binaryString);

          const response = addSearchFile({
            user_id: userData.id,
            image_name: file.path,
            image_data: base64String
          });
        }
        reader.readAsArrayBuffer(file)
      })
    }


  }, [usedmediaquota])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  if (mobile) {
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <div className="p-add" style={{ width: 137 / mult, height: 35 / mult, marginBottom: 0, marginRight: 0 }}>
        </div>
      </div>
    );
  } else {
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        <div className="p-add" style={{ width: 187 / mult, height: 48 / mult }}>
        </div>
      </div>
    );
  }
}


const AllQuotaUsed = ({ text, buttonupgrade, buttoncancel, mobile = false }: any) => {
  //const data64 = BytesTo64(data);
  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < 1100) { mult = 1100 / windowWidth; }
  let mult1 = 2500 / windowWidth;
  /******************************************************************************/
  if (mobile) {
    // Mobile *************************************************************
    mult = 2;
    return (
      <div className="t-emptytrashbox" style={{ position: 'absolute', width: '100vw', height: '100vh' }}>
        <div className="t-emptytraahdbox-title colord9d9d9" style={{ height: 122 / mult, fontSize: 30 / mult + 'px' }}>
          Account upgrade
        </div>
        <div className="t-emptytraahdbox-text colord9d9d9" style={{ width: 600 / mult, height: 108 / mult, fontSize: 15 / mult + 'px' }}>

          <div style={{ width: 600 / mult, height: 108 / mult, fontSize: 30 / mult + 'px' }}>
            {text}
          </div>
          <div>

          </div>

        </div>
        <div className="t-emptytraahdbox-buttons" style={{ marginTop: 40, flexDirection: 'column', height: 160 / mult }}>
          <EmptyButton nav={buttoncancel} classname={"emptybuttongray"} title={"Cancel"} id={""} flex={true} />
          <div style={{ width: 30 / mult, height: 80 / mult }}>
            &nbsp;
          </div>
          <EmptyButton nav={buttonupgrade} title={"Upgrade the account"} id={""} flex={true} />
        </div>
      </div>
    );
  } else {
    // PC *************************************************************
    return (
      <div className="t-emptytrashbox" style={{ width: 810 / mult, height: 391 / mult, top: 230, left: 600 / mult1 }}>
        <div className="t-emptytraahdbox-title colord9d9d9" style={{ width: 798 / mult, height: 122 / mult, fontSize: 25 / mult + 'px' }}>
          Account upgrade
        </div>
        <div className="t-emptytraahdbox-text colord9d9d9" style={{ width: 600 / mult, height: 108 / mult, fontSize: 15 / mult + 'px' }}>

          <div style={{ width: 600 / mult, height: 108 / mult, fontSize: 15 / mult + 'px' }}>
            {text}
          </div>
          <div>

          </div>

        </div>
        <div className="t-emptytraahdbox-buttons" style={{ width: 798 / mult, height: 160 / mult }}>
          <EmptyButton nav={buttoncancel} classname={"emptybuttongray"} title={"Cancel"} id={""} flex={true} />
          <div style={{ width: 30 / mult, height: 80 / mult }}>
            &nbsp;
          </div>
          <EmptyButton nav={buttonupgrade} title={"Upgrade the account"} id={""} flex={true} />
        </div>
      </div>
    );
  }
}

const SearchByDemand = ({ text, buttonupgrade, buttoncancel, setAllDark, usedmediaquota, searchimages, getSearchOnDubleClick, processed, setProcessed, numberImagesFound, setNumberImagesFound, subscriptionNotActive, setSubscriptionNotActive, userid, mobile = false }: any) => {
  const [inProgress, setInProgress] = useState(false);
  const [finished, setFinished] = useState(false);
  const [nextTimeFlag, setNextTimeFlag] = useState(null);
  const [nextTime, setNextTime] = useState("");
  useEffect(() => {
    fetch(baseUrl + "searchimage/getbydemandnexttime/" + userid)
      .then(response => {
        return response.json()
      })
      .then(data => {
        setNextTimeFlag(data.SearchAvailable); setNextTime(data.NextSearchTime);
      })
  }, []);
  const [setByDemandNextTime] = useSetByDemandNextTimeMutation();
  const [runByDemand] = useRunByDemandMutation();
  const ClickRunSearch = async () => {
    // Check next time for  By Demand run
    //if time is  okay run search  netxtimestruct.data.NextSearchTime
    if (nextTimeFlag) {
      await setByDemandNextTime(userid);
      runByDemand(userid);
      setFinished(true);
    } else {

    }
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < 1100) { mult = 1100 / windowWidth; }
  let mult1 = 2500 / windowWidth;

  if (mobile) {
    mult = 2;
    return (
      <div className="t-emptytrashbox" style={{ position: 'absolute', width: '100vw', height: '100vh' }}>


        {nextTimeFlag === null ?
          "Wait..."
          :
          <>
            <div className="t-emptytraahdbox-text colord9d9d9" style={{ width: 600 / mult, height: 108 / mult, fontSize: 20 / mult + 'px' }}>

              {subscriptionNotActive && (
                <div style={{ color: 'red' }}>
                  For some reason your subscription is not active<br />
                  Please contact our support.
                </div>
              )
              }

              {inProgress && (
                <div style={{ fontSize: 30 / mult + 'px' }}>
                  Searching... Processed {processed} of {usedmediaquota} search images.<br />
                  {numberImagesFound === 1 ? (<>1 result found.</>) : (<>{numberImagesFound} results found.</>)}
                </div>
              )
              }
              {finished && (
                <div style={{ fontSize: 30 / mult + 'px' }}>
                  Search By Demand launched.<br />
                  When it is finished, we will notify you by email or/and SMS.
                </div>
              )
              }
              {(!finished && !inProgress && nextTimeFlag) && (
                <span style={{ fontSize: 30 / mult + 'px' }}>{text}</span>
              )
              }
              {(!nextTimeFlag) && (
                <span style={{ fontSize: 30 / mult + 'px' }}>Next search can be executed at {nextTime} or later</span>
              )
              }

              <div style={{ width: 600 / mult, height: 108 / mult, fontSize: 15 / mult + 'px' }}>
              </div>

            </div>
            <div className="t-emptytraahdbox-buttons" style={{ marginTop: 40, flexDirection: 'column', width: 600 / mult, height: 160 / mult }}>
              {(finished || subscriptionNotActive || !nextTimeFlag) ? (
                <EmptyButton nav={buttoncancel} title={"Continue"} id={""} flex={true} />
              ) : (
                <EmptyButton nav={buttoncancel} classname={"emptybuttongray"} title={"Cancel"} id={""} flex={true} />
              )}

              {(!inProgress && !finished && nextTimeFlag) && (
                <>
                  <div style={{ width: 100 / mult, height: 50 / mult }}>

                  </div>

                  &nbsp;&nbsp;<EmptyButton nav={ClickRunSearch} title={"Yes, run the search."} id={""} flex={true} />
                </>
              )}

            </div>
          </>
        }
      </div>
    );
  } else {
    return (
      <div className="t-emptytrashbox" style={{ zIndex: 200, width: 850 / mult, height: 391 / mult, top: 180, left: 500 / (mult1 - 0.4) }}>
        <div className="t-emptytraahdbox-title colord9d9d9" style={{ width: 798 / mult, height: 122 / mult, fontSize: 25 / mult + 'px' }}>

        </div>

        {nextTimeFlag === null ?
          "Wait..."
          :
          <>
            <div className="t-emptytraahdbox-text colord9d9d9" style={{ width: 600 / mult, height: 108 / mult, fontSize: 15 / mult + 'px' }}>

              {subscriptionNotActive && (
                <div style={{ color: 'red' }}>
                  For some reason your subscription is not active<br />
                  Please contact our support.
                </div>
              )
              }

              {inProgress && (
                <div style={{ fontSize: 24 / mult + 'px' }}>
                  Searching... Processed {processed} of {usedmediaquota} search images.<br />
                  {numberImagesFound === 1 ? (<>1 result found.</>) : (<>{numberImagesFound} results found.</>)}
                </div>
              )
              }
              {finished && (
                <div style={{ fontSize: 24 / mult + 'px' }}>
                  Search By Demand launched.<br />
                  When it is finished, we will notify you by email or/and SMS.
                </div>
              )
              }
              {(!finished && !inProgress && nextTimeFlag) && (
                <span style={{ fontSize: 24 / mult + 'px' }}>{text}</span>
              )
              }
              {(!nextTimeFlag) && (
                <span style={{ fontSize: 24 / mult + 'px' }}>Next search can be executed at {nextTime} or later</span>
              )
              }

              <div style={{ width: 600 / mult, height: 108 / mult, fontSize: 15 / mult + 'px' }}>
              </div>


            </div>
            <div className="t-emptytraahdbox-buttons" style={{ width: 600 / mult, height: 160 / mult }}>
              {(finished || subscriptionNotActive || !nextTimeFlag) ? (
                <EmptyButton nav={buttoncancel} title={"Continue"} id={""} flex={true} />
              ) : (
                <EmptyButton nav={buttoncancel} classname={"emptybuttongray"} title={"Cancel"} id={""} flex={true} />
              )}
              {(!inProgress && !finished && nextTimeFlag) && (
                <>
                  <div style={{ width: 100 / mult, height: 50 / mult }}>
                  </div>
                  &nbsp;&nbsp;<EmptyButton nav={ClickRunSearch} title={"Yes, run the search."} id={""} flex={true} />
                </>
              )}

            </div>
          </>
        }
      </div>
    );
  }
}


export default withAuth(Portfolio);