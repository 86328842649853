import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import BigMsg from "../Misc/BigMsg";
import { useConfirmEmailMutation } from "../../Apis/authApi";
import { apiResponse, userModel } from "../../Interfaces";


function EmailConfirmed() {

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  let token = searchParams.get("token");
  if (token !== null) token = token.replace(/\s/g, '+');
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [confirmEmail] = useConfirmEmailMutation();
  const [message, setMessage] = useState("Please wait...");

  const confirmEmail2 = async () => {

    const response: apiResponse = await confirmEmail(
      {
        email: email,
        token: token,
      });

    console.log("response");
    console.log(response);
    if (response.data) {
      if (response.data.is_success) {
        setMessage("Thank you! Your email confirmed. Please Log In.");
      } else {
        setMessage("Something went wrong");
      }
    }
    if (response.error) {
      setMessage("Error : " + response.error.data.error_messages[0]);
    }
  }

  useEffect(() => {
    confirmEmail2()
  }, []);

  const RedirectToLogin = () => {
    navigate("/Login");
  }
  const title = "Sign Up";
  const subtitle = "";
  //const message = "Thank you! Your email confirmed. Please Log In.";
  const inputs = [
    { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
    { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
    { "toptext": "", "type": "", "id": "", "value": "", "handler": () => true },
  ]
  const buttons = [
    { "handler": RedirectToLogin, "title": "Continue" },
    { "handler": () => true, "title": "" },
    { "handler": () => true, "title": "" },
  ]

  return (
    <BigMsg title={title} subtitle={subtitle} message={message} error={error} inputs={inputs} buttons={buttons} />
  );

}

export default EmailConfirmed;