import { createSlice } from "@reduxjs/toolkit";
export const initialState: any = {
    sortMenuState: 2
};

export const varSlice = createSlice({
    name: "var",
    initialState: initialState,
    reducers: {
        setSortMenuState: (state, action) => {
            state.sortMenuState = action.payload;
        },

    },
});

export const { setSortMenuState } = varSlice.actions;
export const varReducer = varSlice.reducer;