import React, { useState, useEffect } from "react";
import { useRegisterUserMutation } from "../../Apis/authApi";
import { inputHelper } from "../helper";
import { apiResponse } from "../../Interfaces";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import '../../../src/CSS/global.css';
import '../../../src/CSS/loginandreg.css';
import EmptyButton from '../Misc/EmptyButton';
import MiniLoader from "../Misc/MiniLoader";
import { W0, W1, W2 } from "../../functions";

function CreateTrial() {
  const [registerUser] = useRegisterUserMutation();
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState({
    userName: "",
    password: "",
    password2: "",
    role: "",
    name: "",
  });

  const handleUserInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const tempData = inputHelper(e, userInput);
    setUserInput(tempData);
    setError("");
  };

  function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(userInput.userName))) {
      setError("Error: You have entered an invalid email address!");
    } else if (userInput.password !== userInput.password2) {
      setError("Error: Password mismatch");
    } else if (userInput.password === "") {
      setError("Error: Password field is empty");
    } else {
      setIsLoading(true);
      const response: apiResponse = await registerUser({
        login: userInput.userName,
        password: userInput.password,
        role: "admin",
        name: userInput.userName,
      });
      setIsLoading(false);
      if (response.data) {
        navigate("/ConfirmEmail");
      } else if (response.error) {
        setError("Error: " + response.error.data);
      }

    }
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  let mult = 1;
  if (windowWidth > W0) {
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (
      <>
        <div className="maincontainer2 colorcccccc">
          <div style={{ marginTop: 90, width: 1337 / mult, height: 600 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
            <div className="createtrial-box " style={{ width: 1240 / mult, height: 560 / mult }}>
              <div className="createtrial-title loginfont" style={{ width: 1100 / mult, height: 110 / mult, fontSize: 17 / mult, fontWeight: 500 / mult }}>
                <span className='createtrial-bigtitle colord9d9d9' style={{ fontSize: 40 / mult }}>Registration </span><br />
                Free 30-day trial<br />
                <span className="login-errormessage">{error}&nbsp;&nbsp;</span>
              </div>
              <div className="createtrial-input" style={{ width: 645 / mult, height: 70 / mult }}>
                <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Email Address</span>
                <input
                  type="email"
                  id="createtrial-email"
                  name="userName"
                  value={userInput.userName}
                  onChange={handleUserInput}
                  size={50}
                  style={{ outline: 'none', width: 640 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                  required />
              </div>
              <div className="createtrial-input" style={{ width: 645 / mult, height: 70 / mult, marginBottom: '20px' }}>
                <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Password</span>
                <input
                  type="password"
                  id="createtrial-password"
                  name="password"
                  value={userInput.password}
                  onChange={handleUserInput}
                  size={50}
                  style={{ outline: 'none', width: 640 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                  required />
              </div>

              <div className="createtrial-input" style={{ width: 645 / mult, height: 70 / mult, marginBottom: '20px' }}>
                <span className="createtrial-inputtitles loginfont" style={{ marginLeft: 20 / mult, fontSize: 17 / mult, fontWeight: 400 / mult }}>Confirm Password</span>
                <input
                  type="password"
                  id="createtrial-password2"
                  name="password2"
                  value={userInput.password2}
                  onChange={handleUserInput}
                  size={50}
                  style={{ outline: 'none', width: 640 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                  required />
              </div>


              {loading ? (<MiniLoader />) :
                (
                  <EmptyButton nav={handleSubmit} title={"Submit"} id={""} />
                )
              }
            </div>
          </div>
        </div>

      </>
    );
  } else {
    mult = W0 / windowWidth;
    return (
      <>
        <div className="maincontainer2 colorcccccc">
          <div className="createtrial-box-mob" style={{ width: '100%', height: '100%' }}>
            <div className="createtrial-title loginfont" style={{ textAlign: 'center', width: '100%', height: 110 / mult, fontSize: 17 / mult, fontWeight: 500 / mult }}>
              <span className='createtrial-bigtitle colord9d9d9' style={{ fontSize: 40 / mult }}>Registration </span><br />
              Free 30-day trial<br />
              <span className="login-errormessage">{error}&nbsp;&nbsp;</span>
            </div>
            <div className="createtrial-input" style={{ width: 350 / mult, height: 70 / mult, marginBottom: '10px' }}>
              <span className="createtrial-inputtitles loginfont" style={{ fontSize: 17 / mult, fontWeight: 400 / mult }}>Email Address</span>
              <input
                type="email"
                id="createtrial-email"
                name="userName"
                value={userInput.userName}
                onChange={handleUserInput}
                size={50}
                style={{ outline: 'none', width: 340 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                required />
            </div>
            <div className="createtrial-input" style={{ width: 350 / mult, height: 70 / mult, marginBottom: '10px' }}>
              <span className="createtrial-inputtitles loginfont" style={{ fontSize: 17 / mult, fontWeight: 400 / mult }}>Password</span>
              <input
                type="password"
                id="createtrial-password"
                name="password"
                value={userInput.password}
                onChange={handleUserInput}
                size={50}
                style={{ outline: 'none', width: 340 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult }}
                required />
            </div>

            <div className="createtrial-input" style={{ width: 350 / mult, height: 70 / mult, marginBottom: '20px' }}>
              <span className="createtrial-inputtitles loginfont" style={{ fontSize: 17 / mult, fontWeight: 400 / mult }}>Confirm Password</span>
              <input
                type="password"
                id="createtrial-password2"
                name="password2"
                value={userInput.password2}
                onChange={handleUserInput}
                size={50}
                style={{ outline: 'none', width: 340 / mult, height: 30 / mult, fontSize: 20 / mult, fontWeight: 300 / mult, margin: '0px' }}
                required />
            </div>


            {loading ? (<MiniLoader />) :
              (

                <EmptyButton nav={handleSubmit} title={"Submit"} id={""} />

              )
            }
          </div>
        </div>
      </>
    );
  }

}

export default CreateTrial;