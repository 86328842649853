import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import { useState, useEffect, useRef } from 'react';
import CreateAccountI from "./CreateAccountI";
import CreateAccountB from "./CreateAccountB";
import { useSelector, useDispatch } from "react-redux";
import { accountModel, userBigModel } from "../../Interfaces";
import { userModel } from "../../Interfaces";
import { RootState } from "../../storage/redux/store";
import {
  setSbscAccType,
  setSbscUsers,
  setSbscUserNumber,
  setSbscSwap,
  setSbscMediaQuota,
  setSbscCost,
  setSbscCompanyName,
  setSbscCompanyAddress,
  setSbscObject
} from "../../storage/redux/slice/subscriptionSlice";
import { useGetSubscriptionDetailsQuery } from "../../Apis/authApi";
import { v4 as uuid } from 'uuid';
import { withAuth } from '../../HOC';
import { W0, W1, W2 } from "../../functions";

function CreateAccount() {
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );

  const dispatch = useDispatch();

  const subscriptionData: accountModel = useSelector(
    (state: RootState) => state.subscriptionStore
  );
  let temp;

  if (subscriptionData.accountType === 1) {
    temp = true;
  }
  if (subscriptionData.accountType === 2) {
    temp = false;
  }
  const [isbuz, setIsbuz] = useState(temp);

  /*****************Load subscr redux*********************************/
  const usersubscription: any = useGetSubscriptionDetailsQuery(userData.id);

  useEffect(() => {
    if (usersubscription.data !== undefined) {

      let users: Array<userBigModel> = [];

      let bigUser: userBigModel

      /*notifications*/
      let notificationWeb = true;
      let notificationEmail = false;
      let notificationSMS = false;
      switch (usersubscription.data.AppSubscriptionUsers[0].NotificationType) {
        case 2:
          notificationSMS = true;
          break;
        case 3:
          notificationEmail = true;
          break;
        case 4:
          notificationEmail = true;
          notificationSMS = true;
          break;
      }

      /*frequency add-on*/
      let notificationAddOn = usersubscription.data.AppSubscriptionUsers[0].FrequencyAddOn;

      /*frequency*/
      let frequencyOnDemand = false;
      let frequency3Times = false;
      let frequencyDaily = false;
      let frequencyWeekly = false;
      let frequencyMonthly = false;

      switch (usersubscription.data.AppSubscriptionUsers[0].FrequencyType) {
        case 1:
          frequencyMonthly = true;
          break;
        case 2:
          frequencyWeekly = true;
          break;
        case 3:
          frequencyDaily = true;
          break;
        case 4:
          frequency3Times = true;
          break;
        case 5:
          frequencyOnDemand = true;
          break;
      }
      //const userTempId = CreateSmallID();   
      bigUser = {
        email: userData.email,
        /*lastName:element.lastName,
        firstName:element.firstName,
        phoneNumber:element.phoneNumber,
        twoFactorEnabled:element.two_factor_enabled,
        emailConfirmed:element.email_confirmed,
        phoneNumberConfirmed:element.phone_number_confirmed,*/
        password: "",
        confpassword: "",
        notificationAddOn: notificationAddOn,
        notificationWeb: notificationWeb,
        notificationEmail: notificationEmail,
        notificationSMS: notificationSMS,
        frequencyOnDemand: frequencyOnDemand,
        frequency3Times: frequency3Times,
        frequencyDaily: frequencyDaily,
        frequencyWeekly: frequencyWeekly,
        frequencyMonthly: frequencyMonthly,
        userStatus: usersubscription.data.AppSubscriptionUsers[0].ApplicationUser.UserStatus,
        primaryUser: true,
        role: userData.role,
        userTempId: usersubscription.data.AppSubscriptionUsers[0].ApplicationUser.Id

      };

      users.push(bigUser);

      dispatch(setSbscUsers(users));
      dispatch(setSbscUserNumber(users.length));
      dispatch(setSbscSwap(usersubscription.data.Swap));
      dispatch(setSbscCost(usersubscription.data.Cost));
      dispatch(setSbscAccType(usersubscription.data.AppSubscriptionType));
      dispatch(setSbscCompanyName(""));
      dispatch(setSbscCompanyAddress(""));
      dispatch(setSbscMediaQuota(usersubscription.data.SearchMediaQuota));

    }
  }, [usersubscription.data, isbuz]);

  /*****************END OF Load subscr redux*********************************/


  /*const [checkrbutton, setCheckRButton] = useState(false);
  const checkrbuttonhandler = () => {
    setCheckRButton(!checkrbutton)
  };*/

  useEffect(() => {
    let temp1 = document.getElementById('iconisbuz1')!;
    let temp2 = document.getElementById('iconisbuz2')!;
    if (isbuz) {
      temp2.style.display = 'block';
      temp1.style.display = 'none';
    } else {
      temp1.style.display = 'block';
      temp2.style.display = 'none';
    }
  });

  /************* Switch Ind/Bus Subscription************************/
  const switchSmallLargeIcons = () => {
    let temp1 = document.getElementById('iconisbuz1')!;
    let temp2 = document.getElementById('iconisbuz2')!;
    if (isbuz) {
      temp1.style.display = 'block';
      temp2.style.display = 'none';
    } else {
      temp2.style.display = 'block';
      temp1.style.display = 'none';
    }
    setIsbuz(!isbuz);
  }
  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  let mult = 1;
  if (windowWidth < W1) { mult = W1 / windowWidth; }
  if (windowWidth < W2) { mult = W1 / W2; }
  /******************************************************************************/
  if (isbuz) { dispatch(setSbscAccType(1)); } else { dispatch(setSbscAccType(2)); }
  if (windowWidth > W0) {
    return (
      <>
        <div className="maincontainer2 colore6e6e6">
          <div style={{ width: 1320 / mult, height: 860 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 100, marginTop: 100 }}>
            <div className="ca-mainbox" style={{ width: 1300 / mult, height: 800 / mult }}>

              <div className="ca-top" style={{ marginBottom: 5, width: 1100 / mult, height: 150 / mult, fontWeight: 600, fontSize: 35 / mult + 'px', letterSpacing: '1.5px' }}>

                <div className="ca-top-left" style={{ width: 600 / mult, height: 90 / mult }}>
                  New Subscription
                </div>
                <div className="ca-top-right1" id="iconisbuz1" onClick={switchSmallLargeIcons} style={{ width: 510 / mult, height: 67 / mult }}>
                </div>
                <div className="ca-top-right2" id="iconisbuz2" onClick={switchSmallLargeIcons} style={{ width: 510 / mult, height: 67 / mult }}>
                </div>
              </div>
              <div className=".container-l-l-column" style={{ margin: 0, width: 1100 / mult, height: 620 / mult, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>

                {isbuz ?
                  (<CreateAccountB subscriptionData={subscriptionData} />)
                  :
                  (<CreateAccountI subscriptionData={subscriptionData} />)
                }
              </div>

            </div>
          </div>

        </div>
      </>
    );
  } else {
    // Mobile *************************************************************
    mult = W0 / windowWidth;
    return (
      <>
        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>
              New Subscription
            </span>
          </div>
          <div className="main_div_mob" style={{ width: 550 / mult, backgroundColor: '#494b53', border: '2px solid #a6abb9' }}>
            <div style={{ width: 500 / mult, marginBottom: 30, paddingTop: 20 }}>
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div className="ca-top-right1" id="iconisbuz1" onClick={switchSmallLargeIcons} style={{ width: 414 / mult, height: 54 / mult }}>
                </div>
                <div className="ca-top-right2" id="iconisbuz2" onClick={switchSmallLargeIcons} style={{ width: 414 / mult, height: 54 / mult }}>
                </div>
              </div>
              <div style={{ margin: 0, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>
                {isbuz ?
                  (<CreateAccountB subscriptionData={subscriptionData} />)
                  :
                  (<CreateAccountI subscriptionData={subscriptionData} />)
                }
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}

export default withAuth(CreateAccount);
