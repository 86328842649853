import { v4 as uuid } from 'uuid';
import {
  setSbscUserNumber_pricecode,
  setSbscMediaQuota_pricecode,
  setSbscNotificationAddOn_pricecode,
  setSbscNotificationSMS_pricecode,
  setSbscFrequencyOnDemand_pricecode,
  setSbscFrequency3Times_pricecode,
  setSbscFrequencyDaily_pricecode,
  setSbscFrequencyWeekly_pricecode,
  setSbscFrequencyMonthly_pricecode,
  setSbscNotificationEmail_pricecode,
} from "./storage/redux/slice/subscriptionSlice";

export const W0 = 600;
export const W1 = 1340;
export const W2 = 1200;

//export const baseUrl = "https://localhost:7251/";
//export const baseUrl = "https://appapi.dejavu.art/";
export const baseUrl = "https://apitest.dejavu.art/";
//export const baseUrl = "https://api.dejavu.art/";  


export function CreateSmallID() {
  const unique_id = uuid();
  return unique_id.slice;
}

export function BytesTo64(buffer) {
  const binaryString = Array.from(new Uint8Array(buffer), byte => String.fromCharCode(byte)).join("");
  return btoa(binaryString);
}

export function CreateSubscriptionRequest(subscriptionData, f_byd, f_3t, f_d, f_m, f_w, n_e, n_sms, ondemandaddon) {
  const usernumber_priceid = subscriptionData.userNumber_pricecode;
  const medianumber_priceid = subscriptionData.mediaQuota_pricecode;
  const f_byd_priceid = subscriptionData.frequencyOnDemand_pricecode;
  const f_3t_priceid = subscriptionData.frequency3Times_pricecode;
  const f_d_priceid = subscriptionData.frequencyDaily_pricecode;
  const f_m_priceid = subscriptionData.frequencyMonthly_pricecode;
  const f_w_priceid = subscriptionData.frequencyWeekly_pricecode;
  const n_e_priceid = subscriptionData.notificationEmail_pricecode;
  const n_sms_priceid = subscriptionData.notificationSMS_pricecode;
  const ondemandaddon_priceid = subscriptionData.notificationAddOn_pricecode;

  let usernumber = subscriptionData.users?.length;
  let medianumber = subscriptionData.mediaQuota;

  let item;
  let reqbody = [];
  item = {
    "priceId": usernumber_priceid,
    "quantity": usernumber
  }
  reqbody.push(item);

  item = {
    "priceId": medianumber_priceid,
    "quantity": medianumber
  }
  reqbody.push(item);

  if (f_byd > 0) {
    item = {
      "priceId": f_byd_priceid,
      "quantity": f_byd
    }
    reqbody.push(item);
  }
  if (f_3t > 0) {
    item = {
      "priceId": f_3t_priceid,
      "quantity": f_3t
    }
    reqbody.push(item);
  }
  if (ondemandaddon > 0) {
    item = {
      "priceId": ondemandaddon_priceid,
      "quantity": ondemandaddon
    }
    reqbody.push(item);
  }
  if (f_d > 0) {
    item = {
      "priceId": f_d_priceid,
      "quantity": f_d
    }
    reqbody.push(item);
  }
  if (f_m > 0) {
    item = {
      "priceId": f_m_priceid,
      "quantity": f_m
    }
    reqbody.push(item);
  }
  if (f_w > 0) {
    item = {
      "priceId": f_w_priceid,
      "quantity": f_w
    }
    reqbody.push(item);
  }
  if (n_e > 0) {
    item = {
      "priceId": n_e_priceid,
      "quantity": n_e
    }
    reqbody.push(item);
  }
  if (n_sms > 0) {
    item = {
      "priceId": n_sms_priceid,
      "quantity": n_sms
    }
    reqbody.push(item);
  }
  return JSON.stringify(reqbody);
}


export function CalculateCost(productandpricedetail, subscriptionData, dispatch, setSbscCost, setPriceCodes = null, lookup_key_prefix = "current") {
  if (lookup_key_prefix === "") { lookup_key_prefix = "current"; }

  if (productandpricedetail.data !== undefined) {

    let totalcost = 0;

    for (let price of productandpricedetail.data.prices) {
      if (price.lookup_key === lookup_key_prefix + "_userplan") {
        for (let element of price.tiers) {
          let up_to = element.up_to;
          if (element.up_to === null) { up_to = 1000000; }
          if (subscriptionData.userNumber <= up_to) {
            totalcost += subscriptionData.userNumber * element.unit_amount;
            break;
          }
        }
        dispatch(setSbscUserNumber_pricecode(price.id));
      }

      if (price.lookup_key === lookup_key_prefix + "_mediaquota") {
        for (let element of price.tiers) {
          let up_to = element.up_to;
          if (element.up_to === null) { up_to = 1000000; }
          if (subscriptionData.mediaQuota <= up_to) {
            totalcost += subscriptionData.mediaQuota * element.unit_amount;
            break;
          }
        }
        dispatch(setSbscMediaQuota_pricecode(price.id));
      }

      /*
      for (let price of productandpricedetail.data.prices) {
        if (price.lookup_key === lookup_key_prefix + "_userplan") {
          for (let element of price.tiers) {
            let up_to = element.up_to;
            if (element.up_to === null) { up_to = 1000000; }
            if (subscriptionData.userNumber <= up_to) {
              totalcost += subscriptionData.userNumber * element.unit_amount;
              break;
            }
          }
          dispatch(setSbscUserNumber_pricecode(price.id));
        }
  
        if (price.lookup_key === lookup_key_prefix + "_mediaquota") {
          for (let element of price.tiers) {
            let up_to = element.up_to;
            if (element.up_to === null) { up_to = 1000000; }
            if (subscriptionData.mediaQuota <= up_to) {
              totalcost += subscriptionData.mediaQuota * element.unit_amount;
              break;
            }
          }
          dispatch(setSbscMediaQuota_pricecode(price.id));
        }
        */

      if (price.lookup_key === lookup_key_prefix + "_ondemandaddon") {
        for (let element of subscriptionData.users) {
          if (element.notificationAddOn) { totalcost += price.unit_amount }
        }
        dispatch(setSbscNotificationAddOn_pricecode(price.id));
      }
      if (price.lookup_key === lookup_key_prefix + "_notificationbysms") {
        for (let element of subscriptionData.users) {
          if (element.notificationSMS) { totalcost += price.unit_amount }
        }
        dispatch(setSbscNotificationSMS_pricecode(price.id));
      }
      if (price.lookup_key === lookup_key_prefix + "_notificationbyemail") {
        for (let element of subscriptionData.users) {
          if (element.notificationEmail) { totalcost += price.unit_amount }
        }
        dispatch(setSbscNotificationEmail_pricecode(price.id));
      }
      if (price.lookup_key === lookup_key_prefix + "_searchmonthly") {
        for (let element of subscriptionData.users) {
          if (element.frequencyMonthly) { totalcost += price.unit_amount }
        }
        dispatch(setSbscFrequencyMonthly_pricecode(price.id));
      }
      if (price.lookup_key === lookup_key_prefix + "_searchweekly") {
        for (let element of subscriptionData.users) {
          if (element.frequencyWeekly) { totalcost += price.unit_amount }
        }
        dispatch(setSbscFrequencyWeekly_pricecode(price.id));
      }
      if (price.lookup_key === lookup_key_prefix + "_searchdaily") {
        for (let element of subscriptionData.users) {
          if (element.frequencyDaily) { totalcost += price.unit_amount }
        }
        dispatch(setSbscFrequencyDaily_pricecode(price.id));
      }
      if (price.lookup_key === lookup_key_prefix + "_search3timesday") {
        for (let element of subscriptionData.users) {
          if (element.frequency3Times) { totalcost += price.unit_amount }
        }
        dispatch(setSbscFrequency3Times_pricecode(price.id));
      }
      if (price.lookup_key === lookup_key_prefix + "_searchondemand") {
        for (let element of subscriptionData.users) {
          if (element.frequencyOnDemand) { totalcost += price.unit_amount }
        }
        dispatch(setSbscFrequencyOnDemand_pricecode(price.id));
      }

    }
    dispatch(setSbscCost(totalcost));

  }
}