import '../CSS/global.css';
import '../CSS/pages.css';
import '../CSS/trash.css';
import { useState, useEffect, useRef } from 'react';
import { useScrollbar } from '../Hooks/use-scrollbar-y';
import { Link } from "react-router-dom";
import { W0, W1, W2 } from "../functions";


function News() {

  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /**************** Scroll bar  things*********************************/
  const termsWrapper = useRef(null);
  const hasScroll = true;

  useScrollbar(termsWrapper, hasScroll, windowWidth);
  let mult = 1;
  if (windowWidth > 600) {
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (

      <div className="maincontainer2">
        <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="pg-mainbox" style={{ width: 1320 / mult, height: 690 / mult }}>
            <div className="pg-mainbox-top" style={{ width: 1250 / mult, height: 80 / mult }}>
              <span className="pg-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px', marginBottom: 20 / mult }}>News & Updates</span>
            </div>
            <div className="pg-mainbox-media" style={{ width: 1230 / mult, height: 597 / mult }}>
              <div style={{ width: 1100 / mult, paddingRight: '12px', height: 450 / mult, minHeight: 450 / mult, marginBottom: 100 / mult }} ref={termsWrapper}>
                <div className="" style={{ height: 1000 / mult, fontSize: 16, marginRight: 50 / mult }}>
                  <NewsText />
                </div>
              </div >
            </div >
          </div >
        </div >
      </div >
    );
  } else {
    // Mobile *************************************************************

    mult = 600 / windowWidth;
    const mult1 = 3;
    return (
      <>
        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>News & Updates</span>
          </div>
          <div className="main_div_mob" style={{ width: 550 / mult }}>
            <div style={{ width: 500 / mult }}>
              <NewsText />
            </div>
          </div>
        </div >
      </>
    );
  }

}

function NewsText() {
  return (
    <div style={{ fontWeight: 300, letterSpacing: '1px', fontSize: 20 }}>

    </div>

  );
}

export default News;