import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../functions";

const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["DetailsByUser", "SubscrDetails"],
  endpoints: (builder) => ({
    createSubscriptionOnServer: builder.mutation({
      query: (req) => ({
        url: `accounts/createsubscriptiononserver?userid=${req.userid}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: req.sb,
      }),
      invalidatesTags: ["DetailsByUser"],
    }),
    registerUser: builder.mutation({
      query: (userData) => ({
        url: "auth/register",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userData,
      }),
    }),
    contactUs: builder.mutation({
      query: (contactUsData) => ({
        url: "auth/contactus",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: contactUsData,
      }),
    }),
    loginUser: builder.mutation({
      query: (userCredentials) => ({
        url: "auth/login",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: userCredentials,
      }),
      invalidatesTags: ["DetailsByUser"],
    }),
    getDetailsByUser: builder.query({
      query: (userid) => ({
        url: `accounts/detailsbyuser/${userid}`,
      }),
      providesTags: ["DetailsByUser"],
    }),
    getSubscriptionDetails: builder.query({
      query: (userid) => ({
        url: `accounts/subscriptiondetails/${userid}`,
      }),
      providesTags: ["SubscrDetails"],
    }),
    getUserListByUser: builder.query({
      query: (userid) => ({
        url: `accounts/userlistbyuser/${userid}`,
      }),
      providesTags: ["DetailsByUser"],
    }),
    checkEmails: builder.mutation({
      query: (emaillist) => ({
        url: `auth/checkemails`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: emaillist,
      }),
    }),
    activateNewSubscription: builder.mutation({
      query: (userid) => ({
        url: `accounts/activatenewsubscription`,
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json"
        },
        body: userid,
      }),
    }),
    cancelSubscription: builder.mutation({
      query: (userid) => ({
        url: `accounts/cancelsubscription`,
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json"
        },
        body: userid,
      }),
      invalidatesTags: ["DetailsByUser", "SubscrDetails"],
    }),
    updateUser: builder.mutation({
      query: (q) => ({
        url: `accounts/updateuser`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: q,
      }),
      invalidatesTags: ["DetailsByUser", "SubscrDetails"],
    }),
    /******Forgot Password API***********/
    sendFPEmail: builder.mutation({
      query: (r) => ({
        url: `auth/forgot-password/?email=${r}`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: "",
      }),
      invalidatesTags: ["DetailsByUser"],
    }),

    sendConfCode: builder.mutation({
      query: ({ code, username }: any) => ({
        url: `auth/login2fa?code=${code}&username=${username}`,
        method: "POST",
        headers: {
          "Content-type": "text/plain",
        },
        body: "",
      }),
      invalidatesTags: ["DetailsByUser"],
    }),

    confirmEmail: builder.mutation({
      query: (r) => ({
        url: `auth/confirmemail`,
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: r,
      }),
      invalidatesTags: ["DetailsByUser"],
    }),

    sendNewPassword: builder.mutation({
      query: (r) => ({
        url: "auth/reset-password",
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: r,
      }),
      invalidatesTags: ["DetailsByUser"],
    }),
    /******END OF Forgot Password API*****/
  }),
});

export const {
  useConfirmEmailMutation,
  useSendConfCodeMutation,
  useRegisterUserMutation,
  useLoginUserMutation,
  useGetDetailsByUserQuery,
  useSendFPEmailMutation,
  useSendNewPasswordMutation,
  useCheckEmailsMutation,
  useCreateSubscriptionOnServerMutation,
  useActivateNewSubscriptionMutation,
  useGetSubscriptionDetailsQuery,
  useUpdateUserMutation,
  useCancelSubscriptionMutation,
  useContactUsMutation
} = authApi;
export default authApi;