import React from "react";

function ButtonTemplateGrayWithWhiteText({ mult, id, children, minibuttons = false }) {
    let t = -44;
    if (minibuttons) { t = -50; }
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={300 / mult}
                height={55 / mult}
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 43.136 8.448"
            >
                <g transform="translate(-68.565 -132.761)">
                    <rect
                        id={id}
                        width="43.136"
                        height="8.448"
                        x="68.565"
                        y="132.761"
                        fill="#a6abb9"
                        fillRule="evenodd"
                        stroke="#000"
                        strokeLinecap="square"
                        strokeLinejoin="bevel"
                        strokeOpacity="0"
                        strokeWidth="0"
                        rx="4.254"
                        ry="4.224"
                    ></rect>
                </g>
            </svg>
            <span id={"span" + id} style={{ height: 0, zIndex: 300, position: "relative", top: t / mult, color: 'white' }} >{children}</span>
        </>

    );
}

export default ButtonTemplateGrayWithWhiteText;