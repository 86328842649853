import React, { useState, useEffect } from "react";
import '../../CSS/global.css';
import ButtonTemplateBlueWithWhiteText from "../../assets/svgtojsx/ButtonTemplateBlueWithWhiteText";
import ButtonTemplateGrayWithWhiteText from "../../assets/svgtojsx/ButtonTemplateGrayWithWhiteText";
import ButtonRedWithWhiteText from "../../assets/svgtojsx/ButtonRedWithWhiteText";
import ButtonTemplateArt from "../../assets/svgtojsx/ButtonTemplateArt";

import ContinueButton from "../../assets/svgtojsx/ContinueButton";

function EmptyButton({ nav, title, id, classname = "emptybutton", flex = false, minibuttons = false }) {

  const id1 = "span" + id;
  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  let mult1 = 1;
  if (windowWidth < 1440) { mult1 = 1440 / windowWidth; }
  if (windowWidth < 1380) { mult1 = 1440 / 1380; }
  if (windowWidth < 600) { mult1 = 1.5; }
  if (flex) {
    mult1 = 2000 / windowWidth;
    if (windowWidth < 1380) {
      mult1 = 1.5;
    }
  }
  let fs = 20;
  if (minibuttons) {
    if (windowWidth < 600) { mult1 = 1200 / windowWidth; }
    fs = 30;
  }

  //if (windowWidth < 600) { mult1 = 600 / windowWidth; }
  if (classname === "emptybuttonred") {
    return (
      <div className="emptybluebutton" id={id} onClick={nav} style={{ fontSize: fs / mult1 + 'px', margin: 0 / mult1 + 'px' }}>
        <ButtonRedWithWhiteText mult={mult1} id={"redbutton" + id} children={title} minibuttons={minibuttons} />
      </div>
    );
  }
  if (classname === "emptybuttongray") {
    return (
      <div className="emptybluebutton" id={id} onClick={nav} style={{ fontSize: fs / mult1 + 'px', margin: 0 / mult1 + 'px' }}>
        <ButtonTemplateGrayWithWhiteText mult={mult1} id={"graybutton" + id} children={title} minibuttons={minibuttons} />
      </div>
    );
  }
  if (classname === "continuebutton") {
    return (
      <div className="emptybluebutton" id={id} onClick={nav} style={{ fontSize: fs / mult1 + 'px', margin: 0 / mult1 + 'px' }}>
        <ContinueButton mult={mult1} id={"continuebutton" + id} children={""} minibuttons={minibuttons} />
      </div>
    );
  }

  if (classname === "emptybuttonart") {
    return (
      <div className="emptybluebutton" id={id} onClick={nav} style={{ fontSize: fs / mult1 + 'px', margin: 0 / mult1 + 'px' }}>
        <ButtonTemplateArt mult={mult1} id={"continuebutton" + id} children={title} minibuttons={minibuttons} />
      </div>
    );
  }

  return (
    <div className="emptybluebutton" id={id} onClick={nav} style={{ fontSize: fs / mult1 + 'px', margin: 0 / mult1 + 'px' }}>
      <ButtonTemplateBlueWithWhiteText mult={mult1} id={"bluebutton" + id} children={title} minibuttons={minibuttons} />
    </div>
  );
}

export default EmptyButton; 