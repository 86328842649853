import React from "react";

function TrashFullMob({ mult }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={28 / mult}
            height={42 / mult}
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 5.413 8.768"
        >
            <g transform="translate(-218.852 -160.792)">
                <path
                    fill="#494b53"
                    fillOpacity="1"
                    strokeWidth="0.469"
                    d="M221.514 160.792l-1.99 1.922.745.77c.244.06.514.099.792.118l-.995-1.03 1.459-1.409v.001l.005-.005 1.75 1.813-.496.48c.07-.02.136-.042.2-.065.342-.124.545-.28.608-.442zm-1.445.183l-.199 1.252.33-.319.103-.65.49.078.218-.211zm2.16.342l.28.29.283.046-.034.213.253.263.107-.67zm-.682.426a.208.208 0 10.005 0h-.006zm1.918.644a.62.62 0 01.19.32c.29.287.118.622-.517.853-.872.317-2.286.317-3.158 0-.622-.226-.8-.552-.535-.835a.605.605 0 01.184-.328c-1.04.424-1.036 1.106.016 1.527 1.057.423 2.77.423 3.828 0 1.057-.423 1.057-1.11 0-1.533l-.008-.003zm-2.067.07l-.68.334.887.887c.1-.005.201-.012.3-.022l.08-.188.275-.522-.44.014-.046-.34-.402.196zm-1.666.646l-.029.177a2.05 2.05 0 00.327.132zm-.88.31l.002.025a.33.33 0 01.002-.024zm5.413 0v.005-.004zm-5.402.134l.416 5.177h.01c.012.213.233.424.665.587.887.333 2.325.333 3.212 0 .49-.184.709-.431.658-.672l.429-5.067c-.063.238-.322.47-.778.653-1.057.423-2.77.423-3.827 0-.472-.19-.733-.43-.784-.678z"
                    display="inline"
                ></path>
            </g>
        </svg>
    );
}

export default TrashFullMob;
