import { useState, useEffect } from "react";
import '../../../src/CSS/global.css';
import '../../../src/CSS/bigmsg.css';
import '../../../src/CSS/loginandreg.css';
import { W0, W1, W2 } from "../../functions";
//import { W2 } from "../../functions";
import EmptyButton from '../Misc/EmptyButton';


function BigMsg({ title, subtitle, message, error, inputs, buttons }: any) {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    /*  let mult = 1;
      let m = 1.138;
      mult = 1920/windowWidth;
    */
    let mult = 1;
    if (windowWidth > W0) {
        if (windowWidth < W1) { mult = W1 / windowWidth; }
        if (windowWidth < W2) { mult = W1 / W2; }
        return (
            <>
                <div className="maincontainer2 colorcccccc">
                    <div style={{ marginTop: 90, width: 1340 / mult, height: 610 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
                        <div className="bigmsg-box" style={{ width: 1320 / mult, height: 573 / mult }}>
                            <div className="bigmsg-box-top loginfont" style={{ width: 1100 / mult, height: 165 / mult, fontWeight: 500 / mult }}>
                                <span className='bigmsg-bigtitle colord9d9d9' style={{ fontSize: 40 / mult }}>{title}</span>
                                <div className="bigmsg-box-top" style={{ width: 1200 / mult, height: 30 / mult }}>
                                    <span className='bigmsg-subtitle colord9d9d9' style={{ fontSize: 20 / mult }}>{subtitle}</span>
                                </div>
                                <div className="bigmsg-box-top" style={{ width: 1200 / mult, height: 30 / mult }}>
                                    <span className="bigmsg-subtitle" style={{ fontSize: 20 / mult, color: 'red' }}>{error}</span>
                                </div>
                            </div>
                            <div className="bigmsg-box-middle" style={{ width: 1200 / mult, height: 390 / mult, fontSize: 20 / mult, marginTop: -20 }}>
                                {message && <span className='bigmsg-subtitle colore6e6e6' style={{ fontSize: 30 / mult, padding: 100 / mult }}>{message}</span>}
                                {inputs[0].toptext && < BigMsgInput toptext={inputs[0].toptext} type={inputs[0].type} id={inputs[0].id} value={inputs[0].value} handler={inputs[0].handler} mult={mult} />}
                                {inputs[1].toptext && < BigMsgInput toptext={inputs[1].toptext} type={inputs[1].type} id={inputs[1].id} value={inputs[1].value} handler={inputs[1].handler} mult={mult} />}
                                {inputs[2].toptext && < BigMsgInput toptext={inputs[2].toptext} type={inputs[2].type} id={inputs[2].id} value={inputs[2].value} handler={inputs[2].handler} mult={mult} />}
                                <div className="bigmsg-box-buttons" style={{ width: 1200 / mult, height: 130 / mult, marginTop: 30 / mult }}>
                                    {buttons[0].title && <EmptyButton nav={buttons[0].handler} title={buttons[0].title} id={""} />}
                                    {buttons[1].title && <EmptyButton nav={buttons[1].handler} title={buttons[1].title} id={""} />}
                                    {buttons[2].title && <EmptyButton nav={buttons[2].handler} title={buttons[2].title} id={""} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    } else {
        mult = 600 / windowWidth;
        return (
            <>
                <div className="maincontainer2 colorcccccc">
                    <div className="bigmsg-box-mob" style={{ width: 350, height: 500, borderRadius: 15 }}>
                        <div className="bigmsg-box-top loginfont" style={{ textAlign: 'center', alignItems: 'center', width: '100%', height: 165 / mult, fontWeight: 500 / mult }}>
                            <span className='colord9d9d9' style={{ fontSize: 40 / mult }}>{title}</span>

                            <span className='bigmsg-subtitle colord9d9d9' style={{ fontSize: 20 / mult }}>{subtitle}</span>

                            <div className="bigmsg-box-top" style={{ alignItems: 'center', height: 30 / mult }}>
                                <span className="bigmsg-subtitle" style={{ fontSize: 20 / mult, color: 'red' }}>{error}</span>
                            </div>
                        </div>

                        <div className="bigmsg-box-middle" style={{ width: '100%', height: '100%', fontSize: 20 / mult }}>
                            {message && <span className='bigmsg-subtitle colore6e6e6' style={{ fontSize: 30 / mult, padding: 100 / mult }}>{message}</span>}
                            {inputs[0].toptext && < BigMsgInput toptext={inputs[0].toptext} type={inputs[0].type} id={inputs[0].id} value={inputs[0].value} handler={inputs[0].handler} mult={mult} mobile={true} />}
                            {inputs[1].toptext && < BigMsgInput toptext={inputs[1].toptext} type={inputs[1].type} id={inputs[1].id} value={inputs[1].value} handler={inputs[1].handler} mult={mult} mobile={true} />}
                            {inputs[2].toptext && < BigMsgInput toptext={inputs[2].toptext} type={inputs[2].type} id={inputs[2].id} value={inputs[2].value} handler={inputs[2].handler} mult={mult} mobile={true} />}

                            {buttons[0].title && <EmptyButton nav={buttons[0].handler} title={buttons[0].title} id={""} />}
                            {buttons[1].title && <EmptyButton nav={buttons[1].handler} title={buttons[1].title} id={""} />}
                            {buttons[2].title && <EmptyButton nav={buttons[2].handler} title={buttons[2].title} id={""} />}

                        </div>
                    </div>
                </div>

            </>
        );

    }



}

function BigMsgInput({ toptext, type, id, value, handler, mult, mobile = false }: any) {
    if (mobile) {
        return (
            <div className="bigmsg-input-div" style={{ width: 350 / mult, height: 70 / mult }}>
                <span style={{ marginLeft: 20 / mult, marginBlockStart: 5 / mult, fontWeight: 400, fontSize: 20 / mult }}>{toptext}</span>
                <input
                    type={type}
                    id={id}
                    onChange={handler}
                    size={50}
                    required
                    style={{ width: 340 / mult, height: 30 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }}
                />
            </div>
        );
    } else {
        return (
            <div className="bigmsg-input-div" style={{ width: 650 / mult, height: 70 / mult }}>
                <span style={{ marginLeft: 20 / mult, marginBlockStart: 5 / mult, fontWeight: 400, fontSize: 20 / mult }}>{toptext}</span>
                <input
                    type={type}
                    id={id}
                    onChange={handler}
                    size={50}
                    required
                    style={{ width: 640 / mult, height: 30 / mult, fontWeight: 200, fontSize: 20 / mult, borderRadius: 7 / mult, textAlign: 'center', outline: 'none' }}
                />
            </div>
        );
    }
}

export default BigMsg;