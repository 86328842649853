

import '../../CSS/global.css';
import '../../CSS/reports.css';
import '../../../src/CSS/mobile.css';
import '../../CSS/portfolio.css';
import { useState, useEffect, useRef } from 'react';
import { useGetAllImagesInfoQuery, useGetImageDataQuery, useSetAknMutation } from "../../Apis/imageApi";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../storage/redux/store";
import { useScrollbar } from '../../Hooks/use-scrollbar-y';
import { withAuth } from '../../HOC';
import { useNavigate } from "react-router-dom";
import MiniLoader from "../Misc/MiniLoader";
import { setSortMenuState } from "../../storage/redux/slice/varSlice";
import SortMenu from "../Misc/SortMenu";
import CheckBoxBlock from "../Misc/CheckBoxBlock";
import { apiResponse, userModel } from "../../Interfaces";
import { W1, W2 } from "../../functions";


function MobileReportObject({ userid, resultimages, intrash, itemname, sid, uuid4name, mult }: any) {
    const searchresults = "";
    const sorr = "s";
    const { data, isLoading, isSuccess, isError, error } = useGetImageDataQuery({ userid, sorr, uuid4name });
    return (
        <div className="container-column" style={{ width: 510 / mult, marginTop: 10, marginBottom: 1, border: '1px solid black' }}>
            <div className="container-column" style={{ width: 500 / mult, margin: 5 }}>
                <div >
                    {data !== undefined ?
                        <img className="myreports-content-item-image" style={{ maxWidth: 110 / mult }} src={`data:image/jpeg;base64,${data}`} />
                        : <MiniLoader />
                    }
                </div>
                {itemname}
            </div>
            <div className="container-row-up" style={{ width: 500 / mult, margin: 5 }}>
                Found images:
            </div>

            {resultimages.map((imagedata: any) => {
                return (
                    <MobileFoundObject
                        key={imagedata.thumb_name}
                        imageurl={imagedata.imageurl}
                        pageurl={imagedata.pageurl}
                        confidence={imagedata.confidence}
                        downloaddate={imagedata.downloaddate}
                        userid={userid}
                        result_status={imagedata.result_status}
                        result_item_id={imagedata.result_item_id}
                        checked_by={imagedata.checked_by}
                        uuid4name={imagedata.thumb_name}
                        how_informed={imagedata.how_informed}
                        mult={mult}
                    />
                );
            })
            }

        </div>
    );
}


const MobileFoundObject = ({ imageurl, pageurl, confidence, downloaddate, userid, result_status, result_item_id, checked_by, uuid4name, how_informed, mult }: any) => {
    const [aknState, setAknState] = useState(false);
    let akn = false;
    if (result_status !== 1) { akn = true; }
    let buttonsarray = [];
    const [setAkn] = useSetAknMutation();

    const submitstatus = (e: any) => {
        const [btnid, ritemid] = e.target.id.split("_");
        let rstatus;
        akn = !akn;
        buttonsarray = [{ "id": "akn_" + result_item_id, "title": "", "buttonstate": akn, "buttonhandler": submitstatus },]
        if (akn) { rstatus = 2; } else { rstatus = 1; }
        setAknState(!aknState);
        const response: any = setAkn({ userid, ritemid, rstatus });
        if (response.data) {
            if (response.data === "OK") {
            } else {
                //setError("Unknown Error");
            }
        } else if (response.error) {
            //setError("Error: " + response.error.data.title);
        }
    }

    const sorr = "r";
    const { data, isLoading, isSuccess, isError, error } = useGetImageDataQuery({ userid, sorr, uuid4name });

    let checked_by_text = checked_by
    if (checked_by === null) { checked_by = ""; }
    if (checked_by.length > 25) {
        checked_by_text = checked_by.substring(0, 13) + "..."
    }

    buttonsarray = [{ "id": "akn_" + result_item_id, "title": "", "buttonstate": akn, "buttonhandler": submitstatus },]

    return (
        <div className="container-row-between" style={{ alignItems: 'flex-start', width: 500 / mult, margin: 10 }}>

            {data === undefined ? (
                <div style={{ width: 120 / mult }}><MiniLoader /></div>
            ) : (
                <div style={{ width: 120 / mult }}>
                    <a href={pageurl} target="_blank">
                        <img className="myreports-content-item-image" style={{ maxWidth: 113 / mult, margin: 3 }} src={`data:image/jpeg;base64,${data}`} />
                    </a>
                </div>
            )
            }

            <div className="container-l-l-column" style={{ width: 360 / mult, minHeight: 20 }}>
                <div className="container-row-between" style={{ width: 350 / mult, minHeight: 5 }} >
                    <span>Confidence:</span>
                    <span className="report-span-mob">{confidence}%</span>
                </div>
                <div className="container-row-between" style={{ width: 350 / mult, minHeight: 5 }} >
                    <span>Found:</span>
                    <span className="report-span-mob">{downloaddate.substring(0, 10)}</span>
                </div>
                <div className="container-row-between" style={{ width: 350 / mult, minHeight: 5 }} >
                    <span>Notified by:</span>
                    <MobileNotifiedBy how_informed={how_informed} mult={mult} />
                </div>
                <div className="container-row-between" style={{ width: 350 / mult, minHeight: 5 }} >
                    <span>Ack:</span>

                    <div >
                        {(akn) ?
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10 }}>
                                <div style={{ marginTop: 6 }}><CheckBoxBlock buttonsarray={buttonsarray} layout={"column"} /></div>
                                <span className="report-span-mob" style={{ color: 'green' }}>{checked_by_text}</span>
                            </div>
                            :
                            <div style={{ display: 'flex', justifyContent: 'right', marginLeft: 10 }}>
                                <div style={{ marginTop: 6 }}>
                                    <CheckBoxBlock buttonsarray={buttonsarray} layout={"column"} />
                                </div>
                            </div>
                        }
                    </div>


                </div>
            </div>
        </div>
    );
}

function MobileNotifiedBy({ how_informed, mult }: any) {
    return (
        <div className="report-span-mob" style={{ textAlign: 'left' }}>
            {(how_informed === 3 || how_informed === 5 || how_informed === 8) && <><span style={{ color: 'green' }}>&nbsp;Mail: OK</span></>}
            {(how_informed === 4 || how_informed === 6 || how_informed === 7) && <><span style={{ color: 'red' }}>&nbsp;Mail: Failed</span></>}
            {(how_informed === 1 || how_informed === 5 || how_informed === 7) && <><span style={{ color: 'green' }}>&nbsp;SMS: OK</span></>}
            {(how_informed === 2 || how_informed === 6 || how_informed === 8) && <><span style={{ color: 'red' }}>&nbsp;SMS: Failed</span></>}
            {(how_informed === 0) && <span>&nbsp;</span>}
            {(how_informed === 9) && <span style={{ color: 'black' }}>On Screen</span>}
        </div>
    );
}
export default withAuth(MobileReportObject);