import '../../CSS/global.css';
import '../../CSS/loginandreg.css';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { accountModel, ccModel, userModel } from "../../Interfaces";
import EmptyButton from '../Misc/EmptyButton';
import { RootState } from "../../storage/redux/store";
import { useNavigate } from 'react-router-dom';
import { withAuth } from '../../HOC';
import { setSbscClientSecret } from "../../storage/redux/slice/subscriptionSlice";
import { useCreateSubscriptionMutation } from "../../Apis/paymentsApi";
import { CreateSubscriptionRequest } from "../../functions";
import { v4 as uuid } from 'uuid';
import { W0, W1, W2 } from "../../functions";

function CheckoutI() {
    const userData: userModel = useSelector(
        (state: RootState) => state.userAuthStore
    );
    const subscriptionData: accountModel = useSelector(
        (state: RootState) => state.subscriptionStore
    );

    //console.log("-----subscriptionData");
    //console.log(subscriptionData);
    const paymentData: ccModel = useSelector(
        (state: RootState) => state.paymentStore
    );
    const [error, setError] = useState("");
    const [createSubscription] = useCreateSubscriptionMutation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const proceedhandler = async () => {

        // create subscription
        const req = CreateSubscriptionRequest(subscriptionData, f_byd, f_3t, f_d, f_m, f_w, n_e, n_sms, ondemandaddon);
        // req to API , if error -  show error

        const response: any = await createSubscription({
            req: req,
            userid: userData.id,
        });

        if (response.data) {
            dispatch(setSbscClientSecret(response.data.clientSecret));
            navigate("/EnterPaymentInfo");
        } else if (response.error) {
            setError("Error: " + response.error.data);
        }
    }
    let frequency;
    let notification = "N/A";

    let f_byd = 0;
    let f_3t = 0;
    let f_d = 0;
    let f_m = 0;
    let f_w = 0;
    let n_e = 0;
    let n_sms = 0;
    let ondemandaddon = 0;
    let ondemanaddontext = "No";
    let cost = 0;
    if (subscriptionData.cost !== undefined) { cost = subscriptionData.cost / 100; }
    if (subscriptionData.users !== undefined) {
        if (subscriptionData.users.length > 0) {
            console.log('subscriptionData.users');
            console.log(subscriptionData.users);
            if (subscriptionData.users[0].frequencyMonthly) { frequency = "Monthly"; f_m = 1; }
            if (subscriptionData.users[0].frequencyWeekly) { frequency = "Weekly"; f_w = 1; }
            if (subscriptionData.users[0].frequencyDaily) { frequency = "Daily"; f_d = 1; }
            if (subscriptionData.users[0].frequency3Times) { frequency = "3Times"; f_3t = 1; }
            if (subscriptionData.users[0].frequencyOnDemand) { frequency = "OnDemand"; f_byd = 1; }
            if (subscriptionData.users[0].notificationAddOn) { ondemanaddontext = "Yes"; ondemandaddon = 1; }
            if (subscriptionData.users[0].notificationEmail) { notification = "Email"; n_e = 1; }
            if (subscriptionData.users[0].notificationSMS) { notification += " and SMS"; n_sms = 1; }
        }
    }
    /*************Calculate data for responsive behaviour************************/
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });
    let mult = 1;
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    /******************************************************************************/
    if (windowWidth > W0) {
        return (
            <>
                <div className="maincontainer2 colore6e6e6">
                    <div style={{ width: 1320 / mult, height: 910 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 100, marginTop: -50 }}>
                        <div className="ca-mainbox" style={{ width: 1300 / mult, height: 800 / mult, marginTop: 100 }}>
                            <div className="ca-top" style={{ width: 1100 / mult, height: 130 / mult, fontWeight: 600, fontSize: 35 / mult + 'px', letterSpacing: '1.5px' }}>
                                Checkout
                            </div>
                            <div className="ca-top" style={{ width: 1100 / mult, height: 50 / mult, fontWeight: 400, fontSize: 30 / mult + 'px' }}>
                                You are about to subscribe to an Individual plan with the following options:
                            </div>
                            <span style={{ color: 'red' }}>{error}</span>
                            <div className="container-column" style={{ margin: 0, width: 1100 / mult, height: 620 / mult, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>
                                <div className="container-row-up" style={{ margin: 0, width: 1000 / mult, justifyContent: 'center' }}>
                                    <div className="container-l-l-column" style={{ marginRight: 40 / mult, width: '50%' }}>
                                        <div className="container-row-between " style={{ marginTop: -65 / mult, marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Number of media:</span>
                                            <span>{subscriptionData.mediaQuota}</span>


                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Image Swap:</span>
                                            <span>Included</span>

                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Online notification: </span>
                                            <span>Included</span>

                                        </div>

                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Additional notification: </span>
                                            <span>{notification}</span>

                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>Search frequency:</span>
                                            <span>{frequency}</span>
                                        </div>
                                        <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                            <span>On Demand add-on:</span>
                                            <span>{ondemanaddontext}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-row-center" style={{ width: '100%', marginTop: 10 / mult }}>
                                    Subscription monthly cost : ${cost}
                                </div>
                                <div className="container-row-center" style={{ width: '100%', marginTop: 60 / mult }}>
                                    <EmptyButton nav={() => { navigate(-1); }} title={"Back"} id={""} classname={"emptybuttongray"} />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <EmptyButton nav={proceedhandler} title={"Proceed with payment"} id={""} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    } else {
        // Mobile *************************************************************
        mult = W0 / windowWidth;
        return (
            <>
                <div className="bg_div_mob">
                    <div className="title_div_mob" style={{ width: 540 / mult }}>
                        <span className="p-span1 colord9d9d9" style={{ fontSize: 30 / mult + 'px' }}>
                            Checkout
                        </span>
                    </div>
                    <div className="main_div_mob" style={{ width: 550 / mult, backgroundColor: '#494b53', border: '2px solid #a6abb9' }}>
                        <div style={{ width: 500 / mult, marginBottom: 30 }}>


                            <div style={{ margin: 0, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>

                                <div style={{ fontSize: 25 / mult + 'px', margin: 10, marginTop: 0 }}>&nbsp;<br />
                                    You are about to subscribe to an Individual plan with the following options:
                                </div>
                                <div className="container-column" style={{ marginTop: 25, fontWeight: 400, fontSize: 20 / mult + 'px', letterSpacing: '1px', color: '#a6abb9' }}>
                                    <div className="container-row-up" style={{ margin: 0, justifyContent: 'center' }}>
                                        <div className="container-l-l-column" >
                                            <div className="container-row-between " style={{ marginBottom: 15 / mult, width: '100%' }}>
                                                <span>Number of media:</span>
                                                <span>{subscriptionData.mediaQuota}</span>


                                            </div>
                                            <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                                <span>Image Swap:</span>
                                                <span>Included</span>

                                            </div>
                                            <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                                <span>Online notification: </span>
                                                <span>Included</span>

                                            </div>

                                            <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                                <span>Additional notification: </span>
                                                <span>{notification}</span>

                                            </div>
                                            <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                                <span>Search frequency:</span>
                                                <span>{frequency}</span>
                                            </div>
                                            <div className="container-row-between" style={{ marginBottom: 15 / mult, width: '100%' }}>
                                                <span>On Demand add-on:</span>
                                                <span>{ondemanaddontext}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-row-center" style={{ width: '100%', marginTop: 10 / mult }}>
                                        Subscription monthly cost :&nbsp;<span style={{ color: 'white' }}>${cost}</span>
                                    </div>
                                    <div className="container-column" style={{ width: '100%', marginTop: 60 / mult }}>
                                        <EmptyButton nav={() => { navigate(-1); }} title={"Back"} id={""} classname={"emptybuttongray"} />
                                        &nbsp;
                                        <EmptyButton nav={proceedhandler} title={"Proceed with payment"} id={""} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default withAuth(CheckoutI);
