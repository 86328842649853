import '../CSS/global.css';
import '../CSS/pages.css';
import '../CSS/trash.css';
import { useState, useEffect, useRef } from 'react';
import { useScrollbar } from '../Hooks/use-scrollbar-y';
import Pdf1 from '../assets/pdfs/WebsiteTermsOfUseDejaVu.pdf';
import Pdf3 from '../assets/pdfs/CustomerTermsOfServiceDejaVu.pdf';
import { Link } from "react-router-dom";
import { W0, W1, W2 } from "../functions";


function Terms() {

  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  /**************** Scroll bar  things*********************************/
  const termsWrapper = useRef(null);
  const hasScroll = true;

  useScrollbar(termsWrapper, hasScroll, windowWidth);
  let mult = 1;
  if (windowWidth > 600) {
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (

      <div className="maincontainer2">
        <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="pg-mainbox" style={{ width: 1320 / mult, height: 690 / mult }}>
            <div className="pg-mainbox-top" style={{ width: 1250 / mult, height: 80 / mult }}>
              <span className="pg-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px', marginBottom: 8 / mult }}>Terms of Service</span>
            </div>
            <div className="pg-mainbox-media" style={{ width: 1230 / mult, height: 597 / mult }}>
              <div style={{ width: 1100 / mult, paddingRight: '12px', height: 450 / mult, minHeight: 450 / mult }} ref={termsWrapper}>
                <div className="" style={{ height: 1000 / mult, fontSize: 16, marginRight: 50 / mult }}>
                  <TermsText />
                </div>
              </div >
            </div >
            <div className="pg-terms-mainbox-bottom" style={{ width: 1270 / mult, height: 90 / mult }}>
              <a style={{ color: '#698ff9', fontWeight: 800 / mult, fontSize: 20 / mult, marginRight: 20 / mult, marginTop: 15 / mult }} href={Pdf1} target="_blank" rel="noopener noreferrer">Website Terms Of Use in PDF format</a><br /><br />
              <a style={{ color: '#698ff9', fontWeight: 800 / mult, fontSize: 20 / mult, marginRight: 20 / mult, marginTop: 15 / mult }} href={Pdf3} target="_blank" rel="noopener noreferrer">Terms Of Service  in PDF format</a>
            </div>
          </div >
        </div >
      </div >
    );
  } else {
    // Mobile *************************************************************

    mult = 600 / windowWidth;
    const mult1 = 3;
    return (
      <>
        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>Terms of Service</span>
          </div>

          <div className="main_div_mob" style={{ width: 550 / mult }}>
            <div style={{ width: 500 / mult }}>
              <TermsText />

              <a style={{ color: 'blue', fontWeight: 600 / mult, fontSize: 20 / mult }} href={Pdf1} target="_blank" rel="noopener noreferrer">Website Terms Of Use in PDF format</a><br /><br />
              <a style={{ color: 'blue', fontWeight: 600 / mult, fontSize: 20 / mult }} href={Pdf3} target="_blank" rel="noopener noreferrer">Terms Of Service  in PDF format</a>
              <div style={{ height: 40 }}></div>
            </div>
          </div>
        </div >
      </>
    );
  }

}

function TermsText() {
  return (
    <>
      <p>Final: March 1, 2023</p>
      <p>DEJAVU.ART</p>
      <p>TERMS OF SERVICE</p>
      <p>Last Updated: March 1st, 2023</p>
      <p>Please read these Terms of Service (these “Terms”) carefully. If you use or access software services which
        help identify images online (the “Services”) provided by DejaVu.art, a Washington corporation
        (“DejaVu.Art”), you agree to be bound by these Terms. If you do not agree to these Terms, do not use the
        Services. We may update or modify these Terms from time to time and will post them and update the date.
        By accessing or using the Services thereafter, you agree to be bound by such updated Terms. If you do not
        agree, you should not continue to use the Services. If there is a conflict between these Terms and any other
        applicable Terms of Service, these Terms shall prevail.</p>
      <p>1. Access and Use of the Services.</p>
      <p>(a) User Account. In order to subscribe to the Services (a “Subscription”) you must
        open a user account (an “Account”). You will be asked to provide certain information such as your email
        address or other forms of identification. You are required to keep the information you provide to us true,
        accurate, and current at all times. The Services are intended for adults 18 years or older and you will not
        be able to use the Services or open an Account if you are under 18.</p>
      <p>(b) Provision of Access. Subject to and conditioned on your payment of Fees (as
        defined below) and compliance with all other terms and conditions of these Terms, DejaVu.Art hereby
        grants you a non-exclusive, non-transferable right to access and use the Services during the Term. Such use
        is limited to your internal use.</p>
      <p>(c) Use Restrictions. You shall not use the Services for any purposes beyond the scope
        of the access granted in these Terms. You shall not at any time, directly or indirectly: (i) violate local, state,
        or other applicable laws or regulations; (ii) upload images (“User Images”) that infringe the intellectual
        property rights, privacy, publicity, or other rights of any third party; (iii) upload User Images that are
        unlawful, obscene, defamatory, threatening, harassing, hateful, or embarrassing to any party as determined
        in our sole discretion; (iv) impersonate a third party or imply you are someone other than who you are; (v)
        copy, modify, or create derivative works of the Services, in whole or in part; (vi) rent, lease, lend, sell,
        license, sublicense, assign, distribute, publish, transfer, or otherwise make available the Services to any
        third party; (vii) reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or
        gain access to the Services, in whole or in part; (viii) remove any proprietary notices from the Services; or
        (ix) engage in any conduct that disrupts or impedes a third party’s use and enjoyment of the Services, or
        which, in our judgment, exposes us or any of our customers or vendors or related entities to liability or
        harm of any kind.</p>
      <p>(d) Reservation of Rights. DejaVu.Art reserves all rights not expressly granted to you
        in these Terms. Except for the limited rights and licenses expressly granted under these Terms, nothing in
        these Terms grants, by implication, waiver, estoppel, or otherwise, to you or any third party any intellectual
        property rights or other right, title, or interest in or to the intellectual property rights of DejaVu.Art
        (“DejaVu.Art IP”).</p>
      <p>(e) Suspension of Access. Notwithstanding anything to the contrary in these Terms,
        DejaVu.Art may temporarily or permanently suspend your access to any portion or all of the Services and/or
        your Subscription if: (i) you are in violation of the these Terms, as solely determined by DejaVu.Art; (ii)
        DejaVu.Art determines that (A) there is a threat or attack on any of the DejaVu.Art IP, (B) your use of the
        Services disrupts or poses a security risk to the DejaVu.Art or to any other customer or vendor of
        DejaVu.Art; or (C) DejaVu.Art’s provision of the Services to you is prohibited by applicable law; or
        (iii) any vendor of DejaVu.Art has suspended or terminated DejaVu.Art’s access to or use of any thirdparty
        services or products required to enable you to access the Services.</p>
      <p>(f) Updates to Services. DejaVu.Art may from time to time in our sole discretion
        develop and provide updates to the Services which may include upgrades, bug fixes, patches, other error
        corrections, and/or new features (collectively, “Updates”). Updates may also modify or delete in their
        entirety certain features and functionality of the Services. You agree that DejaVu.Art has no obligation to
        provide any Updates or to continue to provide or enable any particular features or functionality of the
        Services. Updates shall be subject to these Terms.</p>
      <p>2. Subscription Fees and Payment.</p>
      <p>(a) Subscription Fees. You shall pay DejaVu.Art the monthly Subscription fees posted
        on <a data-fr-linked="true" href="//www.dejavu.art">www.dejavu.art</a> or otherwise communicated to you by email (the “Fees”). The Fees shall be inclusive
        of all applicable taxes. You authorize DejaVu.Art to charge any form of payment you provide to us in
        connection with your Account registration. You must cancel your Subscription before it renews in order
        to avoid us billing you the Fees in the next monthly billing cycle. DejaVu.Art does not pro-rate the Fees.
        If you fail to make any payment when due, without limiting DejaVu.Art’s other rights and remedies,
        DejaVu.Art may suspend your Account until all unpaid amounts are paid in full.</p>
      <p>3. Intellectual Property Ownership; Feedback.</p>
      <p>(a) DejaVu.Art IP. You acknowledge that, as between you and DejaVu.Art,
        DejaVu.Art owns all right, title, and interest, including all intellectual property rights, in and to the
        DejaVu.Art IP.</p>
      <p>(b) User Images. DejaVu.Art acknowledges that, except as provided herein, it has no
        right, title, and interest, in and to the User Images. In order for DejaVu.Art to provide the Services, you
        hereby grant to DejaVu.Art a non-exclusive, royalty-free, worldwide, perpetual license to reproduce,
        distribute, display, and otherwise use the User Images and to perform all acts with respect to the User
        Images as may be necessary for DejaVu.Art to provide the Services to you.</p>
      <p>(c) Feedback. If you send or transmit any communications or materials to DejaVu.Art
        by mail, email, telephone, or otherwise suggesting or recommending changes to the Services, including
        without limitation, new features or functionality relating thereto, or any comments, questions, suggestions,
        or the like (collectively, “Feedback”), DejaVu.Art shall own such Feedback and is free to use such
        Feedback without any obligation or payment to you. In furtherance of the foregoing, you hereby assign to
        DejaVu.Art all right, title, and interest in the Feedback.</p>
      <p>4. Representations and Warranties. You represent and warrant to DejaVu.Art that:</p>
      <p>(a) You are at least 18 years of age and have the full right, power, and authority to</p>
      enter into these Terms and, if necessary, your agreement to these Terms has been duly authorized.
      <p>(b) You have the full right and authority to upload the User Images for DejaVu.Art’s
        use in connection with the Services.</p>

      <p>(c) The User Images do not and will not infringe, misappropriate, or otherwise violate
        any intellectual property rights or any privacy or other rights of any third party or violate any applicable
        law.</p>
      <p>5. Warranty Disclaimer.</p>
      <p>(a) THE SERVICES ARE PROVIDED “AS IS” AND DEJAVU.ART HEREBY
        DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
        OTHERWISE. DEJAVU.ART SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT,
        AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR
        TRADE PRACTICE. DEJAVU.ART MAKES NO WARRANTY OF ANY KIND THAT THE
        SERVICES, OR ANY RESULTS OF THE USE THEREOF, WILL MEET YOUR OR ANY OTHER
        PERSON’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED
        RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES,
        OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE.</p>
      <p>6. Indemnification. You shall indemnify, hold harmless, and, at DejaVu.Art’s option, defend
        DejaVu.Art from and against, any Losses resulting from: (i) the breach of your representations and
        warranties to DejaVu.Art herein; (ii) your negligence or willful misconduct; (iii) use of the Services in a
        manner not authorized by these Terms; or (iv) use of the Services in combination with data, software,
        hardware, equipment, or technology not provided by DejaVu.Art or authorized by DejaVu.Art. “Losses”
        means any and all losses, damages, deficiencies, claims, actions, judgments, settlements, interest, awards,
        penalties, fines, costs, or expenses of whatever kind, including reasonable attorneys’ fees and the costs of
        enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers.</p>
      <p>7. Limitations of Liability. IN NO EVENT WILL DEJAVU.ART BE LIABLE UNDER OR
        IN CONNECTION WITH THESE TERMS UNDER ANY LEGAL OR EQUITABLE THEORY,
        INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY,
        AND OTHERWISE, FOR ANY: (A) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
        SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (B) INCREASED COSTS, DIMINUTION IN
        VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (C) LOSS OF
        GOODWILL OR REPUTATION; (D) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY,
        OR RECOVERY OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (E) COST
        OF REPLACEMENT GOODS OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER
        DEJAVU.ART WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH
        LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL
        DEJAVU.ART’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS
        UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
        (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE EXCEED THE TOTAL
        AMOUNTS PAID BY YOU TO DEJAVU.ART UNDER THESE TERMS IN THE 12-MONTH PERIOD
        PRECEDING THE EVENT GIVING RISE TO THE CLAIM OR $100, WHICHEVER IS LESS.</p>
      <p>8. Term and Termination.</p>
      <p>(a) Term. These Terms are effective as of the date you first use the Services and, unless
        terminated earlier pursuant to these Terms, continue for so long as you pay for your Subscription (the
        “Term”).</p>
      <p>(b) Termination. In addition to any other termination rights set forth herein:</p>

      <p>(i) DejaVu.Art may immediately terminate these Terms and your access to
        the Services if you: (A) fail to pay any amount when due hereunder; or (B) breach these Terms.</p>
      <p>(ii) DejaVu.Art may terminate these Terms, effective immediately, if you: (A)</p>
      <p>become insolvent or are generally unable to pay, or fail to pay, your debts as they become due; (B) file or
        have filed against you, a petition for voluntary or involuntary bankruptcy or otherwise become subject,
        voluntarily or involuntarily, to any proceeding under any domestic or foreign bankruptcy or insolvency
        law; (C) make or seek to make a general assignment for the benefit of your creditors; or (D) apply for or
        have appointed a receiver, trustee, custodian, or similar agent appointed by order of any court of competent
        jurisdiction to take charge of or sell any material portion of your property or business.</p>
      <p>(c) Effect of Expiration or Termination. Upon termination of these Terms you shall
        immediately lose use of the Services. No termination will affect your obligation to pay all Fees that may
        have become due before such expiration or termination or entitle you to any refund. Sections of these
        Terms which, by their nature, are intended to survive termination shall survive termination.</p>
      <p>9. Miscellaneous.</p>
      <p>(a) Privacy. We use and protect your information and maintain the security of the</p>
      <p>Services in accordance with the DejaVu.Art Privacy Policy, which is incorporated herein by reference and</p>

      <p>located at
        <Link to="/Privacy"> www.dejavu.art/Privacy </Link>


      </p>

      <p>(b) Entire Agreement. These Terms, together with any other documents incorporated
        herein, constitute the sole and entire agreement of the parties with respect to the subject matter set forth
        herein and supersedes all prior and contemporaneous understandings, agreements, and representations and
        warranties, both written and oral, with respect to such subject matter. In the event of any inconsistency
        between the statements made in the body of these Terms and any other documents incorporated herein,
        these Terms shall govern.</p>
      <p>(c) Notices. All notices, requests, consents, claims, demands, waivers, and other
        communications hereunder (each, a “Notice”) must be in writing (email sufficient) and be addressed, if to
        you, to the email address we have for you on file and, if to us, to our contact email address set forth in our
        website.</p>
      <p>(d) Force Majeure. In no event shall DejaVu.Art be liable to you, or be deemed to have
        breached these Terms, for any failure or delay in performing its obligations under these Terms, if and to the
        extent such failure or delay is caused by any circumstances beyond DejaVu.Art’s reasonable control,
        including but not limited to acts of God, flood, fire, earthquake, epidemic, explosion, war, terrorism,
        invasion, riot or other civil unrest, strikes, labor stoppages or slowdowns or other industrial disturbances,
        or passage of law or any action taken by a governmental or public authority, including imposing an
        embargo.</p>
      <p>(e) Waiver. No failure to exercise, or delay in exercising, any rights, remedy, power,
        or privilege arising from these Terms will operate or be construed as a waiver thereof, and no single or
        partial exercise of any right, remedy, power, or privilege hereunder will preclude any other or further
        exercise thereof or the exercise of any other right, remedy, power, or privilege.</p>
      <p>(f) Severability. If any provision of these Terms are deemed invalid, illegal, or
        unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability will not affect any other
        term or provision of these Terms or invalidate or render unenforceable such term or provision in any other
        jurisdiction. Upon such determination that any term or other provision is invalid, illegal, or unenforceable,
        the parties shall negotiate in good faith to modify these Terms so as to effect their original intent as closely
        as possible in a mutually acceptable manner in order that the transactions contemplated hereby be
        consummated as originally contemplated to the greatest extent possible.</p>
      <p>(g) Arbitration. Except as provided below, any dispute or controversy arising out of,
        relating to, or concerning any interpretation, construction, performance, or breach of this Agreement, will
        be settled by arbitration to be held in King County, in the State of Washington, in accordance with the rules
        then in effect of the American Arbitration Association. The arbitrator may grant injunctions or other relief
        in the dispute or controversy. The decision of the arbitrator will be final, conclusive, and binding on the
        parties to the arbitration. Judgment may be entered on the arbitrator’s decision in any court having
        jurisdiction. Each party will each pay one-half of the costs and expenses of the arbitration, and each will
        separately pay their own counsel fees and expenses. Each party’s promise to resolve claims by arbitration,
        rather than through the courts, is consideration for the other party’s like promise. Notwithstanding the
        foregoing, the parties may apply to any court of competent jurisdiction for a temporary restraining order,
        preliminary injunction, or other interim or conservatory relief, as necessary, without breach of these terms.</p>
      <p>(h) WAIVER OF JURY TRIAL. EACH PARTY HEREBY IRREVOCABLY
        WAIVES ALL RIGHT TO TRIAL BY JURY IN ANY ACTION, PROCEEDING OR COUNTERCLAIM
        (WHETHER BASED ON CONTRACT, TORT OR OTHERWISE) ARISING OUT OF, OR RELATING
        TO, THESE TERMS.</p>
      <p>(i) Assignment. You may not assign any of your rights or delegate any of your
        obligations hereunder, in each case whether voluntarily, involuntarily, by operation of law or otherwise,
        without the prior written consent of DejaVu.Art. Any purported assignment or delegation in violation of
        this Section will be null and void. No assignment or delegation will relieve the assigning or delegating Party
        of any of its obligations hereunder. These Terms are binding upon and inure to the benefit of the parties
        and their respective permitted successors and assigns.</p>

      <p>************************************************************************************</p>

      <p>Final: March 1st, 2023</p>
      <p>WEBSITE TERMS OF USE</p>
      <p><a data-fr-linked="true" href="//WWW.DEJAVU.ART">WWW.DEJAVU.ART</a></p>
      <p>Last Updated: March 1st, 2023</p>
      <p>These Website Terms of Use are entered into by and between you (“you” or “your”) and DejaVu.art, a
        subsidiary of DejavuAI Inc. a Washington corporation (“Company,” “DejaVuAI Inc., DejaVu.Art,”
        “we,” or “us”). The following terms and conditions (these “Terms of Use”) govern your access to and use
        of <a data-fr-linked="true" href="//www.dejavu.art">www.dejavu.art</a> (the “Website”).</p>

      <p>By using the Website, you: (a) acknowledge that you have read and understand these Terms of Use;
        (b) represent that you are at least the age of majority in your jurisdiction and have the capacity to enter into
        a binding agreement; and (c) accept and agree that you are legally bound by these Terms of Use. If you do
        not, or cannot, agree to these Terms of Use, do not use the Website.</p>
      <p>1. Changes to the Terms of Use. We may revise and update these Terms of Use from time
        to time in our sole discretion. All changes are effective immediately when we post them. Your continued
        use of the Website following the posting of revised Terms of Use means that you accept and agree to the
        changes. You are expected to check this page from time to time so you are aware of any changes, as they
        are binding on you.</p>

      <p>2. Accessing the Website. We reserve the right to withdraw or modify this Website, and any
        service, product, item, or material we provide on the Website, in our sole discretion and without notice to
        you. We will not be liable if, for any reason, all or any part of the Website is unavailable at any time or for
        any period. From time to time, we may restrict access to some parts of the Website, or the entire Website,
        to users.</p>

      <p>3. Intellectual Property Rights. The Website and its entire contents, features, and
        functionality (including but not limited to, all information, software, text, displays, images, video and audio,
        and the design, selection and arrangement thereof), are owned by DejaVu.Art, its licensors, or other
        providers of such material and are protected by applicable United States and international intellectual
        property laws. These Terms of Use hereby permit you to use the Website for your personal use only. You
        must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform,
        republish, download, store or transmit any of the material on our Website without our written consent. No
        right, title or interest in or to the Website or any content on the Website is transferred to you, and all rights
        not expressly granted herein are reserved by DejaVu.Art. Any use of the Website other than as expressly
        permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark
        and other laws. You agree all suggestions, enhancements requests, feedback, recommendations or other
        input provided by you through our Website shall be owned by DejaVu.Art in exchange for use of the
        Website.</p>

      <p>4. Trademarks. Our Company name, any Company logos, and all related names, logos,
        product and service names, designs, and slogans used on the Website are trademarks of DejaVu.Art or our
        affiliates or licensors. Other product and company names and logos appearing on the Website may be
        registered or unregistered trademarks, service marks or trade names of their respective owners. Any use of
        the trademarks, service marks or trade names displayed on the Website is strictly prohibited, and nothing
        appearing on the Website will be construed as granting, by implication or otherwise, any license or right to
        use any of those trademarks, service marks or trade names.</p>

      <p>5. Prohibited Uses. Without limiting the foregoing, you may not use the Website to: (a) take
        actions that are defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory
        or otherwise objectionable; (b) promote sexually explicit or pornographic material, violence, or
        discrimination based on race, sex, religion, nationality, disability, sexual orientation or age; (c) infringe any
        patent, trademark, trade secret, copyright or other intellectual property or other rights of any other person;
        (d) violate the legal rights (including the rights of publicity and privacy) of others or behave in any way
        that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise
        may be in conflict with these Terms of Use and our Privacy Policy; (e) take actions that would be likely to
        deceive any person, including, without limitation, to impersonate or attempt to impersonate DejaVu.Art, a
        DejaVu.Art employee, another user or any other person or entity; (f) promote any illegal activity, or
        advocate, promote or assist in any unlawful act; (g) cause annoyance, inconvenience or needless anxiety to,
        or upset, embarrass, alarm, or annoy any other person; (h) engage in commercial activities or sales with
        others, such as contests, sweepstakes and other sales promotions, bartering or advertising, or transmit, or
        procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,”
        “spam,” or any other similar solicitation; (i) give the impression that your behaviors or comments emanate
        from or are endorsed by us or any other person or entity.</p>

      <p>Further, you may not: (j) use the Website in any manner that could disable, overburden, damage,
        or impair the Website or interfere with any other party's use of the Website; (k) use any robot, spider or
        other automatic device, process or means to access the Website for any reason whatsoever; (l) use any
        manual process to monitor or copy any of the material on the Website for any reason without our prior
        written consent; (m) use any device, software or routine that interferes with the proper working of the
        Website; (n) introduce any viruses, Trojan horses, worms, logic bombs or other material which is malicious
        or technologically harmful; (o) attempt to gain unauthorized access to, interfere with, damage or disrupt
        any parts of the Website, the server on which the Website is stored, or any server, computer or database
        connected to the Website; or (p) otherwise attempt to interfere with the proper working of the Website.</p>

      <p>6. Response to Violations. We reserve the right to (i) take appropriate legal action, including
        without limitation, referral to law enforcement, for any illegal or unauthorized use of the Website; and/or
        (ii) terminate or suspend your access to all or part of the Website for any or no reason, including without
        limitation, any violation of these Terms of Use.</p>

      <p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement
        authorities or court order related to our Website, including, without limitation, users of our Website. YOU
        WAIVE AND HOLD HARMLESS DEJAVU.ART AND ITS OFFICERS, SHAREHOLDERS,
        AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM
        ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF
        ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
        INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>

      <p>7. Reliance on Information Posted. The information presented on or through the Website
        is made available solely for general information purposes. We do not warrant the accuracy, completeness,
        or usefulness of this information. Any reliance you place on such information is strictly at your own risk.
        We disclaim all liability and responsibility arising from any reliance placed on such materials by you or
        any other visitor to the Website, or by anyone who may be informed of any of its contents. This Website
        may contain certain historical information. Historical information, necessarily, is not current and is
        provided for your reference only. We reserve the right to modify the contents of the Website at any time,
        but we have no obligation to update any information on our Website. You agree that it is your responsibility
        to monitor changes to our Website. This Website may include content provided by third parties, including
        materials provided by other users or third-party licensors, syndicators, aggregators and/or reporting
        services. All statements and/or opinions expressed in these materials, and all articles and responses to
        questions and other content, other than the content provided by DejaVu.Art, are solely the opinions and the
        responsibility of the person or entity providing those materials. These materials do not necessarily reflect
        the opinion of DejaVu.Art. We are not responsible, or liable to you or any third party, for the content or
        accuracy of any materials provided by any third parties.</p>

      <p>8. Changes to the Website. We may update the content on this Website from time to time,
        but its content is not necessarily complete or up to date. Any of the material on the Website may be out of
        date at any given time, and we are under no obligation to update such material.</p>

      <p>9. Information About You and Your Visits to the Website. All information we collect on
        this Website is subject to our Privacy Policy. By using the Website, you consent to all actions taken by us
        with respect to your information in compliance with our Privacy Policy.</p>

      <p>10. Third Party Links from the Website. If the Website contains links to other sites and
        resources provided by third parties, these links are provided for your convenience only. We have no control
        over the contents of those sites or resources and accept no responsibility for them or for any loss or damage
        that may arise from your use of them. If you decide to access any of the third-party websites linked to this
        Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</p>

      <p>11. Geographic Restrictions. This Website is provided / hosted in the United States. We
        make no claims that the Website or any of its content is accessible or appropriate outside of the United
        States. Access to the Website may not be legal by certain persons or in certain countries. If you access the
        Website from outside the United States, you do so on your own initiative and are responsible for compliance
        with local laws.</p>

      <p>12. Disclaimer of Warranties. You understand that we cannot and do not guarantee or
        warrant that the Website or any of its contents will be free of viruses or other destructive code. You are
        responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements
        for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our
        site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
        CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER
        TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
        EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO
        YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
        WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
        WEBSITE LINKED TO IT.</p>

      <p>YOUR USE OF THE WEBSITE, ITS CONTENT, ANY INFORMATION, OR ITEMS
        OBTAINED THROUGH THE WEBSITE ARE PROVIDED AT YOUR OWN RISK. THE WEBSITE,
        ITS CONTENT, AND ANY INFORMATION OBTAINED THROUGH THE WEBSITE ARE
        PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF
        ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER DEJAVU.ART NOR ANY PERSON
        ASSOCIATED WITH DEJAVU.ART MAKES ANY WARRANTY OR REPRESENTATION WITH
        RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
        AVAILABILITY OF THE WEBSITE, THE CONTENT, OR THE INFORMATION PROVIDED
        THEREIN. WITHOUT LIMITING THE FOREGOING, NEITHER DEJAVU.ART NOR ANYONE
        ASSOCIATED WITH DEJAVU.ART REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS
        CONTENT OR ANY INFORMATION OBTAINED THROUGH THE WEBSITE WILL BE
        ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
        CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE
        OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY
        INFORMATION OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS
        OR EXPECTATIONS.</p>

      <p>DEJAVU.ART HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
        EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
        ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
        PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH
        CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

      <p>13. Limitation on Liability. IN NO EVENT WILL DEJAVU.ART, ITS MEMBERS,
        AFFILIATES, LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR
        DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
        ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
        WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER
        WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL
        OR PUNITIVE DAMAGES, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY
        LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. IN THE
        EVENT CERTAIN OF THE COMPANY’S LIABILITY TO YOU CANNOT BE EXCLUDED UNDER
        APPLICABLE LAW, THE COMPANY’S MAXIMUM LIABILITY TO YOU FOR ALL SUCH
        DAMAGES SHALL BE $10.</p>

      <p>14. Indemnification. You agree to defend, indemnify and hold harmless DejaVu.Art, its
        members, affiliates, licensors and service providers, and its and their respective officers, directors,
        employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims,
        liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys'
        fees) arising out of or relating to your violation of these Terms of Use or your use of the Website.</p>

      <p>15. Reporting Claims of Copyright Infringement. We take claims of copyright infringement
        seriously. We will respond to notices of alleged copyright infringement that comply with applicable law. If
        you believe any materials accessible on or from this Website infringe your copyright, you may request
        removal of those materials from the Website by submitting written notification to us at the below address.
        In accordance with the Online Copyright Infringement Liability Limitation Act of the Digital Millennium
        Copyright Act (17 U.S.C. § 512) ("DMCA"), the written notice (the "DMCA Notice") must include
        substantially the following:</p>

      <p>• Your physical or electronic signature.</p>

      <p>• Identification of the copyrighted work you believe to have been infringed or, if the claim involves
        multiple works on the Website, a representative list of such works.</p>
      <p>• Identification of the material you believe to be infringing in a sufficiently precise manner to allow
        us to locate that material.</p>
      <p>• Adequate information by which we can contact you (including your name, telephone number, and
        email address).</p>
      <p>• A statement that you have a good faith belief that use of the copyrighted material is not authorized
        by the copyright owner, its agent, or the law.</p>
      <p>• A statement that the information in the written notice is accurate.</p>
      <p>• A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright
        owner.</p>
      <p>Send your DMCA Notice to:</p>
      <p>Web Support</p>
      <p>DejaVu.Art</p>
      <p><a data-fr-linked="true" href="mailto:artsupport@dejavuai.com">artsupport@dejavuai.com</a></p>

      <p>If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice
        may not be effective.</p>
      <p>Please be aware that if you knowingly materially misrepresent that material or activity on the Website is
        infringing your copyright, you may be held liable for damages (including costs and attorneys' fees) under
        Section 512(f) of the DMCA.</p>

      <p>16. Miscellaneous.</p>
      <p>A. Entire Agreement; Conflicts. These Terms of Use, the Privacy Policy, and our
        Terms of Service if you are a customer, constitute the entire agreement between you and DejaVu.Art with
        respect to your use of the Website, and supersede all prior or contemporaneous understandings and
        agreements, whether written or oral, with respect to the subject matter contained herein. Your obligations
        to indemnify DejaVu.Art, along with any other part of these Terms of Use which, by their nature should
        survive termination of these Terms of Use or your right to use the Website, shall survive. In the event of a
        conflict between these Terms of Use and customer Terms of Service, the customer Terms of Service shall
        prevail.</p>

      <p>B. Compliance with Legal Requests. You agree that information related to your use
        of this Website, including information you submit, may be subject to legal process and you understand that
        we will comply with all applicable legal obligations relating to disclosure of such information and we have
        no obligation to give you notice of such disclosures.</p>

      <p>C. No Relationship. You agree that no joint venture, partnership, employment, or
        agency relationship exists between you and DejaVu.Art as a result of these Terms of Use or your use of our
        Website.</p>

      <p>D. No Assignment. These Terms of Use and any rights or licenses granted to you
        herein are personal to you and may not be assigned by you prior to obtaining DejaVu.Art’s written consent.</p>

      <p>E. Governing Law and Jurisdiction. All matters relating to the Website and these
        Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including noncontractual
        disputes or claims), shall be governed by and construed in accordance with the internal laws of
        the State of Washington without giving effect to any choice or conflict of law provision or rule (whether of
        the State of Washington or any other jurisdiction).
        Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Website shall
        be instituted exclusively in the federal courts of the United States or the courts of the State of Washington.
        You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in
        such courts.</p>

      <p>F. Arbitration. At Company's sole discretion, it may require you to submit any
        disputes arising from these Terms of Use or use of the Website, including disputes arising from or
        concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding
        arbitration under the Rules of Arbitration of the American Arbitration Association applying the law of the
        State of Washington.</p>

      <p>G. Injunctive Relief. You agree that DejaVu.Art may bring suit in any court of
        competent jurisdiction in order to enjoin infringement or other misuse of DejaVu.Art’s or its affiliates or
        licensor’s intellectual property rights.</p>

      <p>H. Limitation on Time to File Claims. ANY CAUSE OF ACTION OR CLAIM
        YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE
        MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
        OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED (if applicable
        law prohibits a one (1) year limitation period for asserting claims, the claim must be asserted within the
        shortest time period in excess of one (1) year that is permitted by applicable law).</p>

      <p>I. Waiver and Severability. No waiver by DejaVu.Art of any term or condition set
        forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a
        waiver of any other term or condition, and any failure by DejaVu.Art to assert a right or provision under
        these Terms of Use shall not constitute a waiver of such right or provision. If any provision of these Terms
        of Use are held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable
        for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining
        provisions of the Terms of Use will continue in full force and effect.</p>

      <p>J. Notices, Questions or Concerns. All notices, comments, and other
        communications relating to the Website should be directed to <a data-fr-linked="true" href="mailto:info@dejavuai.com">info@dejavuai.com</a>.</p>

    </>

  );
}

export default Terms;