import '../CSS/global.css';
import '../CSS/pages.css';
import '../CSS/mobile.css';
import { useState, useEffect, useRef } from 'react';
import { apiResponse } from "../Interfaces";
import { useScrollbar } from '../Hooks/use-scrollbar-y';
import {
  useDeleteIgnoredUrlMutation,
  useSetIgnoredUrlMutation,
  useGetIgnoredUrlQuery,
} from "../Apis/imageApi";
import { userModel } from "../Interfaces";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../storage/redux/store";
import { W0, W1, W2 } from "../functions";
import LogoDejavuAI from "../assets/svgtojsx/LogoDejavuAI";

function Settings() {
  const [deleteIgnoredUrl] = useDeleteIgnoredUrlMutation();
  const [setIgnoredUrl] = useSetIgnoredUrlMutation();
  const [showIgnoredSites, setShowIgnoredSites] = useState(false);
  const [ignoredUrls, setIgnoredUrls] = useState([]);
  const [urlInput, setUrlInput] = useState("https://");
  const [error, setError] = useState("");
  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore);

  const userid = userData.id
  let urltype = 1;
  if (showIgnoredSites) { urltype = 0; }
  const ignoredurls: any = useGetIgnoredUrlQuery({ userid, urltype });
  console.log("ignoredurls");
  console.log(ignoredurls);

  console.log("ignoredUrls");
  console.log(ignoredUrls);

  useEffect(() => {
    if (ignoredurls.data !== undefined) {
      setIgnoredUrls(ignoredurls.data);
    }
  }, [showIgnoredSites, ignoredurls]);


  const ClickSwitchTab = () => {
    setShowIgnoredSites(!showIgnoredSites);
    setUrlInput("https://");
  }

  const urlinputhandler = (event: any) => {
    setUrlInput(event.target.value);

  }
  const addurlhandler = async (event: any) => {
    if (urlInput !== "https://") {
      let temp = urlInput;
      temp = temp.replace(/\/$/, "");
      const response: apiResponse = await setIgnoredUrl({
        "abc": userid,
        "url": temp,
        "abc1": urltype
      });
      if (response.data) {
        ;
      } else if (response.error) {
        setError("Error: " + response.error.data);
      }
    }

  }

  const deleteurlhandler = async (event: any) => {
    const response: apiResponse = await deleteIgnoredUrl({
      "abc": userid,
      "url": event.target.id,
      "abc1": urltype
    });

  }

  /**************** Scroll bar  things*********************************/
  const settingsWrapper = useRef(null);
  const hasScroll = true;

  useScrollbar(settingsWrapper, hasScroll);
  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < W1) { mult = W1 / windowWidth; }
  if (windowWidth < W2) { mult = W1 / W2; }
  if (windowWidth > W0) {
    return (

      <div className="maincontainer2">
        <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="pg-mainbox" style={{ width: 1320 / mult, height: 690 / mult }}>
            <div className="pg-mainbox-top" style={{ width: 1283 / mult, height: (73 + 48) / mult, marginLeft: 40 / mult }}>
              <span className="pg-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px', marginBottom: 15 / mult }}>Settings</span>
            </div>


            <div className="pg-mainbox-media"
              style={{ justifyContent: 'normal', fontSize: 30, width: 1130 / mult, height: 540 / mult }}>

              {showIgnoredSites ?
                <>
                  Sites to ignore
                  <span onClick={ClickSwitchTab} style={{ fontSize: 20 / mult + 'px', marginBottom: 15 / mult, color: '#698ff9', cursor: 'pointer', textDecoration: 'underline' }}>Pages with image to ignore</span>
                </>
                :
                <>
                  Pages with image to ignore
                  <span onClick={ClickSwitchTab}
                    style={{ fontSize: 20 / mult + 'px', marginBottom: 15 / mult, color: '#698ff9', cursor: 'pointer', textDecoration: 'underline' }}>Sites to ignore</span>
                </>
              }
              <div className="container-row-center" style={{ fontSize: 20 / mult, width: '100%' }}>

                {showIgnoredSites ?
                  <span>
                    URL of web site you want to ignore while searhing:&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  :
                  <span>
                    URL of page with image you want to ignore while searhing:&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                }
                <input
                  type="text"
                  name="urlinput"
                  value={urlInput}
                  onChange={urlinputhandler}
                  style={{ width: 480, marginTop: 3, outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'left' }}
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="image-addurl" onClick={addurlhandler} title="Add" style={{ width: 30 / mult, height: 30 / mult }}></div>
              </div>

              <div style={{ paddingRight: '12px', height: 400 / mult, width: 1120 / mult, minHeight: 400 / mult }} ref={settingsWrapper}>

                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'start', flexDirection: 'column' }} >

                  {ignoredUrls.map((ignoredUrl: any) => {
                    return (
                      <div style={{ fontSize: 20 / mult, fontWeight: 300, width: 1120 / mult, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginTop: 10 }}>
                        <div><span>{ignoredUrl.Url}</span></div>
                        <div className="t-image-delete" id={ignoredUrl.Url} onClick={deleteurlhandler} title="Delete" style={{ width: 30 / mult, height: 30 / mult, marginRight: 30 }}></div>
                      </div>
                    );
                  })}

                </div>

              </div>
            </div>
            <div style={{ width: '100%', height: (90 + 48) / mult, display: 'flex', justifyContent: 'right', marginTop: 30 / mult, marginRight: 80 / mult }}>
              <LogoDejavuAI mult={mult} />
            </div>
          </div>
        </div>
      </div>
    );

  } else {

    // Mobile *************************************************************

    mult = 600 / windowWidth;
    const mult1 = 3;
    return (
      <>
        <div className="bg_div_mob">
          <div className="title_div_mob" style={{ width: 540 / mult }}>
            <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>Settings</span>
          </div>
          <div className="main_div_mob" style={{ width: 550 / mult }}>
            <div style={{ width: 500 / mult }}>


              <div className="pg-mainbox-media"
                style={{ justifyContent: 'normal', fontSize: 30 }}>
                {showIgnoredSites ?
                  <>
                    Sites to ignore
                    <span onClick={ClickSwitchTab} style={{ fontSize: 20 / mult + 'px', marginBottom: 15 / mult, color: '#698ff9', cursor: 'pointer', textDecoration: 'underline' }}>Pages with image to ignore</span>
                  </>
                  :
                  <>
                    Pages with image to ignore
                    <span onClick={ClickSwitchTab}
                      style={{ fontSize: 20 / mult + 'px', marginBottom: 15 / mult, color: '#698ff9', cursor: 'pointer', textDecoration: 'underline' }}>Sites to ignore</span>
                  </>
                }
                <div className="container-column" style={{ fontSize: 20 / mult, width: '100%', padding: 10 }}>

                  {showIgnoredSites ?
                    <span >
                      URL of web site you want to ignore while searhing:
                    </span>
                    :
                    <span>
                      URL of page with image you want to ignore while searhing:
                    </span>
                  }

                  <input
                    type="text"
                    name="urlinput"
                    value={urlInput}
                    onChange={urlinputhandler}
                    style={{ width: 420 / mult, marginTop: 10, outline: 'none', borderRadius: '10px', border: '0px', height: 25 / mult, fontSize: 20 / mult, textAlign: 'left' }}
                  />&nbsp;&nbsp;&nbsp;&nbsp;
                  <div className="image-addurl" onClick={addurlhandler} title="Add" style={{ width: 30 / mult, height: 30 / mult, marginTop: -12 }}></div>
                </div>



                <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'start', flexDirection: 'column' }} >

                  {ignoredUrls.map((ignoredUrl: any) => {
                    let url1 = ignoredUrl.Url
                    if (ignoredUrl.Url.length > 45) {
                      url1 = url1.substring(0, 45) + "..."
                    }
                    return (
                      <div style={{ fontSize: 20 / mult, fontWeight: 300, display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginTop: 10 }}>
                        <div className="t-image-delete" id={ignoredUrl.Url} onClick={deleteurlhandler} title="Delete" style={{ width: 37 / mult, height: 35 / mult, marginRight: 5 }}></div>
                        <a href={ignoredUrl.Url} target="_blank">{url1}</a>

                      </div>
                    );
                  })}
                </div>

              </div>


            </div>
          </div>
        </div >
      </>
    );
  }
}

export default Settings;