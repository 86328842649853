import '../../CSS/global.css';
import '../../CSS/reports.css';
import '../../../src/CSS/mobile.css';
import '../../CSS/portfolio.css';
import { useState, useEffect, useRef } from 'react';
import { useGetAllImagesInfoQuery, useGetImageDataQuery, useSetAknMutation } from "../../Apis/imageApi";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../storage/redux/store";
import { useScrollbar } from '../../Hooks/use-scrollbar-y';
import { withAuth } from '../../HOC';
import { useNavigate } from "react-router-dom";
import MiniLoader from "../Misc/MiniLoader";
import { setSortMenuState } from "../../storage/redux/slice/varSlice";
import SortMenu from "../Misc/SortMenu";
import CheckBoxBlock from "../Misc/CheckBoxBlock";
import { apiResponse, userModel } from "../../Interfaces";
import { W1, W2 } from "../../functions";
import MobileReportObject from "./MobileReportObject";
import LogoDejavuAI from "../../assets/svgtojsx/LogoDejavuAI";

function Reports() {
  const [showSortButton, setShowSortButton] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSortMenuState(1));
  }, []);

  const userData: userModel = useSelector(
    (state: RootState) => state.userAuthStore
  );
  const varState: any = useSelector(
    (state: RootState) => state.varStore
  );
  const navigate = useNavigate();
  //const [u, setU] = useState(false);
  //const [data1, setData1] = useState(null);

  /***************************************************************/
  const ClickSort = () => {
    setShowSortButton(!showSortButton);
  }
  /****************Get Initial image info *********************/

  let userid = userData.id;
  let userasid = userData.actas_id;
  if (userData.a) {
    userasid = "a";
  }


  //const { data }: any = useGetAllImagesInfoQuery({ userid, userasid });

  //userid = "06ca884c-f865-4276-bff1-4d59526c61ec";
  //userasid = "06ca884c-f865-4276-bff1-4d59526c61ec";
  let sort = varState.sortMenuState;
  const { data } = useGetAllImagesInfoQuery({ userid, userasid, sort });

  //const searchimages = data?.notintrash;
  const searchimages = data?.withresults;
  const resultnumber = data?.resultnumber;

  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const reportWrapper = useRef(null);
  const hasScroll = true;
  useScrollbar(reportWrapper, hasScroll, searchimages, windowWidth);

  /*******************************************************/
  if (data === undefined) {
    return (<></>);
  }
  /**************** Scroll bar  things*********************************/
  let mult = 1;
  if (windowWidth > 600) {
    if (windowWidth < W1) { mult = W1 / windowWidth; }
    if (windowWidth < W2) { mult = W1 / W2; }
    return (
      <div className="maincontainer2">
        <div style={{ marginTop: 90, width: 1337 / mult, height: 715 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
          <div className="p-mainbox" style={{ width: 1325 / mult, height: 695 / mult }}>
            <div className="r-mainbox-top" style={{ width: 1220 / mult, height: 80 / mult }}>
              <span className="r-span1 colord9d9d9" style={{ fontSize: 37 / mult + 'px' }}>My Reports</span>
              <div style={{ marginBottom: 5 / mult }}>
                <LogoDejavuAI mult={mult} />
              </div>
            </div>
            <div className="r-mainbox-sort1" style={{ width: 1283 / mult, height: 77 / mult }}>

              <div className="r-mainbox-sort-left" style={{ marginTop: 15, height: 20 / mult, marginLeft: 10 / mult }}>
                <SortMenu />

              </div>

            </div>

            <div className="r-mainbox-media" style={{ width: 1230 / mult, height: 520 / mult }} >
              <div style={{ paddingRight: '12px', width: 1230 / mult, height: 450 / mult, minHeight: 450 / mult }} ref={reportWrapper}>
                <>
                  {searchimages !== undefined &&
                    <ReportObjects mediadata={searchimages} userid={userData.id} mult={mult} mobile={false} />
                  }
                </>
              </div>
            </div>

            <div className="r-mainbox-bottom" style={{ width: 1283 / mult, height: (90 + 48) / mult }}>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    mult = 600 / windowWidth;
    const mult1 = 3;
    return (
      <div className="bg_div_mob">
        <div className="title_div_mob" style={{ width: 540 / mult }}>
          <span className="p-span1 colord9d9d9" style={{ fontSize: 28 / mult + 'px' }}>My Reports</span>
          <div style={{ marginBottom: 5, marginLeft: 7 }}>

          </div>
        </div>

        <div className="main_div_mob" style={{ width: 550 / mult }}>
          <div className="p-mainbox-sort" style={{ width: 550 / mult, height: 40, marginTop: 5 }}>
            <div style={{ height: 45 / mult, display: 'flex', alignItems: 'center' }}>
              <div className="p-mainbox-sort-left" style={{ height: 45, marginTop: 12 }}>
                <SortMenu mobile={true} />
              </div>
            </div>
          </div>
          {searchimages !== undefined &&
            <ReportObjects mediadata={searchimages} userid={userData.id} mult={mult} mobile={true} />
          }
        </div>
      </div >
    );
  }
}

const ReportObject = ({ userid, resultimages, intrash, itemname, sid, uuid4name, mult }: any) => {
  const searchresults = "";
  const sorr = "s";
  const { data, isLoading, isSuccess, isError, error } = useGetImageDataQuery({ userid, sorr, uuid4name });
  return (
    <div className="myreports-content-item" style={{ width: 1200 / mult }}>

      <div className="myreports-content-item-left">
        {data !== undefined ?
          <img className="myreports-content-item-image" style={{ maxWidth: 110 / mult }} src={`data:image/jpeg;base64,${data}`} />
          : <MiniLoader />
        }
      </div>

      <div className="myreports-content-item-right" style={{ width: 1290 / mult }} >
        <div className="myreports-content-item-header" style={{ width: 1290 / mult }}>
          <div className="myreports-content-item-header-text" >
            {itemname}
          </div>
        </div>
        <div className="myreports-content-item-body" style={{ width: 1100 / mult }} >
          <table className="myreportstable" style={{}}>
            <thead>
              <tr style={{ fontSize: 15 / mult + 'px', fontWeight: 500 / mult }}>
                <th className="tg-0lax">Found Images</th>
                <th className="tg-0lax">URL</th>
                <th className="tg-0lax">Confidence</th>
                <th className="tg-0lax">Found online</th>
                <th className="tg-0lax">Notified by</th>
                <th className="tg-0lax">Acknowledgement</th>

              </tr>
            </thead>
            <tbody>

              {resultimages.map((imagedata: any) => {
                console.log("imagedata");
                console.log(imagedata);
                return (
                  <FoundImageReportObject
                    key={imagedata.thumb_name}
                    imageurl={imagedata.imageurl}
                    pageurl={imagedata.pageurl}
                    confidence={imagedata.confidence}
                    downloaddate={imagedata.downloaddate}
                    userid={userid}
                    result_status={imagedata.result_status}
                    result_item_id={imagedata.result_item_id}
                    checked_by={imagedata.checked_by}
                    //userid={imagedata.application_user_id}
                    uuid4name={imagedata.thumb_name}
                    how_informed={imagedata.how_informed}
                    mult={mult}
                  />
                );
              })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
}

const FoundImageReportObject = ({ imageurl, pageurl, confidence, downloaddate, userid, result_status, result_item_id, checked_by, uuid4name, how_informed, mult }: any) => {

  const [aknState, setAknState] = useState(false);
  let akn = false;
  if (result_status !== 1) { akn = true; }
  let buttonsarray = [];
  const [setAkn] = useSetAknMutation();

  const submitstatus = (e: any) => {
    const [btnid, ritemid] = e.target.id.split("_");
    let rstatus;
    akn = !akn;
    buttonsarray = [{ "id": "akn_" + result_item_id, "title": "", "buttonstate": akn, "buttonhandler": submitstatus },]
    if (akn) { rstatus = 2; } else { rstatus = 1; }
    setAknState(!aknState);
    const response: any = setAkn({ userid, ritemid, rstatus });
    if (response.data) {
      if (response.data === "OK") {
      } else {
        //setError("Unknown Error");
      }
    } else if (response.error) {
      //setError("Error: " + response.error.data.title);
    }
  }

  const sorr = "r";
  const { data, isLoading, isSuccess, isError, error } = useGetImageDataQuery({ userid, sorr, uuid4name });
  let checked_by_text = checked_by
  if (checked_by === null) { checked_by = ""; }
  if (checked_by.length > 18) {
    checked_by_text = checked_by.substring(0, 13) + "..."
  }
  let pageurltext = pageurl
  if (pageurl.length > 80) {
    pageurltext = pageurl.substring(0, 80) + "..."
  }

  buttonsarray = [{ "id": "akn_" + result_item_id, "title": "", "buttonstate": akn, "buttonhandler": submitstatus },]

  return (
    <tr >
      {data === undefined ? (
        <td className="tg-0lax td1" style={{ width: 120 / mult }}><MiniLoader /></td>
      ) : (
        <td className="tg-0lax td1" style={{ width: 120 / mult }}><img className="myreports-content-item-image" style={{ maxWidth: 110 / mult }} src={`data:image/jpeg;base64,${data}`} /></td>
      )
      }
      <td className="tg-0lax td2" style={{ width: 550 / mult, fontSize: 15 / mult + 'px', fontWeight: 200, }}>
        <a href={pageurl} target="_blank" style={{ fontSize: 15 / mult + 'px', fontWeight: 300, color: 'black' }}>
          {pageurltext}
        </a>
      </td>
      <td className="tg-0lax td3" style={{ width: 80 / mult, fontSize: 15 / mult + 'px', fontWeight: 300 }}>{confidence}%</td>
      <td className="tg-0lax td4" style={{ width: 80 / mult, fontSize: 15 / mult + 'px', fontWeight: 300 / mult }}>{downloaddate.substring(0, 10)}</td>
      <td className="tg-0lax td4" style={{ width: 120 / mult, fontSize: 15 / mult + 'px', fontWeight: 300 / mult, display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <NotifiedBy how_informed={how_informed} mult={mult} />
      </td>
      <td className="tg-0lax td4" style={{ width: 120 / mult, fontSize: 15 / mult + 'px', fontWeight: 300 / mult }}>
        <div style={{}}>
          {(akn) ?
            <div style={{ width: 105 / mult, padding: 2 / mult, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10, flexDirection: 'column' }}>
              <CheckBoxBlock buttonsarray={buttonsarray} layout={"column"} />
              <span style={{ color: 'green' }}>{checked_by_text}</span>
            </div>
            :
            <div style={{ width: 105 / mult, padding: 2 / mult, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10 }}>
              <CheckBoxBlock buttonsarray={buttonsarray} layout={"column"} />
            </div>
          }
        </div>
      </td>
    </tr >
  );
}

function NotifiedBy({ how_informed, mult }: any) {
  return (
    <div style={{ textAlign: 'left', width: 65 / mult }}>
      {(how_informed === 3 || how_informed === 5 || how_informed === 8) && <><span style={{ color: 'green' }}>&nbsp;Mail: OK</span><br /></>}
      {(how_informed === 4 || how_informed === 6 || how_informed === 7) && <><span style={{ color: 'red' }}>&nbsp;Mail: Failed</span><br /></>}
      {(how_informed === 1 || how_informed === 5 || how_informed === 7) && <><span style={{ color: 'green' }}>&nbsp;SMS: OK</span><br /></>}
      {(how_informed === 2 || how_informed === 6 || how_informed === 8) && <><span style={{ color: 'red' }}>&nbsp;SMS: Failed</span><br /></>}
      {(how_informed === 0) && <span>&nbsp;</span>}
      {(how_informed === 9) && <span style={{ color: 'black' }}>On Screen</span>}
    </div>
  );
}

function ReportObjects({ mediadata, userid, mult, mobile = false }: any) {
  if (mobile) {
    return (
      <>
        {mediadata.map((searchImages: any, i: number) => {
          return (
            <MobileReportObject
              key={i}
              userid={searchImages.application_user_id}
              resultimages={searchImages.result_images}
              intrash={searchImages.in_trash}
              itemname={searchImages.item_name}
              sid={searchImages.id}
              uuid4name={searchImages.uuid4name}
              mult={mult}
            />
          );
        })
        }
      </>
    );
  } else {
    return (
      <>
        {mediadata.map((searchImages: any, i: number) => {
          return (
            <ReportObject
              key={i}
              userid={searchImages.application_user_id}
              resultimages={searchImages.result_images}
              intrash={searchImages.in_trash}
              itemname={searchImages.item_name}
              sid={searchImages.id}
              uuid4name={searchImages.uuid4name}
              mult={mult}
            />
          );
        })
        }
      </>
    );
  }

}

export default withAuth(Reports);

