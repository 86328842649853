import '../../CSS/global.css';
import '../../CSS/smallmsgs.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSbscAccType } from "../../storage/redux/slice/subscriptionSlice";
import { withAuth } from '../../HOC';
import { W0, W1, W2 } from "../../functions";
import EmptyButton from '../Misc/EmptyButton';

function WhatAccType() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ClickIAmInd = () => {
    dispatch(setSbscAccType(2));
    navigate("/CreateAccount");

  }
  const ClickIAmBus = () => {
    dispatch(setSbscAccType(1));
    navigate("/CreateAccount");
  }
  /*************Calculate data for responsive behaviour************************/
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  let mult = 1;
  if (windowWidth < W1) { mult = W1 / windowWidth; }
  if (windowWidth < W2) { mult = W1 / W2; }
  /******************************************************************************/
  if (windowWidth > W0) {
    return (
      <>
        <div className="maincontainer2 colore6e6e6">
          <div style={{ marginTop: 90, width: 1270 / mult, height: 570 / mult, overflowX: 'auto', overflowY: 'hidden', zIndex: 2 }}>
            <div className="sm-mainbox" style={{ width: 1240 / mult, height: 560 / mult }}>
              <div className="sm-buttonbox" style={{ width: 1300 / mult, height: 90 / mult, fontWeight: 300, fontSize: 30 / mult + 'px' }}>
                What type of subscription are you interested in?
              </div>

              <div style={{ width: 1300 / mult, height: 100 / mult }}>
              </div>

              <div className="sm-buttonbox" style={{ width: 1300 / mult, height: 90 / mult, marginBottom: 90 / mult }}>
                <div className="p-upgradebutton" onClick={ClickIAmInd} style={{ width: 380 / mult, height: 70 / mult, marginRight: 80 / mult, fontWeight: 300, fontSize: 22 / mult + 'px' }}>
                  <span style={{ textAlign: 'center' }}>I'm an <br />individual</span>
                </div>
                <div className="p-upgradebutton" onClick={ClickIAmBus} style={{ width: 380 / mult, height: 70 / mult, marginLeft: 80 / mult, fontWeight: 300, fontSize: 22 / mult + 'px' }}>
                  <span style={{ textAlign: 'center' }}>I represent<br /> a business</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="maincontainer2 colore6e6e6">
          <div className="sm-mainbox" style={{ position: 'absolute', width: '100vw', height: '100vh' }}>
            <div className="sm-buttonbox" style={{ height: 90 / mult, fontWeight: 300, fontSize: 30 / mult + 'px', padding: 20 }}>
              What type of subscription are you interested in?
            </div>
            <div style={{ height: 100 / mult }}>
            </div>
            <div className="sm-buttonbox" style={{ flexDirection: 'column', height: 90 / mult, marginBottom: 90 / mult }}>
              <EmptyButton nav={ClickIAmInd} title={"I'm an individual"} id={""} />
              <div style={{ width: 30 / mult, height: 50 / mult }}>
                &nbsp;
              </div>
              <EmptyButton nav={ClickIAmBus} title={"I represent a business"} id={""} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withAuth(WhatAccType);